<template>
    <div class="cabinet-header">
        <div class="section-title cabinet-title"><span><i class="icon icon-user"></i>Личный кабинет пользователя</span></div>
        <div class="section-subtitle cabinet-menu">
            <router-link to="/cabinet/signatories"><i class="icon icon-pencil-edit"></i> {{ $t('app.links.signatories') }}</router-link>
            <router-link class="cabinet-menu-notification" to="/cabinet/notifications">
                <!-- <span class="count">34</span> -->
                Уведомления</router-link>
            <router-link to="/cabinet/user-details">Регистрационные данные и права доступа</router-link>
        </div>
    </div>
</template>



<script>
    export default {
        name: 'CabinetHeader'
    }
</script>