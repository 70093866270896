// #region Indicator types
/**
 * Перечисление периодичности
 */
export enum EIndicatorPeriodicity {
    /**
     * день
     */
    DAY = 'день',

    DAYLY = 'ежедневная',

    INTIME = 'единовременная',
    /**
     * день с накоплением
     */
    DAY_WITH_ACCUMULATION = 'день с накоплением',
    /**
     * квартал
     */
    QUARTER = 'квартал',
    /**
     * квартал с накоплением
     */
    QUARTER_WITH_ACCUMULATION = 'квартал с накоплением',

    /**
     * месяц с накоплением
     */
    MONTH_WITH_ACCUMULATION = 'месяц с накоплением',

    /**
     * месяц
     */
    MONTH = 'месяц',

    /**
     * неделя
     */
    WEEK = 'неделя',

    /**
     * год
     */
    YEAR = 'год',


    ONCE_YEAR = 'один раз в год'
}

/**
 * Показатель, каким он приходит от сервера
 */
export interface ILoadedIndicator {
    /**
     * Имя файла
     */
    file: string;

    /**
     * Название листа
     */
    sheet: string;

    /**
     * Название показателя
     */
    indicator: string;

    /**
     * Короткое название показателя
     */
    shortIndicator: string;

    /**
     * Периодичность
     */
    periodicity: string;

    /**
     * Единица измерения
     */
    unit: string;

    /**
     * "Температура" показателя
     */
    character: number;
}

/**
 * Элемент показателя, полученный от сервера
 */
export interface ILoadedIndicatorItem {
    /**
     * Дата элемента
     */
    date: number;

    /**
     * Планируемое значение
     */
    plan: null | number;

    /**
     * Фактическое значение
     */
    fact: null | number;
}

/**
 * Элемент показателя
 */
export interface IIndicatorItem {
    /**
     * Дата элемента
     */
    date: Date;

    /**
     * Начало периода элемента
     */
    periodStart: Date;

    /**
     * Конец периода элемента
     */
    periodEnd: Date;

    /**
     * Планируемое значение
     */
    plan: number;

    /**
     * Фактическое значение
     */
    fact: number;
}

/**
 * Показатель
 */
export interface IIndicator extends ILoadedIndicator {
    [x: string]: any;

    newKey: string;
    /**
     * Ключ показателя, содержит значения полей `indicator`, `sheet` и `file` через разделитель " || "
     */
    key: string;

    /**
     * Периодичность
     */
    periodicity: EIndicatorPeriodicity;

    /**
     * Элементы показателя
     */
    items: IIndicatorItem[];


    /**
     * Признак того, что у элементов показателя есть "планируемые значения"
     */
    withPlan: boolean;
}

/**
 * Элементы показателя, выбранные слайдером
 */
export interface ISelectedIndicatorItems {
    /**
     * Ключ показателя
     */
    key: string;

    /**
     * Имя файла
     */
    file: string;

    /**
     * Название листа
     */
    sheet: string;

    /**
     * Название показателя
     */
    indicator: string;

    /**
     * Короткое название показателя
     */
    shortIndicator: string;

    /**
     * Периодичность
     */
    periodicity: string;

    /**
     * Единица измерения
     */
    unit: string;

    /**
     * Признак того, что у элементов показателя есть "планируемые значения"
     */
    withPlan: boolean;

    /**
     * "Температура" показателя
     */
    character: number;

    /**
     * Элемент перед выбранным (`item`)
     *
     * Может быть не заполнен, если выбран элемент за самый первый период
     */
    previousItem: undefined | IIndicatorItem;

    /**
     * Выбранный элемент
     *
     * Может быть за последний из предыдущих периодов, если элемента за выбранный период нет - в этом случае `isItemActual` равно `false`
     */
    item: undefined | IIndicatorItem;

    /**
     * Равно `false`, если элемент `item` не существует или соответсвует периоду, предшествующему выбранной на слайдере дате
     */
    isItemActual: boolean;

    /**
     * Массив элементов для графиков.
     *
     * Может быть пустым; если не пустой `item`, будет максимально возможно близко к "центру" массива.
     *
     * Если `item` не равен `undefined`, содержит минимум `item`
     *
     * @see `inItemsIndex`
     */
    items: IIndicatorItem[];

    /**
     * Индекс `item` в массиве `items`
     *
     * Не равен `-1`, если `item` не равен `undefined`
     */
    inItemsIndex: number;
}

// #endregion


/**
 * Данные слайдера
 */
export interface ISliderData {
    /**
     * Признак "изменено пользователем"
     *
     * Если равно `true`, применение новых элементов показателей не изменяет выбранную дату `value`
     * (`min` и `max` продолжают изменяться)
     */
    changedByUser: boolean;

    /**
     * Минимальная дата (на основе данных)
     */
    min: Date;

    /**
     * Максимальная дата (на основе данных)
     */
    max: Date;

    /**
     * Выбранная дата.
     *
     * Если `changedByUser` равно `false`, при загрузке элементов показателей эта дата может меняться -
     * будет выбираться максимально близкая к текущему времени дата
     */
    value: Date;
}


// #region Widgets
/**
 * Типы виджетов
 */
export enum EWidgetType {
    /**
     * До 8 строк текста
     */
    TEXT_LINES = 'text-lines',

    /**
     * До 4 элементов "строка + полоска состояния"
     */
    TEXT_BARS = 'text-bars',

    /**
     * До 2 "измерителей" ("спидометров")
     */
    METERS = 'meters',

    /**
     * График - столбчатый
     */
    CHART_BARS = 'chart-bars',

    /**
     * График - линейный с маркерами
     */
    CHART_LINEAR_MARKERS = 'chart-linear-markers',

    /**
     * Объемная круговая диаграмма
     */
    CHART_3D_PIE = 'chart-3d-pie',

    /**
     * Плоская круговая диаграмма + раздельная
     */
    CHART_FLAT_PIE = 'chart-flat-pie',

    /**
     * Плоская кольцевая диаграмма
     */
    CHART_RADIUS_PIE = 'chart-radius-pie',

    /**
     * График - столбчатый + линейный
     */
    CHART_MULTIPLE_AXES = 'chart-multiple-axes',

    /**
     * График - Столбчатая с накоплением
     */
    CHART_STACK_COLUMN = 'chart-stack-column',

    /**
     * График - Столбчатая с накоплением
     */
    CHART_TREE_MAP = 'chart-tree-map',
    /**
     * График - Показатель для главной страницы
     */
    CHART_ACTUAL = 'chart-actual',

    AM_CHARTS = 'am-charts',

    CHART_GANTT = 'chart-gantt',

    CHART_BUDGET = 'chart-budget',

    CHART_MODEL_DATA = 'chart-model-data',

    CHART_MODEL_LINEAR = 'chart-model-linear',

    /**
     * График - Хронология публикаций из медиамониторинга
     */
    CHART_TONE_LINE = 'chart-tone-line',
    /**
     * График - Топ авторов из медиамониторинга
     */
    CHART_TONE_BAR = 'chart-tone-bar',
    /**
     * График - Количество публикаций в разрезе сфер из медиамониторинга
     */
    CHART_SPHERE_TONES_BAR = 'chart-sphere-tones-bar',

    CHART_COVID = 'chart-covid',

    CHART_VACCINATION = 'chart-vaccination',

    CHART_SOC = 'chart-soc',

    /**
     * Виджет для визуализации поручений/обращений
     */
    REQUESTS = 'requests'
}

/**
 * Данные, общие для виджетов всех типов
 */
export interface IWidgetBase {
    /**
     * ИД виджета (загружается из сервера при создании виджета)
     */
    id: number;

    /**
     * Тип виджета
     */
    type: EWidgetType;

    /**
     * Заголовок
     */
    title: string;
}

/**
 * Данные виджета для типа "До 8 строк текста"
 */
export interface IWidgetTextLines extends IWidgetBase {
    type: EWidgetType.TEXT_LINES;

    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: Array<{
        indicator: string;
        title: string;
        measurement: number;
        unit: object;
        periodicity: string
    }>;
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

/**
 * Данные виджета для типа "До 4 элементов "строка + полоска состояния""
 */
export interface IWidgetTextBars extends IWidgetBase {
    type: EWidgetType.TEXT_BARS;

    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: Array<{
        indicator: string;
        title: string;
        measurement: number;
        unit: object;
        periodicity: string
    }>;
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

/**
 * Данные виджета для типа "До 2 "измерителей" ("спидометров")"
 */
export interface IWidgetMeters extends IWidgetBase {
    type: EWidgetType.METERS;

    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: Array<{
        indicator: string;
        title: string;
        measurement: number;
        unit: object;
        periodicity: string
    }>;
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

/**
 * Данные виджета для типа "Столбчатый график"
 */
export interface IWidgetChartBars extends IWidgetBase {
    type: EWidgetType.CHART_BARS;

    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: Array<{
        indicator: string;
        type: string;
        title: string;
        plan: boolean | undefined;
        measurement: number;
        unit: object;
        periodicity: string
    }>;
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

/**
 * Данные виджета для типа "Линейный график с маркерами"
 */
export interface IWidgetChartLinearMarkers extends IWidgetBase {
    type: EWidgetType.CHART_LINEAR_MARKERS;

    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: Array<{
        indicator: string;
        title: string;
        measurement: number;
        unit: object;
        periodicity: string
    }>;
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

/**
 * Данные виджета для типа "Объемная круговая диаграмма"
 */
export interface IWidgetChart3dPie extends IWidgetBase {
    type: EWidgetType.CHART_3D_PIE;

    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: Array<{
        indicator: string;
        title: string;
        measurement: number;
        unit: object;
        periodicity: string
    }>;
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

/**
 * Данные виджета для типа "Плоская круговая диаграмма"
 */
export interface IWidgetChartFlatPie extends IWidgetBase {
    type: EWidgetType.CHART_FLAT_PIE;

    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: Array<{
        indicator: string;
        title: string;
        measurement: number;
        unit: object;
        periodicity: string
    }>;
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

/**
 * Данные виджета для типа "Плоская кольцевая диаграмма"
 */
export interface IWidgetChartRadiusPie extends IWidgetBase {
    type: EWidgetType.CHART_RADIUS_PIE;

    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: Array<{
        indicator: string;
        title: string;
        measurement: number;
        unit: object;
        periodicity: string
    }>;
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

/**
 * Данные виджета для типа "Линейная + столбчатая диаграмма"
 */
export interface IWidgetChartMultipleAxes extends IWidgetBase {
    type: EWidgetType.CHART_MULTIPLE_AXES;
    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: Array<{
        indicator: string;
        type: string;
        title: string;
        measurement: number;
        unit: object;
        diffAxis: boolean;
        periodicity: string
    }>;
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

/**
 * Данные виджета для типа "Линейная + столбчатая диаграмма"
 */
export interface IWidgetChartStackColumn extends IWidgetBase {
    type: EWidgetType.CHART_STACK_COLUMN;
    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: Array<{
        indicator: string;
        title: string;
        measurement: number;
        unit: object;
        periodicity: string
    }>;
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

/**
 * Данные виджета для типа "Диаграмма дерево"
 */
export interface IWidgetChartTreeMap extends IWidgetBase {
    type: EWidgetType.CHART_TREE_MAP;
    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: Array<{
        indicator: string;
        title: string;
        measurement: number;
        unit: object;
        periodicity: string
    }>;
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

/**
 * Данные виджета для типа "Показатель для главной страницы"
 */
export interface IWidgetChartActual extends IWidgetBase {
    type: EWidgetType.CHART_ACTUAL;
    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: Array<{
        indicator: string;
        title: string;
        measurement: number;
        unit: object;
        periodicity: string
    }>;
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

export interface IWidgetChartAmCharts extends IWidgetBase {
    type: EWidgetType.AM_CHARTS;
    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: Array<{
        indicator: string;
        title: string;
        measurement: number;
        unit: object;
        periodicity: string
    }>;
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

export interface IWidgetChartGantt extends IWidgetBase {
    type: EWidgetType.CHART_GANTT;
    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: Array<{
        indicator: string;
        title: string;
        measurement: number;
        unit: object;
        periodicity: string
    }>;
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

export interface IWidgetChartBudget extends IWidgetBase {
    type: EWidgetType.CHART_BUDGET;

    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: IWidgetChartBudgetConfigs[];
    /**
     * Размер виджета
     */
    size: number[];
}

export interface IWidgetChartBudgetConfigs {

    indicator: string;

    type: string;

    title: string;

    plan: boolean | undefined;
}

export interface IWidgetChartModelData extends IWidgetBase {
    type: EWidgetType.CHART_MODEL_DATA;
    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: Array<{
        indicator: string;
        type: string;
        title: string;
        measurement: number;
        unit: object;
        diffAxis: boolean;
        periodicity: string
    }>;
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

export interface IWidgetChartModelLinear extends IWidgetBase {
    type: EWidgetType.CHART_MODEL_LINEAR;
    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: Array<{
        indicator: string;
        type: string;
        title: string;
        measurement: number;
        unit: object;
        diffAxis: boolean;
        periodicity: string
    }>;
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

export interface IWidgetChartCovid extends IWidgetBase {
    type: EWidgetType.CHART_COVID;
    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: [];
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

export interface IWidgetChartVaccination extends IWidgetBase {
    type: EWidgetType.CHART_VACCINATION;
    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: [];
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

export interface IWidgetChartSoc extends IWidgetBase {
    type: EWidgetType.CHART_SOC;
    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    indicatorConfigs: [];
    dictCategoryEntity: null | object;
    updateDate: null | number;
    /**
     * Размер виджета
     */
    size: number[];
}

/**
 * Данные виджета для визуализации поручений/обращений
 */
export interface IWidgetRequests extends IWidgetBase {
    type: EWidgetType.REQUESTS;

    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
    /**
     * Размер виджета
     */
    size: number[];
}

/**
 * Данные виджета для типа "Хронология публикаций из медиамониторинга"
 */
export interface IWidgetChartToneLine extends IWidgetBase {
    type: EWidgetType.CHART_TONE_LINE;

    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
}

/**
 * Данные виджета для типа "Топ авторов из медиамониторинга"
 */
export interface IWidgetChartToneBar extends IWidgetBase {
    type: EWidgetType.CHART_TONE_BAR;

    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
}

/**
 * Данные виджета для типа "Количество публикаций в разрезе сфер из медиамониторинга"
 */
export interface IWidgetChartSphereTonesBar extends IWidgetBase {
    type: EWidgetType.CHART_SPHERE_TONES_BAR;

    /**
     * Ключи показателей (`IIndicator.key`)
     */
    indicatorKeys: string[];
}

/**
 * Сборка типов виджетов в один тип
 */
export type TWidget =
    IWidgetChartSphereTonesBar
    | IWidgetChartToneBar
    | IWidgetChartToneLine
    | IWidgetTextLines
    | IWidgetTextBars
    | IWidgetMeters
    | IWidgetChartBars
    | IWidgetChartLinearMarkers
    | IWidgetChart3dPie
    | IWidgetChartFlatPie
    | IWidgetChartRadiusPie
    | IWidgetChartMultipleAxes
    | IWidgetChartStackColumn
    | IWidgetChartTreeMap
    | IWidgetChartActual
    | IWidgetChartAmCharts
    | IWidgetChartGantt
    | IWidgetChartBudget
    | IWidgetChartModelData
    | IWidgetChartModelLinear
    | IWidgetChartCovid
    | IWidgetChartVaccination
    | IWidgetChartSoc;

export type IWidgets =
    IWidgetTextLines
    | IWidgetTextBars
    | IWidgetMeters
    | IWidgetChartLinearMarkers
    | IWidgetChart3dPie
    | IWidgetChartFlatPie
    | IWidgetChartRadiusPie
    | IWidgetChartStackColumn
    | IWidgetChartTreeMap
    | IWidgetChartActual
    | IWidgetChartModelData
    | IWidgetChartMultipleAxes
    | IWidgetChartBars
    | IWidgetChartModelLinear
    | IWidgetChartCovid
    | IWidgetChartVaccination
    | IWidgetChartSoc;

/**
 * Состояние загрузки виджетов
 */
export type TWidgetLoadState = 'loading' | 'error' | 'saving' | 'ready';

// #endregion


/**
 * Содержимое хранилища
 */
export interface IModuleState {
    /**
     * Карта показателей, значения `IIndicator.key` в роли ключей
     */
    indicators: Map<string, IIndicator>;

    /**
     * Данные слайдера
     */
    sliderData: null | ISliderData;

    /**
     * Элементы показателей, отобранные на основе выбранной в слайдере даты
     */
    selectedIndicatorsItems: Map<string, ISelectedIndicatorItems>;

    /**
     * Список виджетов
     */
    widgets: TWidget[];

    categories: any[];

    /**
     * Состояние загрузки виджетов
     */
    widgetsLoadState: TWidgetLoadState;
}

/**
 * Интерфейс "геттеров" хранилища
 */
export interface IModuleGetters {
    /**
     * Карта виджетов, значения `IWidgetBase.id` в роли ключей
     */
    widgetMap: Map<number, TWidget>;
}