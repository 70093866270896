import { Vue, Component } from 'vue-property-decorator';
// import { TWidget } from '@/modules/widget-yard/store/types';
import store from '@/services/store';
import VWidget from '@/modules/widget-yard/c-widget/v-widget';
import App from '@/App.vue';

interface ISectiones {
    id: any;
    name?: string;
    href: string;
    i18n?: string;
    icon?: string;
    hiddenRoles: string[];
    query?: Record<string, any>;
    moduleCode: string;
}
//
// interface ISection {
//     id: number;
//     name: string;
//     widgets: any;
// }

interface IMenuSection {
    value: string;
    i18n: string;
    href?: string;
    icon: string;
    hiddenRoles: string[];
    moduleCode: string;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface Irealm {
    url: string | 'http://mm.csi.kz/main';
    clientId: string | 'ea-ea';
    realm: string | 'ea-sic';
}

// interface IWidgetData {
//     checked: boolean | null;
//     widget: TWidget;
//     expanded: boolean | undefined;
// }

@Component({
    components: {
        'v-widget': VWidget
    }
})
export default class CBoard extends Vue {
    private realm: Irealm | undefined;
    private mmUrl = 'http://mm.csi.kz/main';

    private async loadRealm() {
        const response = await fetch('/api/instance/realm');
        this.realm = await response.json();
        // eslint-disable-next-line no-useless-concat
        this.mmUrl = 'http://mm.csi.kz/main' + '?realm=' + this.realm?.realm + '&clientid=' + this.realm?.clientId;

        // this.sectionList[9].href = this.mmUrl;
        // Вместо беды виш будет беда ниже
        let section: Record<string, any> | undefined;
        for (const currentSection of this.sectionList) {
            if (currentSection.value === '5') {
                section = currentSection;
                break;
            }
        }
        if (section !== undefined) {
            section.href = this.mmUrl;
        }
    }

    // private windowResizeEventHandler!: () => void;
    // private windowClickListener: undefined | ((this: Window, ev: MouseEvent) => void);
    public code = store.state._instanceCode;
    public intervalHandle: number | null = null;
    public path = '';
    public fullBoard = true;
    public link = null;
    public accordion = null;
    public sectionData = {};
    public curSection: any = {};
    // private fullSizeWin = false;
    private openB = 0;

    // Главное меню первого уровня
    private get sectionList(): IMenuSection[] {
        return [
            {
                value: 'sep',
                i18n: 'app.links.soc-eco',
                href: undefined,
                icon: 'graphic',
                hiddenRoles: [],
                moduleCode: '001.000'
            },
            {
                value: 'sem',
                i18n: 'app.links.soc-mod',
                href: undefined,
                icon: 'magnifier-graphic',
                hiddenRoles: [],
                moduleCode: '002.000'
            },
            {
                value: 'strategicPlanning',
                i18n: 'app.links.strategicPlanning',
                icon: 'binocular-graphic',
                hiddenRoles: [],
                moduleCode: '003.000'
            },
            {
                value: 'budgetPlanning',
                i18n: 'app.links.budgetPlanning',
                icon: 'line-chart',
                hiddenRoles: ['role_gu'],
                moduleCode: '004.000'
            },
            {
                value: 'budgetExecution',
                i18n: 'app.links.budgetExecution',
                icon: 'calculator-coins',
                hiddenRoles: ['role_gu'],
                moduleCode: '005.000'
            },
            {
                value: '5',
                i18n: 'app.links.media_monitoring',
                href: this.mmUrl,
                icon: 'magnifier-user',
                hiddenRoles: ['role_gu'],
                moduleCode: '006.000'
            },
            {
                value: 'forms',
                i18n: 'app.links.forms',
                href: undefined,
                icon: 'document-pencil',
                hiddenRoles: ['role_gu'],
                moduleCode: '007.000'
            },
            {
                value: '7',
                i18n: 'app.links.useful',
                href: '/astanamap',
                icon: 'star',
                hiddenRoles: ['role_gu'],
                moduleCode: '008.000'
            },
            {
                value: 'guide',
                i18n: 'app.links.guide',
                href: '/guide',
                icon: 'news',
                hiddenRoles: [],
                moduleCode: '012.000'
            }
        ];
    }

    // Дополнительное меню первого уровня
    public get additionalSectionList(): IMenuSection[] {
        return [
            {
                value: 'additional-republicLaws',
                i18n: 'app.links.republicLaws',
                icon: 'libra',
                hiddenRoles: [],
                moduleCode: '009.000'
            },
            {
                value: 'additional-govCatalog',
                i18n: 'app.links.gov-catalog',
                icon: 'star',
                href: '/gov-catalog',
                hiddenRoles: [],
                moduleCode: '010.000'
            },
            {
                value: 'additional-news',
                i18n: 'app.links.news',
                icon: 'news',
                hiddenRoles: [],
                moduleCode: '011.000'
            }
        ];
    }

    // private isMenuAviable(section: any): boolean {
    //     return true;
    // }

    // private get usrRoles() {
    //     if (store.state.user.roles === undefined) {
    //         return null;
    //     }
    //     return store.state.user.roles;
    // }

    // public menuBudgetRequest = [
    //     { id: 100, name: 'Cводный расчёт расходов', href: '/budget-sum-calc' },
    //     { id: 101, name: 'Бюджетные программы', href: '/budget-program' },
    //     // { id: 1, name: 'Форма 01-111', href: '/form01-111' },
    //     // { id: 2, name: 'Форма 02-111', href: '/form02-111' },
    //     { id: 3, name: 'Форма 01-123', href: '/form01-123' },
    //     { id: 4, name: 'Форма 02-123', href: '/form02-123' },
    //     { id: 5, name: 'Форма 01-136', href: '/form01-136' },
    //     { id: 6, name: 'Форма 01-144', href: '/form01-144' },
    //     { id: 7, name: 'Форма 02-144', href: '/form02-144' },
    //     { id: 8, name: 'Форма 01-149', href: '/form01-149' },
    //     { id: 9, name: 'Форма 02-149', href: '/form02-149' },
    //     { id: 10, name: 'Форма 03-149', href: '/form03-149' },
    //     { id: 11, name: 'Форма 01-151', href: '/form01-151' },
    //     { id: 12, name: 'Форма 02-151', href: '/form02-151' },
    //     { id: 13, name: 'Форма 03-151', href: '/form03-151' },
    //     { id: 14, name: 'Форма 04-151', href: '/form04-151' },
    //     { id: 15, name: 'Форма 01-152', href: '/form01-152' },
    //     { id: 16, name: 'Форма 01-153', href: '/form01-153' },
    //     { id: 17, name: 'Форма 01-159', href: '/form01-159' },
    //     { id: 18, name: 'Форма 02-159', href: '/form02-159' },
    //     { id: 19, name: 'Форма 02-159 (Дороги)', href: '/form02-159-1' },
    //     { id: 20, name: 'Форма 02-159 (339)', href: '/form02-159-2' },
    //     { id: 21, name: 'Форма 03-159', href: '/form03-159' },
    //     { id: 22, name: 'Форма 01-161', href: '/form01-161' },
    //     { id: 23, name: 'Форма 01-169', href: '/form01-169' },
    //     { id: 24, name: 'Форма 01-413', href: '/form01-413' },
    //     { id: 25, name: 'Форма 01-414', href: '/form01-414' },
    //     { id: 26, name: 'Форма 01-416', href: '/form01-416' },
    //     { id: 27, name: 'Форма 01-139', href: '/form01-139' },
    // ];

    // Формы 2 уровень
    public forms: ISectiones[] = [
        { id: 1, i18n: 'modules.forms_input', href: '/forms', hiddenRoles: [], moduleCode: '007.000' },
        { id: 'budget-loader', i18n: 'modules.app.links.budget-loader', href: '/budget-loader', hiddenRoles: [], moduleCode: '007.000' },
        { id: 2, i18n: 'modules.report_for_sep', href: '/report-for-sep', hiddenRoles: ['role_gu'], moduleCode: '007.003' }
    ];
    // Стратегическое планирование 2 уровень
    public sgp: ISectiones[] = [
        { id: 2, i18n: 'modules.target_indicators', href: '/forecast-target-form', hiddenRoles: [], moduleCode: '003.001' },
        { id: 3, i18n: 'modules.intercommunication_of_ti_and_bp', href: '/intercon-forecast', hiddenRoles: [], moduleCode: '003.002' },
        // { id: 4, name: 'Взаимоувязка ЦИ и проектов', href: '/' },
        { id: 5, i18n: 'modules.rs_monitoring', href: '/monitoring-snp', hiddenRoles: ['role_gu'], moduleCode: '003.003' },
        { id: 1, i18n: 'modules.needs_of_rs', href: '/snp', hiddenRoles: ['role_gu'], moduleCode: '003.004' },
        {
            id: 'program-link-list',
            i18n: 'app.links.development_programs',
            href: '/program-link-list',
            hiddenRoles: [],
            moduleCode: '003.005'
        },
        {
            id: 'design-estimate-documentation',
            i18n: 'app.links.design-estimate-documentation',
            href: '/design-estimate-documentation',
            hiddenRoles: [],
            moduleCode: '003.006'
        }
        // { id: 6, name: 'Проекты в разрезе НП', href: '/' }
    ];

    // Бюджетное планирование 2 уровень
    public menuBudget: ISectiones[] =
        [
            {
                id: 1,
                i18n: 'modules.app.links.budget-limit',
                href: '/budget-limit',
                hiddenRoles: [],
                moduleCode: '004.001'
            },
            {
                id: 22,
                href: '/budget-sum-calc-prog',
                i18n: 'app.links.budget_requests',
                hiddenRoles: [],
                moduleCode: '004.002'
            },
            {
                id: 2,
                i18n: 'modules.app.links.budget-form',
                href: '/budget-form',
                hiddenRoles: [],
                moduleCode: '004.003'
            },
            {
                id: 21,
                i18n: 'modules.app.links.budget-correct',
                href: '/budget-correct',
                hiddenRoles: [],
                moduleCode: '004.004'
            },
            {
                id: 3,
                i18n: 'modules.app.links.budget-clarify',
                href: '/budget-clarify',
                hiddenRoles: [],
                moduleCode: '004.005'
            },
            {
                id: 4,
                i18n: 'modules.app.links.budget-report',
                href: '/budget-report',
                hiddenRoles: [],
                moduleCode: '004.006'
            },
            {
                id: 6,
                i18n: 'app.links.analytics',
                href: '/budget-analytics',
                hiddenRoles: [],
                moduleCode: '004.008'
            },
            {
                id: 5,
                i18n: 'modules.app.links.budget-dicts',
                href: '/budget-dicts',
                hiddenRoles: [],
                moduleCode: '004.007'
            }
        ];

    // Исполнение бюджета 2 уровень
    public menuBudgetExecution: ISectiones[] = [
        {
            id: 1,
            i18n: 'modules.financing_plan',
            href: '/budget-execution-form',
            hiddenRoles: [],
            moduleCode: '005.001'
        },
        {
            id: 2,
            i18n: 'modules.change_requests',
            href: '/alteration',
            hiddenRoles: [],
            moduleCode: '005.002'
        },
        {
            id: 3,
            i18n: 'modules.monitoring_execution',
            href: 'monitoring',
            hiddenRoles: [],
            moduleCode: '005.003'
        },
        {
            id: 7,
            i18n: 'modules.budget_loans',
            href: 'budget-loan-table',
            hiddenRoles: [],
            moduleCode: '005.007'
        },
        {
            id: 4,
            i18n: 'modules.purchase_plan',
            href: '/purchase-plan',
            hiddenRoles: [],
            moduleCode: '005.004'
        },
        {
            id: 8,
            i18n: 'app.links.analytics',
            href: '/budget-execution-analytics',
            hiddenRoles: [],
            moduleCode: '005.008'
        },
        {
            id: 5,
            i18n: 'modules.project_management',
            href: '',
            hiddenRoles: [],
            moduleCode: '005.005'
        },
        {
            id: 6,
            i18n: 'modules.efficiency_mark',
            href: '',
            hiddenRoles: [],
            moduleCode: '005.006'
        }

    ];

    // /**
    //  * Элементы меню "СГП"
    //  */
    // private menuSgp = [
    //     { id: 1, name: 'Справочники', href: '/demo-budget-execution/target-dict' },
    //     { id: 2, name: 'ЦИ', href: '/demo-budget-execution/sgp/target-indicators' },
    //     { id: 3, name: 'Определение потребностей', href: '/demo-budget-execution/roads/roads-map' },
    //     { id: 4, name: 'БИП', href: '/demo-budget-execution/roads/bip-roads/' },
    //     { id: 5, name: 'Моделирование', href: '/demo-budget-execution/modeling' }
    // ];
    //
    // /**
    //  * Элементы меню "Соц.-эконом. показатели"
    //  */
    // private menuPser = [
    //     { id: 1, name: 'Соц.-эконом. показатели', href: '/demo-budget-execution/pser/soc-eco-indicators' },
    //     { id: 2, name: 'Прогноз бюджета (лимиты)', href: '/demo-budget-execution/pser/budget-prognosis' }
    // ];
    //
    // /**
    //  * Элементы меню "Бюджетное планирование"
    //  */
    // private menuBudgetPlanningDemo = [
    //     { id: 1, name: 'Классификаторы', href: '/demo-budget-execution/budget-plan/classifiers' },
    //     { id: 2, name: 'Расчет лимитов', href: '/demo-budget-execution/budget-plan/limit-calc' },
    //     {
    //         id: 3,
    //         name: 'Формирование / Переходящий бюджет',
    //         href: '/demo-budget-execution/budget-plan/creation/flowing-budget'
    //     },
    //     {
    //         id: 4,
    //         name: 'Формирование / Дополнительная потребность',
    //         href: '/demo-budget-execution/budget-plan/creation/add-needs'
    //     },
    //     { id: 5, name: 'Формирование / БЗ', href: '/demo-budget-execution/request/request' },
    //     { id: 6, name: 'Формирование / БП', href: '/' },
    //     { id: 7, name: 'Формирование / Заключение ОБК', href: '/demo-budget-execution/request/budget-commission' },
    //     { id: 'b2b53b68-4a2d-4e16-a12b-1ac7178b5e33', name: 'Корректировка', href: '/budget-correct', length: 0 },
    //     { id: 'c8238c0d-1c8c-44b8-86c7-2db0d7d94031', name: 'Уточнение', href: '/budget-clarify', length: 0 }
    // ];
    //
    // /**
    //  * Элементы меню "Исполнение бюджета"
    //  */
    // private menuBudgetExecutionDemo = [
    //     { id: 1, name: 'Классификаторы', href: '/' },
    //     { id: 2, name: 'Исполнение / Сводные данные', href: '/demo-budget-execution/request/budget-plan' },
    //     { id: 3, name: 'Исполнение / Поступления', href: '/' },
    //     { id: 4, name: 'Исполнение / Расходы', href: '/demo-budget-execution/execution/budget-forms' }
    // ];

    // private get menuStrategicPlanning(): ISectiones[] {
    //     const programLinkList: ISectiones = {
    //         id: 'program-link-list',
    //         i18n: 'app.links.development_programs',
    //         href: '/program-link-list',
    //         hiddenRoles: []
    //     };
    //     return [].concat(...(this.sgp as any[])).concat(programLinkList as any);
    // }

    // private get menuBudgetPlanning(): ISectiones[] {
    //     return this.menuBudget;
    // }

    // private get menuBudgetExecution_(): Array<Record<string, any>> {
    //     let id = 0;
    //     const create = (title: string): Record<string, any> => {
    //         id++;
    //         return {
    //             id: 'menuBudgetExecution@' + id,
    //             name: title,
    //             href: '/budget-execution',
    //             query: {
    //                 title: title
    //             }
    //         };
    //     };
    //
    //     return [
    //         create('План поступлений'),
    //         create('План финансирования'),
    //         create('План закупок'),
    //         {
    //             id: 'menuBudgetExecution@' + id,
    //             name: 'Мониторинг исполнения',
    //             href: '/monitoring'
    //         },
    //         create('Проектное управление'),
    //         create('Оценка эффективности')
    //     ];
    // }

    // Дополнительное меню 2 уровень
    public get additionalMenuLaws(): ISectiones[] {
        return [
            {
                id: 'state-planning-system',
                i18n: 'modules.app.additional_npa.additional_menu.sgp',
                href: '/additionally-menu/sgp',
                hiddenRoles: [],
                moduleCode: '009.000'
            },
            {
                id: 'monitoring-snp',
                i18n: 'modules.app.additional_npa.additional_menu.monitoring-snp',
                href: '/additionally-menu/msnp',
                hiddenRoles: [],
                moduleCode: '009.000'
            },
            {
                id: 'investment_projects',
                i18n: 'modules.app.additional_npa.additional_menu.investment_projects',
                href: '/additionally-menu/ip',
                hiddenRoles: [],
                moduleCode: '009.000'
            },
            {
                id: 'budget_planning',
                i18n: 'modules.app.additional_npa.additional_menu.budget_planning',
                href: '/additionally-menu/bp',
                hiddenRoles: [],
                moduleCode: '009.000'
            },
            {
                id: 'budget_execution',
                i18n: 'modules.app.additional_npa.additional_menu.budget_execution',
                href: '/additionally-menu/ib',
                hiddenRoles: [],
                moduleCode: '009.000'
            },
            {
                id: 'other',
                i18n: 'modules.app.additional_npa.additional_menu.other',
                href: '/additionally-menu/pr',
                hiddenRoles: [],
                moduleCode: '009.000'
            }
        ];
    }

    public get additionalMenuNews(): ISectiones[] {
        return [
            {
                id: 1,
                name: 'Tengrinews',
                href: 'https://tengrinews.kz/',
                hiddenRoles: [],
                moduleCode: '011.000'
            }
        ];
    }

    // private programData: any[] = [];

    // private subLinks = [
    //     { value: 41, text: 'Topic', href: 'http://mm.csi.kz/topic' },
    //     { value: 42, text: 'Socmedia', href: 'http://mm.csi.kz/socmedia' },
    //     { value: 51, text: 'По руководству', href: '/requests' },
    //     { value: 52, text: 'По городу и районам', href: '/requests' },
    //     { value: 61, text: 'Карта', href: '/astanamap', tab: 0 },
    //     { value: 62, text: 'Видео', href: '/media', tab: 1 }
    // ];

    public mounted() {
        this.$router.afterEach((to, from) => {
            this.path = to.path;

            let parentComponent: Vue | App | undefined = this.$parent;
            while ((parentComponent !== undefined) && (!(parentComponent instanceof App))) {
                parentComponent = parentComponent.$parent;
            }
        });
        this.path = this.$route.path;
        // this.loadBudget()
    }

    public openBoard() {
        this.fullBoard = !this.fullBoard;
        this.$root.$emit('bv::toggle::collapse', 'menu-link-' + this.link);
        this.$root.$emit('openBoard', true);
    }

    public clickedLink(val: any){
        this.link = val;
    }

    public closeCollapse() {
        if (this.link && !this.fullBoard) {
            this.$root.$emit('bv::toggle::collapse', 'menu-link-' + this.link);
            this.link = null;
        }
    }

    public clickRoute(link: IMenuSection) {
        let route: any;

        /* if ((link.value === 41) || (link.value === 42)) {
            window.open(link.href, '_blank');
        }
        this.$router.push({ path: link.href, query: link.params }); */
        switch (link.value) {
            case '5':
                window.open(link.href, '_blank');
                break;
            case '42':
                window.open(link.href, '_blank');
                break;
            default:
                if (link.href?.includes('://')) {
                    window.open(link.href, '_blank', 'noopener noreferrer');
                    return;
                }

                route = this.$router.resolve(
                    {
                        path: link.href
                        // query: link.query
                    },
                    undefined,
                    false
                );

                this.$router.push(route.route);
                break;
        }
    }

    public onSelectSection(data: any) {
        this.curSection = data;

        /*
        Если нет ссылки - вываливаемся нахрен отсюда
         */
        if ((!(data instanceof Object)) || (typeof data.href !== 'string')) {
            console.error('Cannot handle invalid routing:', data);
            return;
        }

        /*
        Если ссылка с протоколом - она внешняя, открывает скотину в новой вкладке
         */
        if (data.href.includes('://')) {
            window.open(data.href, '_blank', 'noopener noreferrer');
            return;
        }

        /*
        Без этого не поддерживались ссылки более чем с 1 уровнем пути
        (если после "staffing-table/data" открывать "budget-variants", то открывалось пустая страница "/staffing-table/budget-variants")
         */
        const resolved = this.$router.resolve(
            {
                path: data.href,
                query: data.query,
                params: data.params
            },
            undefined,
            false
        );

        /*
        Параметры в отдельном объекте - нефиг мусор в путях держать
         */
        this.$router.push(resolved.location);
        this.$emit('selectSection', this.curSection);
    }

    public getSectionHref(data: any): string {
        if ((!(data instanceof Object)) || (typeof data.href !== 'string')) {
            return '#';
        }

        if (data.href.includes('://')) {
            return data.href;
        }

        return this.$router.resolve(
            {
                path: data.href,
                query: data.query,
                params: data.params
            },
            undefined,
            false
        )
            .href;
    }

    public get isAuthorized(): boolean {
        return store.state.user.authorized;
    }

    public created() {
        // this.loadProgram();
        this.getCategory();
        // this.getWidgets();
        this.loadRealm();
        // this.getMenuBudget();
    }

    private menuSocEcos: ISectiones[] = [];
    private menuSocMods: ISectiones[] = [];


    // private async loadProgram() {
    //     this.programData = await fetch('/api/dict/program')
    //         .then(response => response.json());
    // }
    //
    // private get menuProgram_(): ISectiones[] {
    //     const result: ISectiones[] = [{
    //         id: -1,
    //         name: 'Общие показатели',
    //         href: '/program-monitor2',
    //         hiddenRoles: []
    //     }];
    //
    //
    //     // Версия для показа
    //     const budgetRequestMenuItem = {
    //         id: 22,
    //         href: '/budget-request',
    //         i18n: 'app.links.budget_requests',
    //
    //         hiddenRoles: []
    //     };
    //     return [
    //         {
    //             id: 1,
    //             name: 'Лимиты',
    //             i18n: 'modules.app.links.budget-limit',
    //             href: '/budget-limit',
    //
    //             hiddenRoles: []
    //         },
    //         budgetRequestMenuItem,
    //         {
    //             id: 2,
    //             name: 'Формирование',
    //             i18n: 'modules.app.links.budget-form',
    //             href: '/budget-form',
    //
    //             hiddenRoles: []
    //         },
    //         {
    //             id: 2,
    //             name: 'Корректировка',
    //             i18n: 'modules.app.links.budget-correct',
    //             href: '/budget-correct',
    //
    //             hiddenRoles: []
    //         },
    //         {
    //             id: 3,
    //             name: 'Уточнеие',
    //             i18n: 'modules.app.links.budget-clarify',
    //             href: '/budget-clarify',
    //
    //             hiddenRoles: []
    //         },
    //         {
    //             id: 4,
    //             name: 'Сводные отчеты',
    //             i18n: 'modules.app.links.budget-report',
    //             href: '/budget-report',
    //
    //             hiddenRoles: []
    //         },
    //         {
    //             id: 5,
    //             name: 'ЕБК',
    //             i18n: 'modules.app.links.budget-dicts',
    //             href: '/budget-dicts',
    //
    //             hiddenRoles: []
    //         }
    //     ];
    // }

    // private get menuProgram__(): ISectiones[] {
    //     const result: ISectiones[] = [{
    //         id: -1,
    //         name: 'Общие показатели',
    //         href: '/program-monitor2',
    //         hiddenRoles: []
    //     }];
    //     for (const el of this.programData) {
    //         const newObj: any = { href: '/program-monitor2', id: el.id, query: { id: el.id } };
    //         if (this.$i18n.locale === 'kk') {
    //             newObj.name = el.name_kz;
    //         } else {
    //             newObj.name = el['name_' + this.$i18n.locale];
    //         }
    //         // newObj.name = newObj.name_ru;
    //         newObj.hiddenRoles = [];
    //         newObj.code = el.id;
    //         const pushObj: ISectiones = {
    //             name: newObj.name,
    //             id: newObj.id,
    //             hiddenRoles: [],
    //             href: newObj.href,
    //             query: newObj.query
    //         };
    //         result.push(pushObj);
    //     }
    //     return result;
    // }


    // Загрузка виджетов по СЭП и СЭМ
    private async getCategory() {
        let lists: any[] = [];
        const category: any[] = [];
        await fetch('/api/dict/integration_category')
            .then(response => response.json())
            .then(json => (lists = json));
        lists.sort((a: any, b: any) => a.id - b.id);
        const widgets = this.getWidgets();
        for (const list of lists) {
            list.code = list.code.replace(' \t', '');
            if (list.code.split('.')[0] === '01') {
                list.widgets = [];
                // eslint-disable-next-line no-await-in-loop
                for (const widget of await widgets) {
                    if ((widget.dictCategoryEntity !== undefined) && (widget.dictCategoryEntity !== null)) {
                        if (list.id === widget.dictCategoryEntity.id) {
                            list.widgets.push(widget);
                        }
                    }
                }
                if (list.widgets.length > 0 && list.code.split('.').length === 2) {
                    this.menuSocEcos.push({
                        name: list.name_ru,
                        href: '/soc-eco',
                        id: list.code,
                        hiddenRoles: ['roleTest'],
                        query: {
                            name: list.name_ru,
                            href: '/soc-eco',
                            id: list.id,
                            code: list.code
                        },
                        moduleCode: '001.000'
                    });
                }
            }
            if (list.code.split('.')[0] === '02') {
                list.widgets = [];
                // eslint-disable-next-line no-await-in-loop
                for (const widget of await widgets) {
                    if ((widget.dictCategoryEntity !== undefined) && (widget.dictCategoryEntity !== null)) {
                        if (list.id === widget.dictCategoryEntity.id) {
                            list.widgets.push(widget);
                        }
                    }
                }
            }
        }
        for (const list of lists) {
            if (list.code.split('.')[0] === '02') {
                list.children = [];
                if (list.parent !== null) {
                    lists.forEach((categ: any) => {
                        if (categ.children !== undefined) {
                            if (categ.id === list.parent) {
                                categ.children.push(list);
                            }
                        }
                    });
                }
            }
            if (list.id === 231) {
                category.push(list);
            }
        }
        const resMods: ISectiones[] = [];
        category[0].children.forEach((list: any) => {
            if (list.children.length > 0) {
                for (const child of list.children) {
                    if (child.widgets.length > 0) {
                        child.widgets.forEach((widget: any) => {
                            list.widgets.push(widget);
                        });
                    }
                    if (child.children.length > 0) {
                        for (const childish of child.children) {
                            if (childish.widgets.length > 0) {
                                childish.widgets.forEach((widget: any) => {
                                    child.widgets.push(widget);
                                    list.widgets.push(widget);
                                });
                            }
                        }
                    }
                }
            }
            if (list.widgets.length > 0) {
                resMods.push({
                    name: list.name_ru,
                    id: list.code,
                    href: '/soc-mod',
                    hiddenRoles: ['role_gu'],
                    query: {
                        name: list.name_ru,
                        code: list.code,
                        id: list.id,
                        href: '/soc-mod'
                    },
                    moduleCode: '002.000'
                });
            }
        });
        const firstIndex = resMods.findIndex(item => item.name === 'Основные показатели');
        resMods.push(...this.menuSocMods.splice(0, firstIndex));
        resMods.sort((a: any, b: any) => parseInt(a.id.split('.')[1]) - parseInt(b.id.split('.')[1]));
        this.menuSocMods = resMods;
        // console.log('this.menuSocMods', this.menuSocMods);
    }

    private async getWidgets() {
        let lists: any[] = [];
        await fetch('/api-py/datas/category-hierarchy')
            .then(response => response.json())
            .then(json => (lists = json));
        return lists;
    }

    public hasModuleAccess(moduleFullCode: string): boolean {
        const topLevelCode: string = moduleFullCode.split('.')[0];
        //sep sem mm usefull guide
        const defaultModules: string[] = ['008', '012'];
        return  defaultModules.includes(topLevelCode) ||
                store.state.user.userModules.find(i => {
                const userModules: string[] = i.modules.split('.');
                return topLevelCode === userModules[0];
            }) !== undefined;
    }

}