import { IMessage } from "@/modules/cabinet/Notifications.vue";
import axios from "axios";
import { ActionContext } from "vuex";
import store from "@/services/store";

interface IState {
    messages: IMessage[];
    unreadMessages: IMessage[];
    countUnreadMessages: number;
    curTab: number;
    isShowPopUp: boolean;
}

const state: IState = {
    messages: [],
    unreadMessages: [],
    countUnreadMessages: 0,
    curTab: 0,
    isShowPopUp: false,
};

const mutations = {
    setMessages(state: IState, messages: IMessage[]) {
        state.messages = messages;
    },
    updateMessageStatus(
        state: IState,
        { id, status }: { id: number; status: number }
    ) {
        const message = state.messages.find((msg: IMessage) => msg.id === id);
        if (message) {
            message.status = status;
        }
    },
    setIsShowPopUp(state: IState, statusPopUp: boolean) {
        state.isShowPopUp = statusPopUp;
    },
    setUnreadCount(state: IState, count: number) {
        state.countUnreadMessages = count;
    },

    setUnreadMessages(state: IState, messages: any[]) {
        state.unreadMessages = messages.map((message: any) => {
            const createDate = new Date(message.createDate);
            const formattedCreateDate = createDate.toLocaleDateString("ru-RU", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
            const formattedCreateTime = createDate.toLocaleTimeString("ru-RU", {
                hour: "2-digit",
                minute: "2-digit",
            });
            const formattedTheme =
                message.theme.charAt(0).toUpperCase() + message.theme.slice(1);
            return {
                id: message.id,
                sendDate: formattedCreateDate,
                sendTime: formattedCreateTime,
                title: formattedTheme,
                module: message.module_name,
                text: message.msg,
                dedline: message.endDate,
                status: message.status,
            };
        });
    },
    addUnreadMessage(state: IState, message: any) {
        const createDate = new Date(message.createDate);
        const formattedCreateDate = createDate.toLocaleDateString("ru-RU", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
        const formattedCreateTime = createDate.toLocaleTimeString("ru-RU", {
            hour: "2-digit",
            minute: "2-digit",
        });
        const formattedTheme =
            message.theme.charAt(0).toUpperCase() + message.theme.slice(1);
        state.unreadMessages.push({
            id: message.id,
            sendDate: formattedCreateDate,
            sendTime: formattedCreateTime,
            title: formattedTheme,
            module: message.module_name,
            text: message.msg,
            dedline: message.endDate,
            status: message.status,
        });
    },
    setCurTab(state: IState, tab: number) {
        state.curTab = tab;
    },
};

const actions = {
    async fetchMessages(
        { commit, state }: ActionContext<IState, any>,
        { startDate, endDate }: { startDate?: string; endDate?: string }
    ) {
        try {
            let url = "";
            if (state.curTab === 0) {
                if (startDate && endDate) {
                    url = "/api/notify/user/listbetween";
                } else {
                    url = "/api/notify/user/list";
                }
            } else if (state.curTab === 1) {
                url = "/api/notify/user/listme";
            } else {
                console.log("Неизвестная вкладка: ", state.curTab);
                return;
            }

            const params = new URLSearchParams();
            if (startDate) params.append("begdate", startDate);
            if (endDate) params.append("enddate", endDate);
            url += `?${params.toString()}`;

            const response = await axios.get(url);
            commit("setMessages", response.data);
        } catch (error) {
            console.error("Ошибка при загрузке сообщений:", error);
        }
    },
    async fetchOutMessages({ commit }: ActionContext<IState, any>) {
        try {
            const response = await axios.get("/api/notify/user/listme");
            commit("setMessages", response.data);
        } catch (error) {
            console.error("Error fetching out messages:", error);
        }
    },

    async fetchUnreadMessages({ commit }: ActionContext<IState, any>) {
        try {
            const response = await axios.get("/api/notify/user/unread-list");
            if (response.status === 200) {
                const messages = response.data;

                // Sort messages by date and time in desc
                messages.sort((a: any, b: any) => {
                    const dateA: any = new Date(a.createDate);
                    const dateB: any = new Date(b.createDate);
                    return dateB - dateA;
                });

                commit("setUnreadMessages", messages);
            } else {
                console.error(
                    "Error fetching unread messages. Unexpected response status:",
                    response.status
                );
            }
        } catch (error) {
            console.error("Error fetching unread messages:", error);
        }
    },

    async changeStatus(
        { commit, dispatch }: ActionContext<IState, any>,
        { id, status }: { id: number; status: number }
    ) {
        try {
            await axios.post(
                `api/notify/user/change-status?id=${id}&status=${status}`
            );
            commit("updateMessageStatus", { id, status });
            await dispatch("fetchCountUnreadNoty");
            await dispatch("fetchUnreadMessages");
        } catch (error) {
            console.error("Error changing message status:", error);
        }
    },

    async fetchCountUnreadNoty({ commit }: ActionContext<IState, any>) {
        try {
            const res = await axios.get(`/api/notify/user/unread-count`);
            commit("setUnreadCount", res.data);
        } catch (error) {
            console.error("Error fetching unread messages count:", error);
        }
    },
    async fetchStatusShowPopUp({ commit }: ActionContext<IState, any>) {
        try {
            const res = await axios.get(
                `/api/um/user-msg/${store.getters.user_uuid}`
            );
            commit("setIsShowPopUp", res.data[0]);
        } catch (error) {
            console.error("Error fetching status of popUp for user:", error);
        }
    },
    async toggleShowPopUp(
        { commit }: ActionContext<IState, any>,
        { user_id, vals }: { user_id: string; vals: any[] }
    ) {
        try {
            const popUpData = {
                user_id: user_id,
                vals: vals,
            };
            const response = await axios.post(
                "/api/um/save-user-msg",
                popUpData
            );
            commit("setIsShowPopUp", vals[0].item);
        } catch (error) {
            console.error("Error changing status of popUp for user:", error);
        }
    },
};

const getters = {
    messages: (state: IState) => state.messages,
    countUnreadMessages: (state: IState) => state.countUnreadMessages,
    unreadMessages: (state: IState) => state.unreadMessages,
    isShowPopUp: (state: IState) => state.isShowPopUp,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
