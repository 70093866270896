// noinspection RedundantIfStatementJS

import type { I18nDb } from '.';
import service, { constants } from '.';


class ValidationError extends Error implements I18nDb.Validation.ErrorInfo {
    readonly en: string;
    readonly kk: string;
    readonly ru: string;
    readonly range: I18nDb.Validation.Range;

    constructor(en: string, kk: string, ru: string, start: number, end: number) {
        super(en);
        this.en = en;
        this.kk = kk;
        this.ru = ru;
        this.range = { start, end };
    }
}


// region Restrictions, checks, utils
const parseInteger = (start: number, end: number, value: string): number => {
    const result = parseInt(value);

    if ((!Number.isSafeInteger(result)) || (value !== String(result))) {
        throw new ValidationError(
            'Invalid argument index text - not integer',
            'Некорректный текст индекса аргумента - не является целым числом (каз)',
            'Некорректный текст индекса аргумента - не является целым числом',
            start,
            end,
        );
    }

    return result;
};


let _temporalArgumentTypeNames: Set<string> | undefined;
const temporalArgumentTypeNames = (): Set<string> => {
    if (_temporalArgumentTypeNames === undefined) {
        const result = new Set([DatePart.TYPE_NAME, TimePart.TYPE_NAME, DateTimePart.TYPE_NAME]);
        _temporalArgumentTypeNames = result;
        return result;
    } else {
        return _temporalArgumentTypeNames;
    }
};

let _argumentTypeNames: Set<string> | undefined;
const argumentTypeNames = (): Set<string> => {
    if (_argumentTypeNames === undefined) {
        const result = new Set([TextArgumentPart.TYPE_NAME, NumberArgumentPart.TYPE_NAME, ...temporalArgumentTypeNames()]);
        _argumentTypeNames = result;
        return result;
    } else {
        return _argumentTypeNames;
    }
};

let _filterTypesNames: Set<string> | undefined;
const filterTypesNames = (): Set<string> => {
    if (_filterTypesNames === undefined) {
        const result = new Set([TrimFilter.TYPE_NAME, CapitalizeFilter.TYPE_NAME, UpperFilter.TYPE_NAME, LowerFilter.TYPE_NAME]);
        _filterTypesNames = result;
        return result;
    } else {
        return _filterTypesNames;
    }
};


const textOnlyKey = (key: string | undefined): boolean => {
    if (key == undefined) {
        return false;
    } else if (key.startsWith(constants.KEY_PREFIX__NAME__MONTH_DAY)) {
        return true;
    } else if (key.startsWith(constants.KEY_PREFIX__NAME__MONTH_DAY_SHORT)) {
        return true;
    } else {
        return false;
    }
};

const checkTextOnlyKey = (key: string | undefined, start: number, end: number, argumentTypeName: string) => {
    if (textOnlyKey(key) && (argumentTypeName != TextArgumentPart.TYPE_NAME)) {
        `Key "${key}" can be compiled only with \"${TextArgumentPart.TYPE_NAME}\" argument type, but there is type "${argumentTypeName}"`
        throw new ValidationError(
            `Key "${key}" can be compiled only with arguments of type \"${TextArgumentPart.TYPE_NAME}\" argument type, but there is argument of type "${argumentTypeName}"`,
            `Ключ "${key}" может быть скомпилирован только с аргументами типа \"${TextArgumentPart.TYPE_NAME}\", но встречен аргумент типа "${argumentTypeName}" (каз)`,
            `Ключ "${key}" может быть скомпилирован только с аргументами типа \"${TextArgumentPart.TYPE_NAME}\", но встречен аргумент типа "${argumentTypeName}"`,
            start,
            end,
        );
    }
};


const maxTemporalLevelOfKey = (key: string | undefined): number => {
    if (key === undefined) {
        return Number.MAX_SAFE_INTEGER;
    } else if (key.startsWith(constants.KEY_PREFIX__DATE_TIME__TEMPLATE__DATE)) {
        return 0;
    } else if (key.startsWith(constants.KEY_PREFIX__DATE_TIME__TEMPLATE__TIME)) {
        return 0;
    } else if (key == constants.KEY__DATE_TIME__TEMPLATE__DATE_TIME) {
        return 1;
    } else {
        return Number.MAX_SAFE_INTEGER;
    }
};

const temporalLevelOfArgument = (start: number, end: number, type: string, subtype: string | undefined): number => {
    switch (type) {
        case DatePart.TYPE_NAME:
            switch (subtype) {
                case undefined:
                    `Null subtype is forbidden for type "${type}"`
                    throw new ValidationError(
                        `NULL subtype is forbidden for type "${type}"`,
                        `Подтип NULL запрещен для типа "${type}" (каз)`,
                        `Подтип NULL запрещен для типа "${type}"`,
                        start,
                        end,
                    );
                case DateYearPart.SUBTYPE_NAME:
                    return 0;
                case DateMonthPart.SUBTYPE_NAME:
                    return 0;
                case DateDayPart.SUBTYPE_NAME:
                    return 0;
                case DateMonthDayPart.SUBTYPE_NAME:
                    return 0;
                case DateWeekDayPart.SUBTYPE_NAME:
                    return 0;
                case DateShortPart.SUBTYPE_NAME:
                    return 1;
                case DateLongPart.SUBTYPE_NAME:
                    return 1;
                case DateNumericPart.SUBTYPE_NAME:
                    return 1;
                default:
                    throw new ValidationError(
                        `Unknown subtype "${subtype}" for type "${type}"`,
                        `Неизвестный подтип "${subtype}" для типа "${type}" (каз)`,
                        `Неизвестный подтип "${subtype}" для типа "${type}"`,
                        start,
                        end,
                    );
            }
        case TimePart.TYPE_NAME:
            switch (subtype) {
                case null:
                    throw new ValidationError(
                        `NULL subtype is forbidden for type "${type}"`,
                        `Подтип NULL запрещен для типа "${type}" (каз)`,
                        `Подтип NULL запрещен для типа "${type}"`,
                        start,
                        end,
                    );
                case TimeAmPmPart.SUBTYPE_NAME:
                    return 0;
                case TimeHourPart.SUBTYPE_NAME:
                    return 0;
                case TimeMinutePart.SUBTYPE_NAME:
                    return 0;
                case TimeSecondPart.SUBTYPE_NAME:
                    return 0;
                case TimeNoSecondsPart.SUBTYPE_NAME:
                    return 1;
                case TimeWithSecondsPart.SUBTYPE_NAME:
                    return 1;
                default:
                    throw new ValidationError(
                        `Unknown subtype "${subtype}" for type "${type}"`,
                        `Неизвестный подтип "${subtype}" для типа "${type}" (каз)`,
                        `Неизвестный подтип "${subtype}" для типа "${type}"`,
                        start,
                        end,
                    );
            }
        case DateTimePart.TYPE_NAME:
            return 2;
        default:
            throw new ValidationError(
                `Unknown type "${type}"`,
                `Неизвестный тип "${type}" (каз)`,
                `Неизвестный тип "${type}"`,
                start,
                end,
            );
    }
};

const checkTemporalArgument = (start: number, end: number, key: string | undefined, type: string, subtype: string | undefined) => {
    if (key === undefined) {
        return;
    }

    const maxLevel = maxTemporalLevelOfKey(key);
    const currentLevel = temporalLevelOfArgument(start, end, type, subtype);
    if (currentLevel > maxLevel) {
        const enMessageParts = ['Argument with type "', type, '"'];
        const kkMessageParts = ['Аргумент с типом "', type, '"'];
        const ruMessageParts = ['Аргумент с типом "', type, '"'];

        if (subtype !== undefined) {
            enMessageParts.push(' and subtype "', subtype, '"');
            kkMessageParts.push(' и подтипом "', subtype, '"');
            ruMessageParts.push(' и подтипом "', subtype, '"');
        }

        enMessageParts.push(' cannot be used in template with key "', key, '"');
        kkMessageParts.push(' не может быть использовать в шаблоне с ключом "', key, '" (каз)');
        ruMessageParts.push(' не может быть использовать в шаблоне с ключом "', key, '"');

        throw new ValidationError(
            enMessageParts.join(''),
            kkMessageParts.join(''),
            ruMessageParts.join(''),
            start,
            end,
        );
    }
};

const checkingTemporalArgument = <ST extends (string | undefined), R>(
    start: number,
    end: number,
    key: string | undefined,
    argumentTemplateParts: Array<String>,
    type: string,
    defaultSubtype: ST,
    block: ((subtype: ST) => R),
): R => {
    let subtype: ST
    if (argumentTemplateParts.length > 2) {
        const parsedSubtype = argumentTemplateParts[2].trim().toLowerCase();
        if (filterTypesNames().has(parsedSubtype)) {
            subtype = defaultSubtype;
        } else {
            subtype = (parsedSubtype as ST);
        }
    } else {
        subtype = defaultSubtype;
    }

    checkTemporalArgument(start, end, key, type, subtype);

    return block(subtype);
};


const temporalFormat = (argumentTemplateParts: Array<string>, defaultFormat: string): string => {
    if (argumentTemplateParts.length > 3) {
        const format = argumentTemplateParts[3].trim().toLowerCase();
        if (filterTypesNames().has(format)) {
            return defaultFormat;
        } else {
            return format;
        }
    } else {
        return defaultFormat;
    }
};


const twoDigit = (value: number): string => {
    let result = String(value);
    if (result.length > 2) {
        result = result.substring(result.length - 2);
    }
    while (result.length < 2) {
        result = '0' + result;
    }

    return result;
};
// endregion


// region Parts - base
abstract class Part {
    abstract argType: I18nDb.CompiledArgType | undefined;

    abstract toString(...args: Array<unknown>): string;
}

enum Mode {
    DEFAULT = 0,
    ESCAPING = 1,
    ARGUMENT = 2,
}

const parseParts = (key: string | undefined, template: string): Array<Part> => {
    const result: Array<Part> = [];

    let mode = Mode.DEFAULT;
    const lastIndex = (template.length - 1);
    let startIndex = 0;
    let index = 0;

    const addStatic = () => {
        addStaticPart(result, template, startIndex, index);
    };

    const addArgument = () => {
        if (startIndex < index) {
            const argumentTemplateParts = template.substring(startIndex, index).split(';');
            addArgumentPart(key, startIndex, index, argumentTemplateParts, result);
            addFilterParts(startIndex, index, argumentTemplateParts, result);
        }
    };

    while (index <= lastIndex) {
        const char = template[index];
        switch (mode) {
            case Mode.DEFAULT:
                switch (char) {
                    case '\\':
                        addStatic();

                        mode = Mode.ESCAPING;
                        index++;
                        startIndex = index;
                        break;
                    case '{':
                        addStatic();

                        mode = Mode.ARGUMENT;
                        index++;
                        startIndex = index;
                        break;
                    default:
                        index++;
                        break;
                }
                break;
            case Mode.ESCAPING:
                mode = Mode.DEFAULT;
                index++;
                break;
            case Mode.ARGUMENT:
                if (char == '}') {
                    addArgument();

                    mode = Mode.DEFAULT;
                    index++;
                    startIndex = index;
                } else {
                    index++;
                }
                break;
        }
    }

    if (startIndex < template.length) {
        index = template.length;
        switch (mode) {
            case Mode.DEFAULT:
                addStatic();
                break;
            case Mode.ESCAPING:
                addStatic();
                break;
            case Mode.ARGUMENT:
                addArgument();
                break;
        }
    }

    return result;
}
// endregion


// region Parts - static
class StaticPart extends Part {
    private readonly text: string;

    constructor(text: string) {
        super();
        this.text = text;
    }

    argType = undefined;

    toString(...args: Array<unknown>): string {
        return this.text;
    }
}

const addStaticPart = (target: Array<Part>, template: string, start: number, end: number) => {
    if (start < end) {
        const text = template.substring(start, end);
        const part = new StaticPart(text);
        target.push(part);
    }
}
// endregion


// region Parts - arguments - base
abstract class ArgumentPart extends Part {
    abstract readonly argumentIndex: number;
    abstract readonly typeAlias: string;

    getArgument(...args: Array<unknown>): unknown {
        if (this.argumentIndex >= args.length) {
            throw new Error(`Too few arguments - max argument index is ${args.length}, required argument index is ${this.argumentIndex}`);
        } else {
            return args[this.argumentIndex];
        }
    }

    abstract argumentToString(argumentValue: unknown): string

    nullToString(): string { return ""; }

    toString(...args: Array<unknown>): string {
        const argumentValue = this.getArgument(...args);
        if (argumentValue === null) {
            return this.nullToString();
        } else {
            return this.argumentToString(argumentValue);
        }
    }
}

class TextArgumentPart extends ArgumentPart {
    static readonly TYPE_NAME: string = "text";

    readonly argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return TextArgumentPart.TYPE_NAME;
    }

    get argType(): I18nDb.CompiledArgType {
        return 'ANY';
    }

    argumentToString(argumentValue: unknown): string {
        return String(argumentValue);
    }
}

const addArgumentPart = (key: string | undefined, start: number, end: number, argumentTemplateParts: Array<string>, target: Array<Part>) => {
    if (argumentTemplateParts.length === 0) {
        throw new ValidationError(
            'No argument index',
            'Нет индекса аргумента (каз)',
            'Нет индекса аргумента',
            start,
            end,
        );
    }

    const argumentIndexPart = argumentTemplateParts[0];
    const argumentIndex = parseInteger(start, end, argumentIndexPart);
    if (argumentIndex < 0) {
        throw new ValidationError(
            'Invalid argument index text - negative index',
            'Некорректный текст индекса аргумента - отрицательный индекс (каз)',
            'Некорректный текст индекса аргумента - отрицательный индекс',
            start,
            end,
        );
    }

    let typeOrFilter: string | null;
    if (argumentTemplateParts.length > 1) {
        typeOrFilter = argumentTemplateParts[1].trim().toLowerCase();
    } else {
        typeOrFilter = null;
    }

    let argumentTypeName: string;
    if (typeOrFilter == null) {
        argumentTypeName = TextArgumentPart.TYPE_NAME;
    } else if (argumentTypeNames().has(typeOrFilter)) {
        argumentTypeName = typeOrFilter;
    } else if (filterTypesNames().has(typeOrFilter)) {
        argumentTypeName = TextArgumentPart.TYPE_NAME;
    } else {
        `Unexpected part \"${typeOrFilter}\" - it is not argument type or filter`;
        throw new ValidationError(
            `Unexpected part \"${typeOrFilter}\" - it is not argument type or filter`,
            `Непредвиденная часть \"${typeOrFilter}\" - она не является типом аргумента или фильтром (каз)`,
            `Непредвиденная часть \"${typeOrFilter}\" - она не является типом аргумента или фильтром`,
            start,
            end,
        );
    }


    checkTextOnlyKey(key, start, end, argumentTypeName);

    let argumentPart: ArgumentPart;
    if (argumentTypeName == TextArgumentPart.TYPE_NAME) {
        argumentPart = new TextArgumentPart(argumentIndex);
    } else if (temporalArgumentTypeNames().has(argumentTypeName)) {
        argumentPart = createTemporalArgumentPart(key, start, end, argumentTemplateParts, argumentIndex, argumentTypeName);
    } else if (argumentTypeName == NumberArgumentPart.TYPE_NAME) {
        argumentPart = createNumberArgumentPart(start, end, argumentTemplateParts, argumentIndex);
    } else {
        throw new ValidationError(
            `Unexpected argument type \"${argumentTypeName}\"`,
            `Неизвестный тип аргумента \"${argumentTypeName}\" (каз)`,
            `Неизвестный тип аргумента \"${argumentTypeName}\"`,
            start,
            end,
        );
    }

    target.push(argumentPart);
}
// endregion


// region Parts - arguments - temporal
abstract class TemporalPart extends ArgumentPart {
    protected throwUnexpectedArgumentClassError(value: unknown): never {
        throw new Error(`Cannot format argument of type ${typeof value} as ${this.typeAlias}`);
    }

    protected abstract format(value: Date): string

    argumentToString(argumentValue: unknown): string {
        let preparedDate: Date;
        if (argumentValue instanceof Date) {
            preparedDate = argumentValue;
        } else {
            const type = typeof argumentValue;
            if ((type === 'string') || (type === 'number')) {
                preparedDate = new Date((argumentValue as string | number));
            } else {
                this.throwUnexpectedArgumentClassError(argumentValue)
            }
        }

        const year = preparedDate.getFullYear();
        if (Number.isNaN(year)) {
            throw new Error('Cannot format invalid date');
        }

        return this.format(preparedDate);
    }

    get argType(): I18nDb.CompiledArgType {
        return 'DATE';
    }
}

const createTemporalArgumentPart = (key: string | undefined, start: number, end: number, argumentTemplateParts: Array<string>, argumentIndex: number, type: String): TemporalPart => {
    switch (type) {
        case DatePart.TYPE_NAME:
            return createDateArgumentPart(key, start, end, argumentTemplateParts, argumentIndex);
        case TimePart.TYPE_NAME:
            return createTimeArgumentPart(key, start, end, argumentTemplateParts, argumentIndex);
        default:
            return createDateTimeArgumentPart(key, start, end, argumentTemplateParts, argumentIndex);
    }
}
// endregion


// region Parts - arguments - temporal - date
abstract class DatePart extends TemporalPart {
    static readonly TYPE_NAME: string = 'date';
}

const createDateArgumentPart = (key: string | undefined, start: number, end: number, argumentTemplateParts: Array<string>, argumentIndex: number): DatePart => {
    return checkingTemporalArgument(start, end, key, argumentTemplateParts, DatePart.TYPE_NAME, DateShortPart.SUBTYPE_NAME, (subtype) => {
        switch (subtype) {
            case DateYearPart.SUBTYPE_NAME:
                return createDateYearPart(key, start, end, argumentTemplateParts, argumentIndex);
            case DateMonthPart.SUBTYPE_NAME:
                return createDateMonthPart(key, start, end, argumentTemplateParts, argumentIndex);
            case DateDayPart.SUBTYPE_NAME:
                return createDateDayPart(key, start, end, argumentTemplateParts, argumentIndex);
            case DateMonthDayPart.SUBTYPE_NAME:
                return createDateMonthDayPart(key, start, end, argumentTemplateParts, argumentIndex);
            case DateWeekDayPart.SUBTYPE_NAME:
                return createDateWeekDayPart(key, start, end, argumentTemplateParts, argumentIndex);
            case DateLongPart.SUBTYPE_NAME:
                return new DateLongPart(argumentIndex);
            case DateShortPart.SUBTYPE_NAME:
                return new DateShortPart(argumentIndex);
            case DateNumericPart.SUBTYPE_NAME:
                return new DateNumericPart(argumentIndex);
            default:
                throw new ValidationError(
                    `Unknown subtype "${subtype}" for type "${DatePart.TYPE_NAME}"`,
                    `Неизветсный подтип "${subtype}" для типа "${DatePart.TYPE_NAME}" (каз)`,
                    `Неизветсный подтип "${subtype}" для типа "${DatePart.TYPE_NAME}"`,
                    start,
                    end,
                );
        }
    });
}


abstract class DateYearPart extends DatePart {
    static readonly SUBTYPE_NAME: string = `year`
    static readonly TYPE_ALIAS = `${DateYearPart.TYPE_NAME}[${DateYearPart.SUBTYPE_NAME}]`

    protected abstract formatYear(value: number): string

    protected format(value: Date): string {
        return this.formatYear(value.getFullYear());
    }
}

class DateYearNumericPart extends DateYearPart {
    static readonly FORMAT_NAME: string = `numeric`;
    static readonly TYPE_ALIAS = `${DateYearNumericPart.TYPE_NAME}[${DateYearNumericPart.SUBTYPE_NAME};${DateYearNumericPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateYearNumericPart.TYPE_ALIAS;
    }

    protected formatYear(value: number): string {
        return value.toString();
    }
}

class DateYear2DigitPart extends DateYearPart {
    static readonly FORMAT_NAME: string = `2-digit`;
    static readonly TYPE_ALIAS = `${DateYear2DigitPart.TYPE_NAME}[${DateYear2DigitPart.SUBTYPE_NAME};${DateYear2DigitPart.FORMAT_NAME}]`

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateYear2DigitPart.TYPE_ALIAS;
    }

    protected formatYear(value: number): string {
        return twoDigit(value);
    }
}

const createDateYearPart = (key: string | undefined, start: number, end: number, argumentTemplateParts: Array<string>, argumentIndex: number): DateYearPart => {
    const format = temporalFormat(argumentTemplateParts, DateYearNumericPart.FORMAT_NAME);
    switch (format) {
        case DateYearNumericPart.FORMAT_NAME:
            return new DateYearNumericPart(argumentIndex);
        case DateYear2DigitPart.FORMAT_NAME:
            return new DateYear2DigitPart(argumentIndex);
        default:
            throw new ValidationError(
                `Unknown format "${format}" for type "${DateYearPart.TYPE_ALIAS}"`,
                `Неизвестный формат "${format}" для типа "${DateYearPart.TYPE_ALIAS}" (каз)`,
                `Неизвестный формат "${format}" для типа "${DateYearPart.TYPE_ALIAS}"`,
                start,
                end,
            );
    }
};


abstract class DateMonthPart extends DatePart {
    static readonly SUBTYPE_NAME: string = 'month';
    static readonly TYPE_ALIAS = `${DateMonthPart.TYPE_NAME}[${DateMonthPart.SUBTYPE_NAME}]`;

    abstract formatMonth(value: number): string

    protected format(value: Date): string {
        return this.formatMonth(value.getMonth());
    }
}

class DateMonthShortPart extends DateMonthPart {
    static readonly FORMAT_NAME: string = 'short';
    static readonly TYPE_ALIAS = `${DateMonthShortPart.TYPE_NAME}[${DateMonthShortPart.SUBTYPE_NAME};${DateMonthShortPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateMonthShortPart.TYPE_ALIAS;
    }

    formatMonth(value: number): string {
        return service.monthShortName(value);
    }
}

class DateMonthLongPart extends DateMonthPart {
    static readonly FORMAT_NAME: string = 'long';
    static readonly TYPE_ALIAS = `${DateMonthLongPart.TYPE_NAME}[${DateMonthLongPart.SUBTYPE_NAME};${DateMonthLongPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateMonthLongPart.TYPE_ALIAS;
    }

    formatMonth(value: number): string {
        return service.monthName(value);
    }
}

class DateMonthNumericPart extends DateMonthPart {
    static readonly FORMAT_NAME: string = `numeric`;
    static readonly TYPE_ALIAS = `${DateMonthNumericPart.TYPE_NAME}[${DateMonthNumericPart.SUBTYPE_NAME};${DateMonthNumericPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateMonthNumericPart.TYPE_ALIAS;
    }

    formatMonth(value: number): string {
        return String(value + 1);
    }
}

class DateMonth2DigitPart extends DateMonthPart {
    static readonly FORMAT_NAME: string = `2-digit`;
    static readonly TYPE_ALIAS = `${DateMonth2DigitPart.TYPE_NAME}[${DateMonth2DigitPart.SUBTYPE_NAME};${DateMonth2DigitPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateMonth2DigitPart.TYPE_ALIAS;
    }

    formatMonth(value: number): string {
        return twoDigit(value);
    }
}

const createDateMonthPart = (key: string | undefined, start: number, end: number, argumentTemplateParts: Array<string>, argumentIndex: number): DateMonthPart => {
    const format = temporalFormat(argumentTemplateParts, DateMonthNumericPart.FORMAT_NAME);
    switch (format) {
        case DateMonthShortPart.FORMAT_NAME:
            return new DateMonthShortPart(argumentIndex);
        case DateMonthLongPart.FORMAT_NAME:
            return new DateMonthLongPart(argumentIndex);
        case DateMonthNumericPart.FORMAT_NAME:
            return new DateMonthNumericPart(argumentIndex);
        case DateMonth2DigitPart.FORMAT_NAME:
            return new DateMonth2DigitPart(argumentIndex);
        default:
            throw new ValidationError(
                `Unknown format "${format}" for type "${DateMonthPart.TYPE_ALIAS}"`,
                `Неизвестный формат "${format}" для типа "${DateMonthPart.TYPE_ALIAS}" (каз)`,
                `Неизвестный формат "${format}" для типа "${DateMonthPart.TYPE_ALIAS}"`,
                start,
                end,
            );
    }
};


abstract class DateDayPart extends DatePart {
    static readonly SUBTYPE_NAME: string = 'day';
    static readonly TYPE_ALIAS = `${DateDayPart.TYPE_NAME}[${DateDayPart.SUBTYPE_NAME}]`;

    protected abstract formatDay(value: number): string;

    protected format(value: Date): string {
        const day = value.getDate();
        return this.formatDay(day);
    }
}

class DateDayNumericPart extends DateDayPart {
    static readonly FORMAT_NAME: string = 'numeric';
    static readonly TYPE_ALIAS = `${DateDayNumericPart.TYPE_NAME}[${DateDayNumericPart.SUBTYPE_NAME};${DateDayNumericPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateDayNumericPart.TYPE_ALIAS;
    }

    protected formatDay(value: number): string {
        return String(value);
    }
}

class DateDay2DigitPart extends DateDayPart {
    static readonly FORMAT_NAME: string = '2-digit'
    static readonly TYPE_ALIAS = `${DateDay2DigitPart.TYPE_NAME}[${DateDay2DigitPart.SUBTYPE_NAME};${DateDay2DigitPart.FORMAT_NAME}]`

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateDay2DigitPart.TYPE_ALIAS;
    }

    protected formatDay(value: number): string {
        return twoDigit(value);
    }
}

const createDateDayPart = (key: string | undefined, start: number, end: number, argumentTemplateParts: Array<string>, argumentIndex: number): DateDayPart => {
    const format = temporalFormat(argumentTemplateParts, DateDayNumericPart.FORMAT_NAME);
    switch (format) {
        case DateDayNumericPart.FORMAT_NAME:
            return new DateDayNumericPart(argumentIndex);
        case DateDay2DigitPart.FORMAT_NAME:
            return new DateDay2DigitPart(argumentIndex);
        default:
            throw new ValidationError(
                `Unknown format "$format" for type "${DateDayPart.TYPE_ALIAS}"`,
                `Неизвестный формат "$format" for type "${DateDayPart.TYPE_ALIAS}" (каз)`,
                `Неизвестный формат "$format" for type "${DateDayPart.TYPE_ALIAS}"`,
                start,
                end,
            );
    }
};


abstract class DateMonthDayPart extends DatePart {
    static readonly SUBTYPE_NAME: string = 'month-day';
    static readonly TYPE_ALIAS = `${DateMonthDayPart.TYPE_NAME}[${DateMonthDayPart.SUBTYPE_NAME}]`;

    protected abstract formatMonthDay(month: number, day: number): string;

    protected format(value: Date): string {
        const month = value.getMonth();
        const day = value.getDate();
        return this.formatMonthDay(month, day);
    }
}

class DateMonthDayShortPart extends DateMonthDayPart {
    static readonly FORMAT_NAME: string = 'short';
    static readonly TYPE_ALIAS = `${DateMonthDayShortPart.TYPE_NAME}[${DateMonthDayShortPart.SUBTYPE_NAME};${DateMonthDayShortPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateMonthDayShortPart.TYPE_ALIAS;
    }

    protected formatMonthDay(month: number, day: number): string {
        const template = service.monthDayShortTemplate(month);
        return service.translateByTemplate(template, day);
    }
}

class DateMonthDayLongPart extends DateMonthDayPart {
    static readonly FORMAT_NAME: string = 'long';
    static readonly TYPE_ALIAS = `${DateMonthDayLongPart.TYPE_NAME}[${DateMonthDayLongPart.SUBTYPE_NAME};${DateMonthDayLongPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateMonthDayLongPart.TYPE_ALIAS;
    }

    protected formatMonthDay(month: number, day: number): string {
        const template = service.monthDayTemplate(month);
        return service.translateByTemplate(template, day);
    }
}

const createDateMonthDayPart = (key: string | undefined, start: number, end: number, argumentTemplateParts: Array<string>, argumentIndex: number): DateMonthDayPart => {
    const format = temporalFormat(argumentTemplateParts, DateMonthDayShortPart.FORMAT_NAME);
    switch (format) {
        case DateMonthDayShortPart.FORMAT_NAME:
            return new DateMonthDayShortPart(argumentIndex);
        case DateMonthDayLongPart.FORMAT_NAME:
            return new DateMonthDayLongPart(argumentIndex);
        default:
            throw new ValidationError(
                `Unknown format "${format}" for type "${DateMonthDayPart.TYPE_ALIAS}"`,
                `Неизвестный формат "${format}" для типа "${DateMonthDayPart.TYPE_ALIAS}" (каз)`,
                `Неизвестный формат "${format}" для типа "${DateMonthDayPart.TYPE_ALIAS}"`,
                start,
                end,
            );
    }
};


abstract class DateWeekDayPart extends DatePart {
    static readonly SUBTYPE_NAME: string = 'week-day';
    static readonly TYPE_ALIAS = `${DateWeekDayPart.TYPE_NAME}[${DateWeekDayPart.SUBTYPE_NAME}]`;


    protected abstract formatWeekDay(value: number): string;

    protected format(value: Date): string {
        return this.formatWeekDay(value.getDay());
    }
}

class DateWeekDayNumericPart extends DateWeekDayPart {
    static readonly FORMAT_NAME: string = 'numeric';
    static readonly TYPE_ALIAS = `${DateWeekDayNumericPart.TYPE_NAME}[${DateWeekDayNumericPart.SUBTYPE_NAME};${DateWeekDayNumericPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateWeekDayNumericPart.TYPE_ALIAS;
    }

    protected formatWeekDay(value: number): string {
        return String(value);
    }
}

class DateWeekDayShortPart extends DateWeekDayPart {
    static readonly FORMAT_NAME: string = 'short';
    static readonly TYPE_ALIAS = `${DateWeekDayShortPart.TYPE_NAME}[${DateWeekDayShortPart.SUBTYPE_NAME};${DateWeekDayShortPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateWeekDayShortPart.TYPE_ALIAS;
    }

    protected formatWeekDay(value: number): string {
        return service.weekDayShort(value);
    }
}

class DateWeekDayLongPart extends DateWeekDayPart {
    static readonly FORMAT_NAME: string = 'long';
    static readonly TYPE_ALIAS = `${DateWeekDayLongPart.TYPE_NAME}[${DateWeekDayLongPart.SUBTYPE_NAME};${DateWeekDayLongPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateWeekDayLongPart.TYPE_ALIAS;
    }

    protected formatWeekDay(value: number): string {
        return service.weekDay(value);
    }
}

const createDateWeekDayPart = (key: string | undefined, start: number, end: number, argumentTemplateParts: Array<string>, argumentIndex: number): DateWeekDayPart => {
    const format = temporalFormat(argumentTemplateParts, DateWeekDayShortPart.FORMAT_NAME);
    switch (format) {
        case DateWeekDayNumericPart.FORMAT_NAME:
            return new DateWeekDayNumericPart(argumentIndex);
        case DateWeekDayShortPart.FORMAT_NAME:
            return new DateWeekDayShortPart(argumentIndex);
        case DateWeekDayLongPart.FORMAT_NAME:
            return new DateWeekDayLongPart(argumentIndex);
        default:
            throw new ValidationError(
                `Unknown format "${format}" for type "${DateWeekDayPart.TYPE_ALIAS}"`,
                `Неизвестный формат "${format}" для типа "${DateWeekDayPart.TYPE_ALIAS}" (каз)`,
                `Неизвестный формат "${format}" для типа "${DateWeekDayPart.TYPE_ALIAS}"`,
                start,
                end,
            );
    }
}


class DateShortPart extends DatePart {
    static readonly SUBTYPE_NAME: string = 'short';
    static readonly TYPE_ALIAS = `${DateShortPart.TYPE_NAME}[${DateShortPart.SUBTYPE_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateShortPart.TYPE_ALIAS;
    }

    protected format(value: Date): string {
        const template = service.dateTemplateShort();
        return service.translateByTemplate(template, value);
    }
}


class DateLongPart extends DatePart {
    static readonly SUBTYPE_NAME: string = 'long';
    static readonly TYPE_ALIAS = `${DateLongPart.TYPE_NAME}[${DateLongPart.SUBTYPE_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateLongPart.TYPE_ALIAS;
    }

    protected format(value: Date): string {
        const template = service.dateTemplateLong();
        return service.translateByTemplate(template, value);
    }
}


class DateNumericPart extends DatePart {
    static readonly SUBTYPE_NAME: string = 'numeric';
    static readonly TYPE_ALIAS = `${DateNumericPart.TYPE_NAME}[${DateNumericPart.SUBTYPE_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateNumericPart.TYPE_ALIAS;
    }

    protected format(value: Date): string {
        const template = service.dateTemplateNumeric();
        return service.translateByTemplate(template, value);
    }
}
// endregion


// region Parts - arguments - temporal - time
abstract class TimePart extends TemporalPart {
    static readonly TYPE_NAME: string = 'time';
}

const createTimeArgumentPart = (key: string | undefined, start: number, end: number, argumentTemplateParts: Array<string>, argumentIndex: number): TimePart => {
    return checkingTemporalArgument(start, end, key, argumentTemplateParts, TimePart.TYPE_NAME, TimeNoSecondsPart.SUBTYPE_NAME, (subtype) => {
        switch (subtype) {
            case TimeAmPmPart.SUBTYPE_NAME:
                return new TimeAmPmPart(argumentIndex);
            case TimeHourPart.SUBTYPE_NAME:
                return createTimeHourPart(key, start, end, argumentTemplateParts, argumentIndex);
            case TimeMinutePart.SUBTYPE_NAME:
                return createTimeMinutePart(key, start, end, argumentTemplateParts, argumentIndex);
            case TimeSecondPart.SUBTYPE_NAME:
                return createTimeSecondPart(key, start, end, argumentTemplateParts, argumentIndex);
            case TimeNoSecondsPart.SUBTYPE_NAME:
                return new TimeNoSecondsPart(argumentIndex);
            case TimeWithSecondsPart.SUBTYPE_NAME:
                return new TimeWithSecondsPart(argumentIndex);
            default:
                throw new ValidationError(
                    `Unknown subtype "${subtype}" for type "${TimePart.TYPE_NAME}"`,
                    `Неизвестный подтип "${subtype}" для типа "${TimePart.TYPE_NAME}" (каз)`,
                    `Неизвестный подтип "${subtype}" для типа "${TimePart.TYPE_NAME}"`,
                    start,
                    end,
                );
        }
    });
}


class TimeAmPmPart extends TimePart {
    static readonly SUBTYPE_NAME: string = 'ampm';
    static readonly TYPE_ALIAS = `${TimeAmPmPart.TYPE_NAME}[${TimeAmPmPart.SUBTYPE_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return TimeAmPmPart.TYPE_ALIAS;
    }

    protected format(value: Date): string {
        const hour = value.getHours();
        if (hour < 12) {
            // AM
            return service.am();
        } else {
            // PM
            return service.pm();
        }
    }
}


abstract class TimeHourPart extends TimePart {
    static readonly SUBTYPE_NAME: string = 'hour';
    static readonly TYPE_ALIAS = `${TimeHourPart.TYPE_NAME}[${TimeHourPart.SUBTYPE_NAME}]`;

    protected abstract formatHour(value: number): string;

    protected prepare(value: number): number {
        if (service.format24h()) {
            return value
        } else {
            let result = value;

            while (result >= 12) {
                result -= 12;
            }

            return result;
        }
    }

    protected format(value: Date): string {
        const hour = value.getHours();
        const preparedHour = this.prepare(hour);
        return this.formatHour(preparedHour);
    }
}

class TimeHourNumericPart extends TimeHourPart {
    static readonly FORMAT_NAME: string = 'numeric';
    static readonly TYPE_ALIAS = `${TimeHourNumericPart.TYPE_NAME}[${TimeHourNumericPart.SUBTYPE_NAME};${TimeHourNumericPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return TimeHourNumericPart.TYPE_ALIAS;
    }

    protected formatHour(value: number): string {
        return String(value);
    }
}

class TimeHour2DigitPart extends TimeHourPart {
    static readonly FORMAT_NAME: string = '2-digit';
    static readonly TYPE_ALIAS = `${TimeHour2DigitPart.TYPE_NAME}[${TimeHour2DigitPart.SUBTYPE_NAME};${TimeHour2DigitPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return TimeHour2DigitPart.TYPE_ALIAS;
    }

    protected formatHour(value: number): string {
        return twoDigit(value);
    }
}

const createTimeHourPart = (key: string | undefined, start: number, end: number, argumentTemplateParts: Array<string>, argumentIndex: number): TimeHourPart => {
    const format = temporalFormat(argumentTemplateParts, TimeHourNumericPart.FORMAT_NAME);
    switch (format) {
        case TimeHourNumericPart.FORMAT_NAME:
            return new TimeHourNumericPart(argumentIndex);
        case TimeHour2DigitPart.FORMAT_NAME:
            return new TimeHour2DigitPart(argumentIndex);
        default:
            throw new ValidationError(
                `Unknown format "${format}" for type "${TimeHourPart.TYPE_ALIAS}"`,
                `Неизвестный формат "${format}" для типа "${TimeHourPart.TYPE_ALIAS}" (каз)`,
                `Неизвестный формат "${format}" для типа "${TimeHourPart.TYPE_ALIAS}"`,
                start,
                end,
            );
    }
}


abstract class TimeMinutePart extends TimePart {
    static readonly SUBTYPE_NAME: string = 'minute';
    static readonly TYPE_ALIAS = `${TimeMinutePart.TYPE_NAME}[${TimeMinutePart.SUBTYPE_NAME}]`;

    protected abstract formatMinute(value: number): string;

    protected format(value: Date): string {
        const minute = value.getMinutes();
        return this.formatMinute(minute);
    }
}

class TimeMinuteNumericPart extends TimeMinutePart {
    static readonly FORMAT_NAME: string = 'numeric';
    static readonly TYPE_ALIAS = `${TimeMinuteNumericPart.TYPE_NAME}[${TimeMinuteNumericPart.SUBTYPE_NAME};${TimeMinuteNumericPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return TimeMinuteNumericPart.TYPE_ALIAS;
    }

    protected formatMinute(value: number): string {
        return String(value);
    }
}

class TimeMinute2DigitPart extends TimeMinutePart {
    static readonly FORMAT_NAME: string = '2-digit';
    static readonly TYPE_ALIAS = `${TimeMinute2DigitPart.TYPE_NAME}[${TimeMinute2DigitPart.SUBTYPE_NAME};${TimeMinute2DigitPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return TimeMinute2DigitPart.TYPE_ALIAS;
    }

    protected formatMinute(value: number): string {
        return twoDigit(value);
    }
}

const createTimeMinutePart = (key: string | undefined, start: number, end: number, argumentTemplateParts: Array<string>, argumentIndex: number): TimeMinutePart => {
    const format = temporalFormat(argumentTemplateParts, TimeMinute2DigitPart.FORMAT_NAME);
    switch (format) {
        case TimeMinuteNumericPart.FORMAT_NAME:
            return new TimeMinuteNumericPart(argumentIndex);
        case TimeMinute2DigitPart.FORMAT_NAME:
            return new TimeMinute2DigitPart(argumentIndex);
        default:
            throw new ValidationError(
                `Unknown format "${format}" for type "${TimeMinutePart.TYPE_ALIAS}"`,
                `Неизвестный формат "${format}" для типа "${TimeMinutePart.TYPE_ALIAS}" (каз)`,
                `Неизвестный формат "${format}" для типа "${TimeMinutePart.TYPE_ALIAS}"`,
                start,
                end,
            );
    }
}


abstract class TimeSecondPart extends TimePart {
    static readonly SUBTYPE_NAME: string = 'second';
    static readonly TYPE_ALIAS = `${TimeSecondPart.TYPE_NAME}[${TimeSecondPart.SUBTYPE_NAME}]`;

    protected abstract formatSecond(value: number): string;

    protected format(value: Date): string {
        const second = value.getSeconds();
        return this.formatSecond(second);
    }
}

class TimeSecondNumericPart extends TimeSecondPart {
    static readonly FORMAT_NAME: string = 'numeric';
    static readonly TYPE_ALIAS = `${TimeSecondNumericPart.TYPE_NAME}[${TimeSecondNumericPart.SUBTYPE_NAME};${TimeSecondNumericPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return TimeSecondNumericPart.TYPE_ALIAS;
    }

    protected formatSecond(value: number): string {
        return String(value);
    }
}

class TimeSecond2DigitPart extends TimeSecondPart {
    static readonly FORMAT_NAME: string = '2-digit';
    static readonly TYPE_ALIAS = `${TimeSecond2DigitPart.TYPE_NAME}[${TimeSecond2DigitPart.SUBTYPE_NAME};${TimeSecond2DigitPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return TimeSecond2DigitPart.TYPE_ALIAS;
    }

    protected formatSecond(value: number): string {
        return twoDigit(value);
    }
}

const createTimeSecondPart = (key: string | undefined, start: number, end: number, argumentTemplateParts: Array<string>, argumentIndex: number): TimeSecondPart => {
    const format = temporalFormat(argumentTemplateParts, TimeSecond2DigitPart.FORMAT_NAME);
    switch (format) {
        case TimeSecondNumericPart.FORMAT_NAME:
            return new TimeSecondNumericPart(argumentIndex);
        case TimeSecond2DigitPart.FORMAT_NAME:
            return new TimeSecond2DigitPart(argumentIndex);
        default:
            throw new ValidationError(
                `Unknown format "${format}" for type "${TimeSecondPart.TYPE_ALIAS}"`,
                `Неизвестный формат "${format}" для типа "${TimeSecondPart.TYPE_ALIAS}" (каз)`,
                `Неизвестный формат "${format}" для типа "${TimeSecondPart.TYPE_ALIAS}"`,
                start,
                end,
            );
    }
}


class TimeNoSecondsPart extends TimePart {
    static readonly SUBTYPE_NAME: string = 'no-seconds';
    static readonly TYPE_ALIAS = `${TimeNoSecondsPart.TYPE_NAME}[${TimeNoSecondsPart.SUBTYPE_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return TimeNoSecondsPart.TYPE_ALIAS;
    }

    protected format(value: Date): string {
        const template = service.timeTemplateNoSeconds();
        return service.translateByTemplate(template, value);
    }
}


class TimeWithSecondsPart extends TimePart {
    static readonly SUBTYPE_NAME: string = 'with-seconds';
    static readonly TYPE_ALIAS = `${TimeWithSecondsPart.TYPE_NAME}[${TimeWithSecondsPart.SUBTYPE_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return TimeWithSecondsPart.TYPE_ALIAS;
    }

    protected format(value: Date): string {
        const template = service.timeTemplateWithSeconds();
        return service.translateByTemplate(template, value);
    }
}
// endregion


// region Parts - arguments - temporal - date-time
abstract class DateTimePart extends TemporalPart {
    static readonly TYPE_NAME: string = 'date-time';

    protected abstract dateTemplate(): string;
    protected abstract timeTemplate(): string;

    protected dateTimeTemplate(): string {
        return service.dateTimeTemplate();
    }

    protected format(value: Date): string {
        const dateTemplate = this.dateTemplate();
        const timeTemplate = this.timeTemplate();
        const dateTimeTemplate = this.dateTimeTemplate();

        const dateText = service.translateByTemplate(dateTemplate, value);
        const timeText = service.translateByTemplate(timeTemplate, value);
        return service.translateByTemplate(dateTimeTemplate, dateText, timeText);
    }
}

const createDateTimeArgumentPart = (key: string | undefined, start: number, end: number, argumentTemplateParts: Array<string>, argumentIndex: number): DateTimePart => {
    return checkingTemporalArgument(start, end, key, argumentTemplateParts, DateTimePart.TYPE_NAME, DateTimeNoSecondsPart.SUBTYPE_NAME, (subtype) => {
        switch (subtype) {
            case DateTimeLongPart.SUBTYPE_NAME:
                return createDateTimeLongPart(key, start, end, argumentTemplateParts, argumentIndex);
            case DateTimeNumericPart.SUBTYPE_NAME:
                return createDateTimeNumericPart(key, start, end, argumentTemplateParts, argumentIndex);
            case DateTimeShortPart.SUBTYPE_NAME:
                return createDateTimeShortPart(key, start, end, argumentTemplateParts, argumentIndex);
            case DateTimeNoSecondsPart.SUBTYPE_NAME:
                return new DateTimeNoSecondsPart(argumentIndex);
            case DateTimeWithSecondsPart.SUBTYPE_NAME:
                return new DateTimeWithSecondsPart(argumentIndex);
            default:
                throw new ValidationError(
                    `Unknown subtype "${subtype}" for type "${DateTimePart.TYPE_NAME}"`,
                    `Неизвестный подтип "${subtype}" для типа "${DateTimePart.TYPE_NAME}" (каз)`,
                    `Неизвестный подтип "${subtype}" для типа "${DateTimePart.TYPE_NAME}"`,
                    start,
                    end,
                );
        }
    });
}


abstract class DateTimeLongPart extends DateTimePart {
    static readonly SUBTYPE_NAME: string = 'long';
    static readonly TYPE_ALIAS = `${DateTimeLongPart.TYPE_NAME}[${DateTimeLongPart.SUBTYPE_NAME}]`;

    protected dateTemplate(): string {
        return service.dateTemplateLong();
    }
}

class DateTimeLongNoSecondsPart extends DateTimeLongPart {
    static readonly FORMAT_NAME: string = 'no-seconds';
    static readonly TYPE_ALIAS = `${DateTimeLongNoSecondsPart.TYPE_NAME}[${DateTimeLongNoSecondsPart.SUBTYPE_NAME};${DateTimeLongNoSecondsPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateTimeLongNoSecondsPart.TYPE_ALIAS;
    }

    protected timeTemplate(): string {
        return service.timeTemplateNoSeconds();
    }
}

class DateTimeLongWithSecondsPart extends DateTimeLongPart {
    static readonly FORMAT_NAME: string = 'with-seconds';
    static readonly TYPE_ALIAS = `${DateTimeLongWithSecondsPart.TYPE_NAME}[${DateTimeLongWithSecondsPart.SUBTYPE_NAME};${DateTimeLongWithSecondsPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateTimeLongWithSecondsPart.TYPE_ALIAS;
    }

    protected timeTemplate(): string {
        return service.timeTemplateWithSeconds();
    }
}

const createDateTimeLongPart = (key: string | undefined, start: number, end: number, argumentTemplateParts: Array<string>, argumentIndex: number): DateTimeLongPart => {
    const format = temporalFormat(argumentTemplateParts, DateTimeLongNoSecondsPart.FORMAT_NAME);
    switch (format) {
        case DateTimeLongNoSecondsPart.FORMAT_NAME:
            return new DateTimeLongNoSecondsPart(argumentIndex);
        case DateTimeLongWithSecondsPart.FORMAT_NAME:
            return new DateTimeLongWithSecondsPart(argumentIndex);
        default:
            throw new ValidationError(
                `Unknown format "${format}" for type "${DateTimeLongPart.TYPE_ALIAS}"`,
                `Неизвестный формат "${format}" для типа "${DateTimeLongPart.TYPE_ALIAS}" (каз)`,
                `Неизвестный формат "${format}" для типа "${DateTimeLongPart.TYPE_ALIAS}"`,
                start,
                end,
            );
    }
}


abstract class DateTimeNumericPart extends DateTimePart {
    static readonly SUBTYPE_NAME: string = 'numeric';
    static readonly TYPE_ALIAS = `${DateTimeNumericPart.TYPE_NAME}[${DateTimeNumericPart.SUBTYPE_NAME}]`;

    protected dateTemplate(): string {
        return service.dateTemplateNumeric();
    }
}

class DateTimeNumericNoSecondsPart extends DateTimeNumericPart {
    static readonly FORMAT_NAME: string = 'no-seconds';
    static readonly TYPE_ALIAS = `${DateTimeNumericNoSecondsPart.TYPE_NAME}[${DateTimeNumericNoSecondsPart.SUBTYPE_NAME};${DateTimeNumericNoSecondsPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateTimeNumericNoSecondsPart.TYPE_ALIAS;
    }

    protected timeTemplate(): string {
        return service.timeTemplateNoSeconds();
    }
}

class DateTimeNumericWithSecondsPart extends DateTimeNumericPart {
    static readonly FORMAT_NAME: string = 'with-seconds';
    static readonly TYPE_ALIAS = `${DateTimeNumericWithSecondsPart.TYPE_NAME}[${DateTimeNumericWithSecondsPart.SUBTYPE_NAME};${DateTimeNumericWithSecondsPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateTimeNumericWithSecondsPart.TYPE_ALIAS;
    }

    protected timeTemplate(): string {
        return service.timeTemplateWithSeconds();
    }
}

const createDateTimeNumericPart = (key: string | undefined, start: number, end: number, argumentTemplateParts: Array<string>, argumentIndex: number): DateTimeNumericPart => {
    const format = temporalFormat(argumentTemplateParts, DateTimeNumericNoSecondsPart.FORMAT_NAME);
    switch (format) {
        case DateTimeNumericNoSecondsPart.FORMAT_NAME:
            return new DateTimeNumericNoSecondsPart(argumentIndex);
        case DateTimeNumericWithSecondsPart.FORMAT_NAME:
            return new DateTimeNumericWithSecondsPart(argumentIndex);
        default:
            throw new ValidationError(
                `Unknown format "${format}" for type "${DateTimeNumericPart.TYPE_ALIAS}"`,
                `Неизвестный формат "${format}" для типа "${DateTimeNumericPart.TYPE_ALIAS}" (каз)`,
                `Неизвестный формат "${format}" для типа "${DateTimeNumericPart.TYPE_ALIAS}"`,
                start,
                end,
            );
    }
}


abstract class DateTimeShortPart extends DateTimePart {
    static readonly SUBTYPE_NAME: string = 'short';
    static readonly TYPE_ALIAS = `${DateTimeShortPart.TYPE_NAME}[${DateTimeShortPart.SUBTYPE_NAME}]`;

    protected dateTemplate(): string {
        return service.dateTemplateShort();
    }
}

class DateTimeShortNoSecondsPart extends DateTimeShortPart {
    static readonly FORMAT_NAME: string = 'no-seconds';
    static readonly TYPE_ALIAS = `${DateTimeShortNoSecondsPart.TYPE_NAME}[${DateTimeShortNoSecondsPart.SUBTYPE_NAME};${DateTimeShortNoSecondsPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateTimeShortNoSecondsPart.TYPE_ALIAS;
    }

    protected timeTemplate(): string {
        return service.timeTemplateNoSeconds();
    }
}

class DateTimeShortWithSecondsPart extends DateTimeShortPart {
    static readonly FORMAT_NAME: string = 'with-seconds';
    static readonly TYPE_ALIAS = `${DateTimeShortWithSecondsPart.TYPE_NAME}[${DateTimeShortWithSecondsPart.SUBTYPE_NAME};${DateTimeShortWithSecondsPart.FORMAT_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateTimeShortWithSecondsPart.TYPE_ALIAS;
    }

    protected timeTemplate(): string {
        return service.timeTemplateWithSeconds();
    }
}

const createDateTimeShortPart = (key: string | undefined, start: number, end: number, argumentTemplateParts: Array<string>, argumentIndex: number): DateTimeShortPart => {
    const format = temporalFormat(argumentTemplateParts, DateTimeShortNoSecondsPart.FORMAT_NAME);
    switch (format) {
        case DateTimeShortNoSecondsPart.FORMAT_NAME:
            return new DateTimeShortNoSecondsPart(argumentIndex);
        case DateTimeShortWithSecondsPart.FORMAT_NAME:
            return new DateTimeShortWithSecondsPart(argumentIndex);
        default:
            throw new ValidationError(
                `Unknown format "${format}" for type "${DateTimeShortPart.TYPE_ALIAS}"`,
                `Неизвестный формат "${format}" для типа "${DateTimeShortPart.TYPE_ALIAS}" (каз)`,
                `Неизвестный формат "${format}" для типа "${DateTimeShortPart.TYPE_ALIAS}"`,
                start,
                end,
            );
    }
}


class DateTimeNoSecondsPart extends DateTimePart {
    static readonly SUBTYPE_NAME: string = 'no-seconds';
    static readonly TYPE_ALIAS = `${DateTimeNoSecondsPart.TYPE_NAME}[${DateTimeNoSecondsPart.SUBTYPE_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateTimeNoSecondsPart.TYPE_ALIAS;
    }

    protected dateTemplate(): string {
        return service.dateTemplateShort();
    }

    protected timeTemplate(): string {
        return service.timeTemplateNoSeconds();
    }
}


class DateTimeWithSecondsPart extends DateTimePart {
    static readonly SUBTYPE_NAME: string = 'with-seconds';
    static readonly TYPE_ALIAS = `${DateTimeWithSecondsPart.TYPE_NAME}[${DateTimeWithSecondsPart.SUBTYPE_NAME}]`;

    argumentIndex: number;

    constructor(argumentIndex: number) {
        super();
        this.argumentIndex = argumentIndex;
    }

    get typeAlias(): string {
        return DateTimeWithSecondsPart.TYPE_ALIAS;
    }

    protected dateTemplate(): string {
        return service.dateTemplateShort();
    }

    protected timeTemplate(): string {
        return service.timeTemplateWithSeconds();
    }
}
// endregion


// region Parts - arguments - number
enum RoundType {
    FLOOR = 'FLOOR',
    CEIL = 'CEIL',
    DEFAULT = 'DEFAULT'
}

class NumberArgumentPart extends ArgumentPart {
    static readonly TYPE_NAME: string = 'number';
    static readonly SETTING_NAME__FRAC_MIN: string = 'frac-min';
    static readonly SETTING_PREFIX__FRAC_MIN = `${NumberArgumentPart.SETTING_NAME__FRAC_MIN}=`;
    static readonly SETTING_NAME__FRAC_MAX: string = 'frac-max';
    static readonly SETTING_PREFIX__FRAC_MAX = `${NumberArgumentPart.SETTING_NAME__FRAC_MAX}=`
    static readonly SETTING_VALUE__FRAC_MAX__MAX = `${NumberArgumentPart.SETTING_PREFIX__FRAC_MAX}max`;
    static readonly SETTING_NAME__ROUND: string = 'round';
    static readonly SETTING_PREFIX__ROUND = `${NumberArgumentPart.SETTING_NAME__ROUND}=`;

    static parseInt(start: number, end: number, part: string, prefix: string): number {
        try {
            const stringValue = part.substring(prefix.length);
            const value = parseInteger(start, end, stringValue);
            if (value < 0) {
                // noinspection ExceptionCaughtLocallyJS
                throw new ValidationError(
                    `Negative value - ${value}`,
                    `Отрицательное значение - ${value} (каз)`,
                    `Отрицательное значение - ${value}`,
                    start,
                    end,
                );
            }
            return value
        } catch (e) {
            if (e instanceof ValidationError) {
                throw new ValidationError(
                    `Invalid setting "${part}" for type "${this.TYPE_NAME}" - ${e.en}`,
                    `Некорректная настройка "${part}" для типа "${this.TYPE_NAME}" - ${e.kk} (каз)`,
                    `Некорректная настройка "${part}" для типа "${this.TYPE_NAME}" - ${e.ru}`,
                    start,
                    end,
                );
            } else {
                throw new ValidationError(
                    `Invalid setting "${part}" for type "${this.TYPE_NAME}" - ${e}`,
                    `Некорректная настройка "${part}" для типа "${this.TYPE_NAME}" - ${e} (каз)`,
                    `Некорректная настройка "${part}" для типа "${this.TYPE_NAME}" - ${e}`,
                    start,
                    end,
                );
            }
        }
    }

    static doubleSetting(start: number, end: number, settingName: String, previousValue: String) {
        throw new ValidationError(
            `Double "${settingName}" setting, previous setting - "${previousValue}"`,
            `Дубль настройки "${settingName}", предыдущая настройка - "${previousValue}" (каз)`,
            `Дубль настройки "${settingName}", предыдущая настройка - "${previousValue}"`,
            start,
            end,
        );
    }

    static digitsToArray(value: string, start: number = 0, end: number = value.length): Array<number> {
        if (start < 0) {
            throw new Error(`Start index is negative (${start})`);
        }
        if (start >= value.length) {
            throw new Error(`Start index (${start}) is greater than max index (${value.length - 1})`);
        }
        if (end < start) {
            throw new Error(`End index (${end}) is less than start index (${start})`);
        }
        if (end > value.length) {
            throw new Error(`Start index (${start}) is greater than length (${value.length})`);
        }

        if (end == start) {
            return [];
        }

        const result: Array<number> = [];

        for (let index = start; index < end; index++) {
            const charDigit = value[index];
            const intDigit = parseInteger(start, end, charDigit);
            result.push(intDigit);
        }

        return result;
    }

    static normalizeDigitList(digits: Array<number>, addOne: Boolean, canChangeSize: Boolean): Boolean {
        let currentAddOne = addOne

        for (let index = (digits.length - 1); index >= 0; index--) {
            if (currentAddOne) {
                digits[index]++;
            }

            if (digits[index] > 9) {
                digits[index] -= 10;
                currentAddOne = true;
            } else {
                currentAddOne = false;
            }
        }

        if (currentAddOne && canChangeSize) {
            digits.unshift(1);
            currentAddOne = false;
        }

        return currentAddOne;
    }


    argumentIndex: number;
    readonly fracMinCount: number;
    readonly fracMaxCount: number;
    readonly roundType: RoundType;

    constructor(argumentIndex: number, fracMinCount: number, fracMaxCount: number, roundType: RoundType) {
        super();
        this.argumentIndex = argumentIndex;
        this.fracMinCount = fracMinCount;
        this.fracMaxCount = fracMaxCount;
        this.roundType = roundType;
    }

    get argType(): I18nDb.CompiledArgType {
        return 'NUMBER';
    }

    get typeAlias(): string {
        return NumberArgumentPart.TYPE_NAME;
    }

    argumentToString(argumentValue: unknown): string {
        let stringValue: string;
        if (typeof argumentValue === 'number') {
            if (Number.isFinite(argumentValue)) {
                stringValue = String(argumentValue);
            } else {
                throw new Error(`Cannot process infinite number - ${argumentValue}`);
            }
        } else {
            throw new Error(`Cannot process non-number value - ${argumentValue} (${typeof argumentValue})`);
        }

        const isNegative = (stringValue[0] == '-');
        let intPart: Array<number>;
        let fracPart: Array<number>;
        (() => {
            const dotIndex = stringValue.indexOf('.');

            if (dotIndex >= 0) {
                if (isNegative) {
                    intPart = NumberArgumentPart.digitsToArray(stringValue, 1, dotIndex);
                } else {
                    intPart = NumberArgumentPart.digitsToArray(stringValue, 0, dotIndex);
                }

                fracPart = NumberArgumentPart.digitsToArray(stringValue, dotIndex + 1);
            } else {
                if (isNegative) {
                    intPart = NumberArgumentPart.digitsToArray(stringValue, 1);
                } else {
                    intPart = NumberArgumentPart.digitsToArray(stringValue);
                }

                fracPart = [];
            }
        })();

        while ((fracPart.length > 0) && (fracPart[fracPart.length - 1] === 0)) {
            fracPart.splice(fracPart.length - 1, 1);
        }

        const normalize = () => {
            const addOne = NumberArgumentPart.normalizeDigitList(fracPart, false, false);
            NumberArgumentPart.normalizeDigitList(intPart, addOne, true);
        };

        // Apply min fraction digit count
        while (fracPart.length < this.fracMinCount) {
            fracPart.push(0);
        }

        // Apply max fraction digit count
        if (fracPart.length > this.fracMaxCount) {
            const digit = fracPart[this.fracMaxCount];

            let doRound: boolean;
            switch (this.roundType) {
                case RoundType.FLOOR:
                    doRound = false;
                    break;
                case RoundType.CEIL:
                    doRound = (digit > 0);
                    break;
                default:
                    doRound = (digit > 4);
                    break;
            }

            while (fracPart.length > this.fracMaxCount) {
                fracPart.splice(this.fracMaxCount, 1);
            }

            if (doRound) {
                fracPart[this.fracMaxCount - 1]++;
                normalize();
            }
        }

        const numberSeparatorFraction = service.numberSeparatorFraction();
        const numberSeparatorThousands = service.numberSeparatorThousands();

        let thousandDigitPassed = (intPart.length % 3);
        if (thousandDigitPassed != 0) {
            thousandDigitPassed = (3 - thousandDigitPassed);
        }

        const result: Array<string> = [];

        (() => {
            if (isNegative) result.push('-');

            intPart.forEach((digit) => {
                if (thousandDigitPassed == 3) {
                    result.push(numberSeparatorThousands);
                    thousandDigitPassed = 0;
                }

                result.push(String(digit));
                thousandDigitPassed++;
            });

            if (fracPart.length > 0) {
                result.push(numberSeparatorFraction);
                fracPart.forEach((digit) => {
                    result.push(String(digit));
                });
            }
        })();

        return result.join('');
    }
}

const createNumberArgumentPart = (start: number, end: number, argumentTemplateParts: Array<string>, argumentIndex: number): NumberArgumentPart => {
    let fracMinPart: string | undefined;
    let mutableFracMinCount: number | undefined;
    let fracMaxPart: string | undefined;
    let mutableFracMaxCount: number | undefined;
    let roundPart: string | undefined;
    let mutableRoundType: RoundType | undefined;

    argumentTemplateParts.forEach((part, index) => {
        if ((index < 2) || filterTypesNames().has(part)) {
            return;
        }

        const preparedPart = part.trim().toLowerCase();

        if (preparedPart.startsWith(NumberArgumentPart.SETTING_PREFIX__FRAC_MIN)) {
            if (fracMinPart === undefined) {
                fracMinPart = part
                mutableFracMinCount = NumberArgumentPart.parseInt(start, end, preparedPart, NumberArgumentPart.SETTING_PREFIX__FRAC_MIN);
            } else {
                NumberArgumentPart.doubleSetting(start, end, NumberArgumentPart.SETTING_NAME__FRAC_MIN, fracMinPart!!);
            }
        } else if (preparedPart.startsWith(NumberArgumentPart.SETTING_PREFIX__FRAC_MAX)) {
            if (fracMaxPart === undefined) {
                fracMaxPart = part;
                if (preparedPart === NumberArgumentPart.SETTING_VALUE__FRAC_MAX__MAX) {
                    mutableFracMaxCount = Number.MAX_SAFE_INTEGER;
                } else {
                    mutableFracMaxCount = NumberArgumentPart.parseInt(start, end, preparedPart, NumberArgumentPart.SETTING_PREFIX__FRAC_MAX);
                }
            } else {
                NumberArgumentPart.doubleSetting(start, end, NumberArgumentPart.SETTING_NAME__FRAC_MAX, fracMaxPart!!);
            }
        } else if (preparedPart.startsWith(NumberArgumentPart.SETTING_PREFIX__ROUND)) {
            if (roundPart === undefined) {
                roundPart = part;

                const valueString = preparedPart
                    .substring(NumberArgumentPart.SETTING_PREFIX__ROUND.length)
                    .trim()
                    .toUpperCase();

                switch (valueString) {
                    case RoundType.CEIL:
                    case RoundType.FLOOR:
                    case RoundType.DEFAULT:
                        mutableRoundType = valueString;
                        break;
                    default:
                        throw new ValidationError(
                            `Invalid setting "${part}" for type "${NumberArgumentPart.TYPE_NAME}"`,
                            `Некорректная настройка "${part}" для типа "${NumberArgumentPart.TYPE_NAME}" (каз)`,
                            `Некорректная настройка "${part}" для типа "${NumberArgumentPart.TYPE_NAME}"`,
                            start,
                            end,
                        );
                }
            } else {
                NumberArgumentPart.doubleSetting(start, end, NumberArgumentPart.SETTING_NAME__ROUND, roundPart!!);
            }
        } else {
            throw new ValidationError(
                `Unknown setting "${part}" for type "${NumberArgumentPart.TYPE_NAME}"`,
                `Неизвестная настройка "${part}" для типа "${NumberArgumentPart.TYPE_NAME}" (каз)`,
                `Неизвестная настройка "${part}" для типа "${NumberArgumentPart.TYPE_NAME}"`,
                start,
                end,
            );
        }
    });

    const fracMinCount = mutableFracMinCount ?? 0;
    const fracMaxCount = ((): number => {
        if (mutableFracMaxCount === undefined) {
            return Math.max(3, fracMinCount);
        } else if (mutableFracMaxCount < fracMinCount) {
            throw new ValidationError(
                `Setting "${NumberArgumentPart.SETTING_NAME__FRAC_MIN}" (${fracMinCount}) is greater than setting "${NumberArgumentPart.SETTING_NAME__FRAC_MAX}" (${mutableFracMaxCount})`,
                `Настройка "${NumberArgumentPart.SETTING_NAME__FRAC_MIN}" (${fracMinCount}) больше, чем настройка "${NumberArgumentPart.SETTING_NAME__FRAC_MAX}" (${mutableFracMaxCount}) (каз)`,
                `Настройка "${NumberArgumentPart.SETTING_NAME__FRAC_MIN}" (${fracMinCount}) больше, чем настройка "${NumberArgumentPart.SETTING_NAME__FRAC_MAX}" (${mutableFracMaxCount})`,
                start,
                end,
            );
        } else {
            return mutableFracMaxCount;
        }
    })();

    const roundType: RoundType = (mutableRoundType === undefined ? RoundType.DEFAULT : mutableRoundType);

    return new NumberArgumentPart(argumentIndex, fracMinCount, fracMaxCount, roundType);
}
// endregion


// region Parts - filters
abstract class Filter {
    abstract filter(text: string, ...args: Array<unknown>): string;
}

class FilterPart extends Part {
    readonly wrappedPart: Part;
    readonly filter: Filter;

    constructor(wrappedPart: Part, filter: Filter) {
        super();
        this.wrappedPart = wrappedPart;
        this.filter = filter;
    }

    toString(...args: Array<unknown>): string {
        const text = this.wrappedPart.toString(...args);
        return this.filter.filter(text, ...args);
    }

    get argType(): I18nDb.CompiledArgType | undefined {
        return this.wrappedPart.argType;
    }
}

class TrimFilter extends Filter {
    static readonly TYPE_NAME: string = 'trim';

    private static instance: TrimFilter | undefined;
    static get INSTANCE(): TrimFilter {
        if (TrimFilter.instance === undefined) {
            const result = new TrimFilter();
            TrimFilter.instance = result;
            return result;
        } else {
            return TrimFilter.instance;
        }
    }

    filter(text: string, ...args: Array<unknown>): string {
        return text.trim();
    }
}

class CapitalizeFilter extends Filter {
    static readonly TYPE_NAME: string = 'capitalize';

    private static instance: CapitalizeFilter | undefined;
    static get INSTANCE(): CapitalizeFilter {
        if (CapitalizeFilter.instance === undefined) {
            const result = new CapitalizeFilter();
            CapitalizeFilter.instance = result;
            return result;
        } else {
            return CapitalizeFilter.instance;
        }
    }

    filter(text: string, ...args: Array<unknown>): string {
        switch (text.length) {
            case 0:
                return text;
            case 1:
                return text.toUpperCase();
            default:
                return text.substring(0, 1).toUpperCase() + text.substring(1);
        }
    }
}

class UpperFilter extends Filter {
    static readonly TYPE_NAME: string = 'upper';

    private static instance: UpperFilter | undefined;
    static get INSTANCE(): UpperFilter {
        if (UpperFilter.instance === undefined) {
            const result = new UpperFilter();
            UpperFilter.instance = result;
            return result;
        } else {
            return UpperFilter.instance;
        }
    }

    filter(text: string, ...args: Array<unknown>): string {
        return text.toUpperCase();
    }
}

class LowerFilter extends Filter {
    static readonly TYPE_NAME: string = 'lower';

    private static instance: LowerFilter | undefined;
    static get INSTANCE(): LowerFilter {
        if (LowerFilter.instance === undefined) {
            const result = new LowerFilter();
            LowerFilter.instance = result;
            return result;
        } else {
            return LowerFilter.instance;
        }
    }

    filter(text: string, ...args: Array<unknown>): string {
        return text.toLowerCase();
    }
}

const addFilterParts = (start: number, end: number, argumentTemplateParts: Array<string>, target: Array<Part>) => {
    argumentTemplateParts.forEach((argumentTemplatePart) => {
        const filterKey = argumentTemplatePart.trim().toLowerCase();

        let filter: Filter | undefined;
        switch (filterKey) {
            case TrimFilter.TYPE_NAME:
                filter = TrimFilter.INSTANCE;
                break;
            case CapitalizeFilter.TYPE_NAME:
                filter = CapitalizeFilter.INSTANCE;
                break;
            case UpperFilter.TYPE_NAME:
                filter = UpperFilter.INSTANCE;
                break;
            case LowerFilter.TYPE_NAME:
                filter = LowerFilter.INSTANCE;
                break;
            default:
                filter = undefined;
                break;
        }

        if (filter !== undefined) {
            if (target.length === 0) {
                throw new ValidationError(
                    `Cannot apply filter "${filterKey}" - no other argument parts added before it`,
                    `Невозможно применить фильтр "${filterKey}" - перед ним нет других частей аргумента (каз)`,
                    `Невозможно применить фильтр "${filterKey}" - перед ним нет других частей аргумента`,
                    start,
                    end,
                );
            }

            const wrappedPart = target[target.length - 1];
            const part = new FilterPart(wrappedPart, filter);

            target.splice(target.length - 1, 1, part);
        }
    });
};
// endregion


export default class I18nDbCompiledTemplate {
    static create(key: string | undefined, template: string, fallback: string): I18nDbCompiledTemplate {
        let parts: Array<Part>;
        let validationError: ValidationError | undefined;

        try {
            parts = parseParts(key, template);
            validationError = undefined;
        } catch (e) {
            if (e instanceof ValidationError) {
                validationError = e;
            } else {
                validationError = new ValidationError(
                    `Unknown error - "${e}"`,
                    `Неизвестная ошибка - "${e}" (каз)`,
                    `Неизвестная ошибка - "${e}"`,
                    0,
                    template.length,
                );
            }
            console.error(`Cannot compile template "${template}"`, validationError);

            parts = [new StaticPart(fallback)];
        }

        return new I18nDbCompiledTemplate(parts, validationError, fallback);
    }


    private readonly parts: Array<Part>;
    private readonly validationError?: ValidationError;
    private readonly fallback: string;


    constructor(parts: Array<Part>, validationError: ValidationError | undefined, fallback: string) {
        this.parts = parts;
        this.validationError = validationError;
        this.fallback = fallback;
    }


    get validationErrorInfo(): I18nDb.Validation.ErrorInfo | undefined {
        return this.validationError;
    }

    get argTypes(): Array<I18nDb.CompiledArgType> {
        let maxArgIndex = -1;
        const argTypeMapByIndex = new Map<number, I18nDb.CompiledArgType>();

        this.parts.forEach((part) => {
            let currentPart: Part = part;
            while (currentPart instanceof FilterPart) {
                currentPart = currentPart.wrappedPart;
            }

            if (currentPart instanceof ArgumentPart) {
                const argType = part.argType;
                if (argType === undefined) {
                    return;
                }

                const argumentIndex = currentPart.argumentIndex;
                if (maxArgIndex < argumentIndex) {
                    maxArgIndex = argumentIndex;
                }

                switch (argType) {
                    case 'ANY':
                        if (!argTypeMapByIndex.has(argumentIndex)) {
                            argTypeMapByIndex.set(argumentIndex, argType);
                        }
                        break;
                    case 'NUMBER':
                    case 'DATE':
                        argTypeMapByIndex.set(argumentIndex, argType);
                        break;
                }
            }
        });

        if (maxArgIndex < 0) {
            return [];
        }

        const result: Array<I18nDb.CompiledArgType> = [];
        for (let argIndex = 0; argIndex <= maxArgIndex; argIndex++) {
            const argType = argTypeMapByIndex.get(argIndex);
            result.push(argType ?? 'ANY');
        }
        return result;
    }

    toString(...args: Array<unknown>): string {
        try {
            const result: Array<string> = [];

            this.parts.forEach((part) => {
                const partText = part.toString(...args);
                result.push(partText);
            });

            return result.join('');
        } catch (e) {
            console.warn('Cannot translate', e);
            return this.fallback;
        }
    }
}