import {Vue, Component} from 'vue-property-decorator';
import store from "@/services/store";
import i18nService from "@/services/i18n";


export interface NBRK {
    NBRKUSD: string;
    USDNBRK: string;
    NBRKEUR: string;
    EURNBRK: string;
    NBRKRUB: string;
    RUBNBRK: string;
    USD_CHANGE: string;
    EUR_CHANGE: string;
    RUB_CHANGE: string;
}

export interface KASE {
    KASEUSD: number;
    USDKASE: string;
    KASEEUR: number;
    EURKASE: string;
    KASERUB: number;
    RUBKASE: string;
}

export interface ALA {
    USDSELL: string;
    USDBUY: string;
    EUROSELL: string;
    EUROBUY: string;
    RUBSELL: string;
    RUBBUY: string;
}

export interface AST {
    USDSELL: string;
    USDBUY: string;
    EUROSELL: string;
    EUROBUY: string;
    RUBSELL: string;
    RUBBUY: string;
}

export interface ICurrencyData {
    USD: string;
    EUR: string;
    RUB: string;
    // NBRK: NBRK;
    // KASE: KASE;
    // ALA: ALA;
    // AST: AST;
    date?: string;
    datetime?: Date;
}

export interface IWeather {
    alt_name?: string;
    name?: string;
    degree: string;
    weather_icon?: string;
    chance_drops?: string;
    dist?: string;
    dt?: Date;
    dtu?: Date;
    max_wind_speed?: string;
    wind_direction?: string;
    datetime?: Date;
    station?: string;
}

@Component
export default class CBoardRight extends Vue {

    public get locales(): string[] {
        return store.state.locales;
    }

    public get locale(): string {
        return i18nService.locale;
    }

    public set locale(value: string) {
        i18nService.locale = value;
    }

    private currencyData: ICurrencyData = {
        USD: '420',
        EUR: '506',
        RUB: '5.89'
    } // данные

    private weatherData: IWeather = {
        degree: '23'
    }

    private async loadCurrencyData() {
        await fetch('/api-py/currency')
            .then(response => response.json())
            .then(json => (this.currencyData = json));
    }

    private async loadWeatherData() {
        // await fetch('/api-py/weather/'+this.$store.state._instanceCode)
        await fetch('/api-py/weather')
            .then(response => response.json())
            .then(json => (this.weatherData = json));
    }

    private mounted() {
        this.loadCurrencyData();
        this.loadWeatherData();
    }

    private scrollTop() {
        const mainConParent = document.getElementById('main-container');
        if (mainConParent instanceof HTMLElement) {
            mainConParent.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }
}