import {Vue, Component} from 'vue-property-decorator';

import requestStoreModule from '@/modules/requests/store';

import i18nService from '@/services/i18n';
import store, {storeActions} from '@/services/store';
import theme from '@/services/theme';
import {BIconBoxArrowRight} from 'bootstrap-vue'
import Notification, { IMessage } from "@/modules/cabinet/Notifications.vue";
import { mapState, mapGetters, mapActions } from "vuex";


@Component({
    components: {
        BIconBoxArrowRight,
        Notification,
    },
    computed: {
        ...mapState("notifications", {
            countUnreadMessages: (state: any) => state.countUnreadMessages,
            unreadMessages: (state: any) => state.unreadMessages,
            isShowPopUp: (state: any) => state.isShowPopUp,
        }),
        ...mapGetters("notifications", [
            "messages",
            "countUnreadMessages",
            "unreadMessages",
            "isShowPopUp",
        ]),
    },
    methods: {
        ...mapActions("notifications", [
            "changeStatus",
            "fetchCountUnreadNoty",
            "fetchUnreadMessages",
            "fetchStatusShowPopUp",
            "fetchMessages",
        ]),
    },
})

export default class CMenu extends Vue {
    private windowResizeEventHandler!: () => void;
    private windowClickListener: undefined | ((this: Window, ev: MouseEvent) => void);
    public intervalHandle: number | null = null;

    public showNotyModal = false;
    public showNotyWindow = false;
    public selectedMessage: any = null;
    public get unreadMessages(): IMessage[] {
        return this.$store.state.notifications.unreadMessages;
    }  
    
    public get countUnreadMessages(): number {
        return this.$store.state.notifications.countUnreadMessages;
    }
    public get isShowPopUp(): boolean {
        return this.$store.state.notifications.isShowPopUp;
    }
    public fetchCountUnreadNoty!: () => void;
    public fetchUnreadMessages!: any;
    public fetchStatusShowPopUp!: () => void;
    public changeStatus!: (payload: { id: number; status: number }) => void;

    public path = '';
    public opened = false;
    public profile = false;
    public isOpen = false;

    public srcTheme = '';

    public srcInfo = '';
    public srcDiagram = '';
    public srcMail = '';
    public srcVideo = '';
    public srcMap = '';
    public srcNewsMonitor = '';

    public srcDay = '';
    public srcMoon = '';

    private isMobile = false; // режим мобильного телефона
    private isLandscape = false; // альбомная ориентация

    /** новый дизайн */
    public region = 1;
    public regList = [
        {value: 0, text: 'г.Нур-султан'},
        {value: 1, text: 'Мангистауская область'}
    ];

    public created() {
        this.windowResizeEventHandler = () => {
            if (window.innerWidth <= 800) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }

            if ((window.innerWidth >= window.innerHeight) && (window.innerWidth <= 800)) {
                this.isLandscape = true;
            } else {
                this.isLandscape = false;
            }
        };

        this.windowClickListener = () => {
            this.opened = false;
            this.profile = false;
            if ((window.innerWidth >= window.innerHeight) && (window.innerWidth <= 800)) {
                this.isLandscape = !this.isLandscape;
            }
        };

        window.addEventListener('resize', this.windowResizeEventHandler);
        window.addEventListener('click', this.windowClickListener);

        this.$watch('themeDark', () => {
            this.initialSrc();
        });

        this.$watch('path', () => {
            this.initialSrc();
        });

        this.$watch("isShowPopUp", () => {
            this.showPopUp()
        })

        this.fetchCountUnreadNoty();
        this.fetchUnreadMessages();
        setInterval(this.checkNewUnreadMsg, 60000);
    }

    public mounted() {
        this.$router.afterEach((to, from) => {
            this.path = to.path;
        });
        this.fetchStatusShowPopUp();

        setTimeout(() => {
            this.showNotyWindow = false;
        }, 3000);

    }

    private beforeDestroy() {
        window.removeEventListener('resize', this.windowResizeEventHandler);
    }

    public destroyed() {
        if (this.windowClickListener !== undefined) {
            window.removeEventListener('click', this.windowClickListener);
        }

        if (this.intervalHandle !== null) {
            clearInterval(this.intervalHandle);
        }
    }

    private initialSrc() {
        if (theme.dark) {
            this.srcTheme = '/img/dark-theme';

            this.srcDay = this.srcTheme + '/menu/day_off.png';
            this.srcMoon = this.srcTheme + '/menu/moon_on.png';
        } else {
            this.srcTheme = '/img/light-theme';

            this.srcDay = this.srcTheme + '/menu/day_on.png';
            this.srcMoon = this.srcTheme + '/menu/moon_off.png';
        }

        this.srcInfo = this.srcTheme + '/menu/infopanel_off.png';
        this.srcDiagram = this.srcTheme + '/menu/diagram_off.png';
        this.srcMail = this.srcTheme + '/menu/message_off.png';
        this.srcVideo = this.srcTheme + '/menu/video_off.png';
        this.srcMap = this.srcTheme + '/menu/map_off.png';
        this.srcNewsMonitor = this.srcTheme + '/menu/diagram_off.png';


        switch (this.path) {
            case '/plashki':
                this.srcInfo = this.srcTheme + '/menu/infopanel_on.png';
                break;
            case '/datas':
                this.srcDiagram = this.srcTheme + '/menu/diagram_on.png';
                break;
            case '/requests':
                this.srcMail = this.srcTheme + '/menu/message_on.png';
                break;
            case '/media':
                this.srcVideo = this.srcTheme + '/menu/video_on.png';
                break;
            case '/astanamap':
                this.srcMap = this.srcTheme + '/menu/map_on.png';
                break;
            case '/news-monitor':
                this.srcNewsMonitor = this.srcTheme + '/menu/diagram_on.png';
                break;
            default:
                this.srcInfo = this.srcTheme + '/menu/infopanel_off.png';
                this.srcDiagram = this.srcTheme + '/menu/diagram_off.png';
                this.srcMail = this.srcTheme + '/menu/message_off.png';
                this.srcVideo = this.srcTheme + '/menu/video_off.png';
                this.srcMap = this.srcTheme + '/menu/map_off.png';
                this.srcNewsMonitor = this.srcTheme + '/menu/diagram_off.png';
        }
    }

    private menuClicked() {
        setTimeout(() => {
            this.opened = false;
        });
    }

    public toggleDrown() {
        this.isOpen = !this.isOpen;
    }

    // Обращения
    public get wipRequestCount(): number {
        return requestStoreModule.wipCount;
    }

    // переключение темы
    private get themeDark(): boolean {
        return theme.dark;
    }

    private onThemeToggleClick() {
        if (theme.dark) {
            this.srcTheme = '/img/dark-theme';

            this.srcDay = this.srcTheme + '/menu/day_off.png';
            this.srcMoon = this.srcTheme + '/menu/moon_on.png';
        } else {
            this.srcTheme = '/img/light-theme';

            this.srcDay = this.srcTheme + '/menu/day_on.png';
            this.srcMoon = this.srcTheme + '/menu/moon_off.png';
        }
        theme.dark = !theme.dark;
    }

    // авторизация
    private loginClicked() {
        setTimeout(() => {
            this.profile = false;
        });
    }

    private logoutClicked() {
        storeActions.logout();
        this.opened = false;
    }

    private updatePassClicked() {
        storeActions.updatePassword();
        this.opened = false;
    }

    public get isAuthorized(): boolean {
        return store.state.user.authorized;
    }

    public get userLogin(): string | null {
        return store.state.user.login;
    }

    public get mainText(): unknown {
        const mainTextFieldName = "mainText" + store.state._instanceCode;
        return this.$t(mainTextFieldName);
    }

    public get regionIcon(): unknown {
        if (store.state._instanceCode != "") {
            const mainTextFieldName = "/img/menu/region-icon-" + store.state._instanceCode + ".png";
            return this.$t(mainTextFieldName);
        }
        return this.$t("/img/menu/mangistau.jpeg");
    }

    // новые уведомления
    public getRowClass(item: any): string {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const dedlineDate = item.dedline
            ? new Date(item.dedline.split(".").reverse().join("-"))
            : null;

        if (item.status !== 4) {
            if (dedlineDate && dedlineDate > currentDate) {
                return "noty-green";
            } else if (dedlineDate && dedlineDate <= currentDate) {
                return "noty-red";
            } else {
                return "noty-green";
            }
        }
        return "noty-gray";
    }

    public openMessageModal(message: any) {
        this.selectedMessage = message;
        this.showNotyModal = true;
        this.changeStatus({ id: message.id, status: 4 });
    }

    public closePopUp() {
        this.showNotyWindow = false; 
    }

    public markAllAsRead() {
        this.unreadMessages.forEach((message: any) => {
            this.changeStatus({ id: message.id, status: 4 });
        });
    }

    public showPopUp() {
        if (this.isShowPopUp) {
            this.showNotyWindow = true;
        }
    }

    private checkNewUnreadMsg() {
        this.fetchUnreadMessages().then(() => {
            if (this.unreadMessages.length > 0 && this.isShowPopUp) {
                this.showNotyWindow = true;
                setTimeout(() => {
                    this.showNotyWindow = false;
                }, 3000);
            }
        });
        this.fetchCountUnreadNoty();
    }

}