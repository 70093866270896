import { Module } from 'vuex';

import storeService, { IState } from '@/services/store';
import { IIndicator, IModuleGetters, IModuleState, ISelectedIndicatorItems, ISliderData, TWidgetLoadState, TWidget, EWidgetType, IIndicatorItem } from './types';
import bridge from './bridge';


export const moduleName = 'widgetYard';

const today = new Date();
today.setMilliseconds(0);
today.setSeconds(0);
today.setMinutes(0);
today.setHours(0);

/**
 * Количество элементов, выделяемых для графиков
 */
const selectedItemsCount = 9;


const defaultExport: Module<IModuleState, IState> = {
    namespaced: true,
    state: {
        indicators: new Map(),
        sliderData: null,
        selectedIndicatorsItems: new Map(),
        widgets: [],
        categories: [],
        widgetsLoadState: 'loading'
    },
    getters: {
        widgetMap(state): Map<number, TWidget> {
            const result: Array<[number, TWidget]> = [];

            state.widgets.forEach((widget) => {
                result.push([widget.id, widget]);
            });

            return new Map<number, TWidget>(result);
        }
    },
    mutations: {
        setIndicators(state, indicators: IIndicator[]) {
            const newIndicators = new Map<string, IIndicator>();

            let periodRange: null | { min: Date; max: Date } = null;
            indicators.forEach((indicator) => {
                newIndicators.set(indicator.key, indicator);
                indicator.items.forEach((item) => {
                    if (periodRange === null) {
                        periodRange = {
                            min: item.periodStart,
                            max: item.periodEnd
                        };
                    } else {
                        if (periodRange.min > item.periodStart) {
                            periodRange.min = item.periodStart;
                        }
                        if (periodRange.max < item.periodEnd) {
                            periodRange.max = item.periodEnd;
                        }
                    }
                });
            });

            state.indicators = newIndicators;

            if (periodRange !== null) {
                const range = periodRange as { min: Date; max: Date };

                const sliderData = state.sliderData;
                if (sliderData === null) {
                    let prefferedDate: Date;

                    if (today < range.min) {
                        prefferedDate = range.min;
                    } else if (today > range.max) {
                        prefferedDate = range.max;
                    } else {
                        prefferedDate = today;
                    }

                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    accessor.sliderData = {
                        changedByUser: false,
                        max: range.max,
                        min: range.min,
                        value: prefferedDate
                    };
                } else {
                    if (sliderData.min > range.min) {
                        sliderData.min = range.min;
                    }
                    if (sliderData.max < range.max) {
                        sliderData.max = range.max;
                    }
                    if (!sliderData.changedByUser) {
                        if (today < sliderData.min) {
                            sliderData.value = sliderData.min;
                        } else if (today > sliderData.max) {
                            sliderData.value = sliderData.max;
                        } else {
                            sliderData.value = today;
                        }

                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                        accessor.sliderData = state.sliderData;
                    }
                }
            }
        },

        setSliderData(state, sliderData: null | ISliderData) {
            state.sliderData = sliderData;

            let sliderDate: null | Date = null;
            if (sliderData !== null) {
                sliderDate = sliderData.value;
            }

            const newItems: Map<string, ISelectedIndicatorItems> = new Map();

            if (sliderDate !== null) {
                const date = new Date(sliderDate);

                state.indicators.forEach((indicator) => {
                    let item: undefined | IIndicatorItem;

                    for (const existentItem of indicator.items) {
                        if (existentItem.periodStart <= date) {
                            item = existentItem;
                        } else {
                            break;
                        }
                    }

                    let isItemActual: boolean;
                    const items: IIndicatorItem[] = [];
                    let inItemsIndex = -1;
                    let previousItem: IIndicatorItem | undefined;
                    if (item === undefined) {
                        isItemActual = false;
                    } else {
                        isItemActual = (item.periodStart <= date) && (item.periodEnd >= date);

                        items.push(item);

                        let left = selectedItemsCount - 1;
                        let futureLeft = Math.floor(left / 2);

                        let index = indicator.items.indexOf(item) + 1;
                        while ((index < indicator.items.length) && (futureLeft > 0)) {
                            items.push(indicator.items[index]);

                            left--;
                            futureLeft--;
                            index++;
                        }

                        index = indicator.items.indexOf(item) - 1;
                        while ((index >= 0) && (left > 0)) {
                            items.unshift(indicator.items[index]);

                            left--;
                            index--;
                        }

                        inItemsIndex = items.indexOf(item);

                        index = indicator.items.indexOf(item);
                        if (index > 0) {
                            previousItem = indicator.items[index - 1];
                        }
                    }

                    newItems.set(indicator.key, {
                        previousItem,
                        item,
                        isItemActual,
                        key: indicator.key,
                        file: indicator.file,
                        indicator: indicator.indicator,
                        periodicity: indicator.periodicity,
                        withPlan: indicator.withPlan,
                        sheet: indicator.sheet,
                        shortIndicator: indicator.shortIndicator,
                        unit: indicator.unit,
                        character: indicator.character,
                        items,
                        inItemsIndex
                    });
                });
            }

            state.selectedIndicatorsItems = newItems;
        },

        setWidgetsLoadState(state, widgetsLoadState: TWidgetLoadState) {
            state.widgetsLoadState = widgetsLoadState;
        },

        setWidgets(state, widgets: TWidget[]) {
            state.widgets = widgets;
        },

        setCategories(state, categories: any[]) {
            state.categories = categories;
        },

        addWidget(state, widget: TWidget) {
            state.widgets.unshift(widget);
        },

        deleteWidget(state, id: number) {
            let index = -1;
            for (let i = 0; i < state.widgets.length; i++) {
                if (state.widgets[i].id === id) {
                    index = i;
                    break;
                }
            }

            if (index >= 0) {
                state.widgets.splice(index, 1);
            }
        },

        setWidgetType(state, payload: { id: number; type: EWidgetType }) {
            let newWidget: TWidget;
            switch (payload.type) {
                case EWidgetType.METERS:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.METERS,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.TEXT_BARS:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.TEXT_BARS,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.TEXT_LINES:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.TEXT_LINES,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.CHART_BARS:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.CHART_BARS,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.CHART_LINEAR_MARKERS:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.CHART_LINEAR_MARKERS,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.CHART_3D_PIE:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.CHART_3D_PIE,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.CHART_FLAT_PIE:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.CHART_FLAT_PIE,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.CHART_RADIUS_PIE:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.CHART_RADIUS_PIE,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.CHART_MULTIPLE_AXES:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.CHART_MULTIPLE_AXES,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.CHART_STACK_COLUMN:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.CHART_STACK_COLUMN,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.CHART_TREE_MAP:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.CHART_TREE_MAP,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.CHART_ACTUAL:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.CHART_ACTUAL,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.AM_CHARTS:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.AM_CHARTS,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.CHART_GANTT:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.CHART_GANTT,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.CHART_BUDGET:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.CHART_BUDGET,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: []
                    };
                    break;
                case EWidgetType.CHART_MODEL_DATA:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.CHART_MODEL_DATA,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.CHART_MODEL_LINEAR:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.CHART_MODEL_LINEAR,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.CHART_COVID:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.CHART_COVID,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.CHART_VACCINATION:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.CHART_VACCINATION,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                case EWidgetType.CHART_SOC:
                    newWidget = {
                        id: payload.id,
                        type: EWidgetType.CHART_SOC,
                        dictCategoryEntity: null,
                        indicatorKeys: [],
                        indicatorConfigs: [],
                        title: '',
                        size: [],
                        updateDate: new Date().getTime()
                    };
                    break;
                default:
                    throw new Error(`Unknown widget type "${payload.type}"`);
            }

            let found: null | TWidget = null;
            for (const existent of state.widgets) {
                if (existent.id === payload.id) {
                    found = existent;
                    break;
                }
            }

            if (found === null) {
                state.widgets.push(newWidget);
            } else {
                newWidget.title = found.title;
                state.widgets.splice(
                    state.widgets.indexOf(found),
                    1,
                    newWidget
                );
            }
        }
    }
};
export default defaultExport;



const getState = (): IModuleState => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-extra-parens
    return (storeService.state as any)[moduleName] as IModuleState;
};

let cachedGetters: null | IModuleGetters = null;
const getGetters = (): IModuleGetters => {
    if (cachedGetters !== null) {
        return cachedGetters;
    }

    cachedGetters = {
        get widgetMap(): Map<number, TWidget> {
            return storeService.getters[`${moduleName}/widgetMap`];
        }
    };
    return cachedGetters;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const commit = (mutation: string, payload: any) => {
    storeService.commit(`${moduleName}/${mutation}`, payload);
};


let updateTime = Date.now();


export const accessor = {
    /**
     * Получение состояния модуля
     */
    getState,

    getIsReadyToUpdate(){
        if (this.indicators.length==0) {
            return true
        }
        const d = Date.now();
        const delta = d - updateTime;
        return (delta/10000>10);
    },

    // #region Indicators
    /**
     * Показатели со своими элементами
     */
    get indicators(): IIndicator[] {
        const result: IIndicator[] = [];
        const indicators = getState().indicators;

        indicators.forEach((indicator) => {
            result.push(indicator);
        });

        return result;
    },
    /**
     * Показатели со своими элементами
     */
    set indicators(value: IIndicator[]) {
        updateTime = Date.now();
        commit('setIndicators', value);
    },

    /**
     * Поиск показателя по его ключу
     *
     * @param key ключ показателя (`IIndicator.key`)
     *
     * @returns показатель или `undefined`, если показатель не найден
     */
    getIndicator(key: string): undefined | IIndicator {
        return getState().indicators.get(key);
    },
    // #endregion


    // #region sliderData
    /**
     * Данные слайдера
     */
    get sliderData(): null | ISliderData {
        return getState().sliderData;
    },

    /**
     * Данные слайдера
     */
    set sliderData(value: null | ISliderData) {
        commit('setSliderData', value);
    },
    // #endregion


    // #region Selected indicators' items
    /**
     * Карта элементов показателей, выбранных на основе даты слайдера
     *
     * Ключ карты - ключ показателя (`IIndicator.key`)
     */
    get selectedMap(): Map<string, ISelectedIndicatorItems> {
        return getState().selectedIndicatorsItems;
    },

    /**
     * Массив элементов показателей, выбранных на основе даты слайдера
     */
    get selectedArray(): ISelectedIndicatorItems[] {
        const map = this.selectedMap;

        const result: ISelectedIndicatorItems[] = [];
        for (const entry of map) {
            result.push(entry[1]);
        }

        result.sort((a, b): number => {
            if (a.key < b.key) {
                return -1;
            }
            if (a.key > b.key) {
                return 1;
            }
            return 0;
        });

        return result;
    },
    // #endregion


    // #region Widgets
    /**
     * Состояние загрузки виджетов
     */
    get widgetsLoadState(): TWidgetLoadState {
        return getState().widgetsLoadState;
    },

    /**
     * Состояние загрузки виджетов
     */
    set widgetsLoadState(value: TWidgetLoadState) {
        commit('setWidgetsLoadState', value);
    },

    /**
     * Массив виджетов
     */
    get widgets(): TWidget[] {
        return getState().widgets;
    },
    /**
     * Массив виджетов
     */
    set widgets(value: TWidget[]) {
        commit('setWidgets', value);
    },

    get categories(): any {
        return getState().categories;
    },

    set categories(value: any) {
        commit('setCategories', value);
    },

    /**
     * Карта виджетов, ключ - ИД виджета (`TWidget.id`)
     */
    get widgetMap(): Map<number, TWidget> {
        return getGetters().widgetMap;
    },

    /**
     * Сохранение виджетов
     */
    saveWidgets() {
        const state = getState().widgetsLoadState;

        if (state === 'ready') {
            bridge.saveWidgets();
        }
    },

    /**
     * Добавление виджета
     * @param widget Добавляемый виджет
     */
    addWidget(widget: TWidget) {
        commit('addWidget', widget);
    },

    /**
     * Удаление виджета
     * @param id ИД удаляемого виджета (`TWidget.id`)
     */
    deleteWidget(id: number) {
        commit('deleteWidget', id);
    },

    /**
     * Изменение типа виджета
     * @param id ИД изменяемого виджета (`TWidget.id`)
     * @param type новый тип виджета
     */
    setWidgetType(id: number, type: EWidgetType) {
        const payload: { id: number; type: EWidgetType } = { id, type };
        commit('setWidgetType', payload);
    }
    // #endregion
};