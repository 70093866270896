import i18nService from '@/services/i18n';
import { IAnchors, IMenuItem, TMenuItem, TInitializationStep, IBudgetMenuData, IMenuNavRoute } from './types';
import { forbiddenQueryKeys } from './utils';


export const anchors: IAnchors = {
    // Социально-экономические показатели
    socEco: {
        type: 'menu',
        title: {
            text: 'Socio-economic indicators',
            i18n: 'app.links.soc-eco'
        },
        icon: 'graphic',
        children: []
    },

    // Социально-экономическая модель
    socMod: {
        type: 'menu',
        title: {
            text: 'Socio-economic model',
            i18n: 'app.links.soc-mod'
        },
        icon: 'binocular-graphic',
        children: []
    },

    // Программы развития
    developmentPrograms: {
        type: 'menu',
        title: {
            text: 'Development programs',
            i18n: 'app.links.development_programs'
        },
        icon: 'magnifier-graphic',
        children: []
    },

    // Бюджет
    budget: {
        type: 'menu',
        title: {
            text: 'Budget',
            i18n: 'app.links.budget'
        },
        icon: 'calculator-coins',
        children: []
    }
};

export const initialItems: TMenuItem[] = [
    // Социально-экономические показатели
    anchors.socEco,

    // Социально-экономическая модель
    anchors.socMod,

    // СГП
    {
        type: 'menu',
        title: {
            text: 'СГП'
        },
        icon: {
            type: 'svg',
            path: 'M18,11H6V6H18M16.5,17A1.5,1.5 0 0,1 15,15.5A1.5,1.5 0 0,1 16.5,14A1.5,1.5 0 0,1 18,15.5A1.5,1.5 0 0,1 16.5,17M7.5,17A1.5,1.5 0 0,1 6,15.5A1.5,1.5 0 0,1 7.5,14A1.5,1.5 0 0,1 9,15.5A1.5,1.5 0 0,1 7.5,17M4,16C4,16.88 4.39,17.67 5,18.22V20A1,1 0 0,0 6,21H7A1,1 0 0,0 8,20V19H16V20A1,1 0 0,0 17,21H18A1,1 0 0,0 19,20V18.22C19.61,17.67 20,16.88 20,16V6C20,2.5 16.42,2 12,2C7.58,2 4,2.5 4,6V16Z'
        },
        children: [
            // Справочники
            {
                type: 'item',
                title: {
                    text: 'Справочники'
                },
                icon: {
                    type: 'svg',
                    path: 'M5.81,2C4.83,2.09 4,3 4,4V20C4,21.05 4.95,22 6,22H18C19.05,22 20,21.05 20,20V4C20,2.89 19.1,2 18,2H12V9L9.5,7.5L7,9V2H6C5.94,2 5.87,2 5.81,2M12,13H13A1,1 0 0,1 14,14V18H13V16H12V18H11V14A1,1 0 0,1 12,13M12,14V15H13V14H12M15,15H18V16L16,19H18V20H15V19L17,16H15V15Z'
                },
                nav: {
                    type: 'route',
                    path: '/demo-budget-execution/sgp/dict'
                }
            },

            // ЦИ
            {
                type: 'item',
                title: {
                    text: 'ЦИ'
                },
                nav: {
                    type: 'route',
                    path: '/demo-budget-execution/sgp/target-indicators',
                    isActive: router => router.currentRoute.path.startsWith('/demo-budget-execution/sgp/target-indicators')
                }
            },

            // Определение потребностей
            {
                type: 'item',
                title: {
                    text: 'Определение потребностей'
                },
                nav: {
                    type: 'route',
                    path: '/demo-budget-execution/roads/roads-map'
                }
            },

            // БИП
            {
                type: 'item',
                title: {
                    text: 'БИП'
                },
                nav: {
                    type: 'route',
                    path: '/demo-budget-execution/roads/bip-roads/'
                }
            },

            // Моделирование
            {
                type: 'item',
                title: {
                    text: 'Моделирование'
                },
                nav: {
                    type: 'route',
                    path: '/demo-budget-execution/modeling/'
                }
            }
        ]
    },

    // ПСЭР
    {
        type: 'menu',
        title: {
            text: 'ПСЭР'
        },
        children: [
            // Соц.-эконом. показатели
            {
                type: 'item',
                title: {
                    text: 'Соц.-эконом. показатели'
                },
                nav: {
                    type: 'route',
                    path: '/demo-budget-execution/pser/soc-eco-indicators'
                }
            },

            // Прогноз бюджета (лимиты)
            {
                type: 'item',
                title: {
                    text: 'Прогноз бюджета (лимиты)'
                },
                nav: {
                    type: 'route',
                    path: '/demo-budget-execution/pser/budget-prognosis'
                }
            }
        ]
    },

    // Бюджетное планирование
    {
        type: 'menu',
        title: {
            text: 'Бюджетное планирование'
        },
        icon: 'calculator-coins',
        children: [
            // Классификаторы
            {
                type: 'item',
                title: {
                    text: 'Классификаторы'
                },
                nav: {
                    type: 'route',
                    path: '/demo-budget-execution/budget-plan/classifiers'
                }
            },

            // Расчет лимитов
            {
                type: 'item',
                title: {
                    text: 'Расчет лимитов'
                },
                nav: {
                    type: 'route',
                    path: '/demo-budget-execution/budget-plan/limit-calc'
                }
            },

            // Формирование
            {
                type: 'menu',
                title: {
                    text: 'Формирование'
                },
                children: [
                    // Переходящий бюджет
                    {
                        type: 'item',
                        title: {
                            text: 'Переходящий бюджет'
                        },
                        nav: {
                            type: 'route',
                            path: '/demo-budget-execution/budget-plan/creation/flowing-budget'
                        }
                    },

                    // Дополнительная потребность
                    {
                        type: 'item',
                        title: {
                            text: 'Дополнительная потребность'
                        },
                        nav: {
                            type: 'route',
                            path: '/demo-budget-execution/budget-plan/creation/add-needs'
                        }
                    },

                    // БЗ
                    {
                        type: 'item',
                        title: {
                            text: 'БЗ'
                        },
                        nav: {
                            type: 'route',
                            path: '/demo-budget-execution/request/request'
                        }
                    },

                    // БП
                    {
                        type: 'item',
                        title: {
                            text: 'БП'
                        },
                        nav: {
                            type: 'route',
                            path: ''
                        }
                    },

                    // Заключение ОБК
                    {
                        type: 'item',
                        title: {
                            text: 'Заключение ОБК'
                        },
                        nav: {
                            type: 'route',
                            path: '/demo-budget-execution/request/budget-commission'
                        }
                    }
                ]
            },

            // Корректировка
            {
                type: 'item',
                title: {
                    text: 'Корректировка'
                },
                nav: {
                    type: 'route',
                    path: '/budget-correct?id=b2b53b68-4a2d-4e16-a12b-1ac7178b5e33&length=0'
                }
            },

            // Уточнение
            {
                type: 'item',
                title: {
                    text: 'Уточнение'
                },
                nav: {
                    type: 'route',
                    path: '/budget-clarify?id=c8238c0d-1c8c-44b8-86c7-2db0d7d94031&length=0'
                }
            }
        ]
    },

    // Исполнение бюджета
    {
        type: 'menu',
        title: {
            text: 'Исполнение бюджета'
        },
        children: [
            // Классификаторы
            {
                type: 'item',
                title: {
                    text: 'Классификаторы'
                },
                nav: {
                    type: 'route',
                    path: ''
                }
            },

            // Исполнение
            {
                type: 'menu',
                title: {
                    text: 'Исполнение'
                },
                children: [
                    // Сводные данные
                    {
                        type: 'item',
                        title: {
                            text: 'Сводные данные'
                        },
                        nav: {
                            type: 'route',
                            path: '/demo-budget-execution/request/budget-plan'
                        }
                    },

                    // Поступления
                    {
                        type: 'item',
                        title: {
                            text: 'Поступления'
                        },
                        nav: {
                            type: 'route',
                            path: ''
                        }
                    },

                    // Расходы
                    {
                        type: 'item',
                        title: {
                            text: 'Расходы'
                        },
                        nav: {
                            type: 'route',
                            path: '/demo-budget-execution/execution/budget-forms'
                        }
                    }
                ]
            }
        ]
    },

    // Программы развития
    anchors.developmentPrograms,

    // Бюджет
    anchors.budget,

    // Бюджетная заявка
    {
        type: 'menu',
        title: {
            text: 'Budget request',
            i18n: 'app.links.budget_request'
        },
        icon: 'calculator-coins',
        children: [
            // Форма 01-111
            {
                type: 'item',
                title: {
                    text: 'Форма 01-111'
                },
                nav: {
                    type: 'route',
                    path: '/form01-111',
                    query: {
                        id: 1
                    }
                }
            },

            // Форма 02-111
            {
                type: 'item',
                title: {
                    text: 'Форма 02-111'
                },
                nav: {
                    type: 'route',
                    path: '/form02-111',
                    query: {
                        id: 2
                    }
                }
            },

            // Форма 01-123
            {
                type: 'item',
                title: {
                    text: 'Форма 01-123'
                },
                nav: {
                    type: 'route',
                    path: '/form01-123',
                    query: {
                        id: 3
                    }
                }
            },

            // Форма 02-123
            {
                type: 'item',
                title: {
                    text: 'Форма 02-123'
                },
                nav: {
                    type: 'route',
                    path: '/form02-123',
                    query: {
                        id: 4
                    }
                }
            },

            // Форма 01-144
            {
                type: 'item',
                title: {
                    text: 'Форма 01-144'
                },
                nav: {
                    type: 'route',
                    path: '/form01-144',
                    query: {
                        id: 5
                    }
                }
            }
        ]
    },

    // Медиамониторинг
    {
        type: 'item',
        title: {
            text: 'Media monitoring',
            i18n: 'app.links.media_monitoring'
        },
        icon: 'magnifier-user',
        nav: {
            type: 'external',
            path: 'http://mm.csi.kz/main'
        }
    },

    // Исполнение поручений
    {
        type: 'item',
        title: {
            text: 'Execution of orders',
            i18n: 'app.links.execution_of_orders'
        },
        icon: 'document-pencil',
        nav: {
            type: 'route',
            path: '/requests',
            query: {
                value: 6,
                links: [51, 52]
            }
        }
    },

    // Полезное
    {
        type: 'item',
        title: {
            text: 'Useful',
            i18n: 'app.links.useful'
        },
        icon: 'star',
        nav: {
            type: 'route',
            path: '/astanamap',
            query: {
                value: 7,
                links: [61, 62]
            }
        }
    }
];


// #region Методы для изменения меню
/**
 * Загрузка категорий, изменяются меню "Социально-экономические показатели" и "Социально-экономическая модель"
 * @param callback вызывается при завершении, после загрузки данных
 */
const loadCategories = (callback: () => void) => {
    interface ICategory extends Record<string, any> {
        id: number;
        code: string;
        widgets: IWidget[];
        // eslint-disable-next-line @typescript-eslint/camelcase
        name_ru: string;
        parent: null | number;
        children: ICategory[];
    }

    interface IWidget extends Record<string, any> {
        dictCategoryEntity?: {
            id?: number;
        };
    }

    // Грузим категории
    fetch('/api/dict/integration_category')
        .then(response => response.json())
        .then((categories: ICategory[]) => {
            const categoryMapById: Map<number, ICategory> = new Map();
            const categoryMapByCode: Map<string, ICategory> = new Map();
            const rootCategories: ICategory[] = [];

            // Сортируем категории
            categories.sort((a, b) => a.id - b.id);

            // Подготавливаем категории
            categories.forEach(category => {
                category.code = category.code.replace(' \t', '');
                category.widgets = [];
                category.children = [];
                categoryMapById.set(category.id, category);
                categoryMapByCode.set(category.code, category);
            });

            // Выстраиваем иерархию категорий
            categories.forEach(category => {
                if (typeof category.parent === 'number') {
                    const parentCategory = categoryMapById.get(category.parent);
                    if (parentCategory !== undefined) {
                        parentCategory.children.push(category);
                    } else {
                        rootCategories.push(category);
                    }
                } else {
                    rootCategories.push(category);
                }
            });

            // Грузим виджеты
            fetch('/api-py/datas/hierarchy')
                .then(response => response.json())
                .then((widgets: IWidget[]) => {
                    // Раскидываем виджеты по категориям
                    widgets.forEach((widget) => {
                        if ((widget.dictCategoryEntity !== undefined) && (widget.dictCategoryEntity !== null) && (typeof widget.dictCategoryEntity.id === 'number')) {
                            const category = categoryMapById.get(widget.dictCategoryEntity.id);
                            if (category !== undefined) {
                                category.widgets.push(widget);
                            }
                        }
                    });


                    // Категории "Социально-экономические показатели" начинаются с "01.", их в "socEco"
                    (() => {
                        const socEcoChildren = anchors.socEco.children;
                        if (socEcoChildren.length > 0) {
                            socEcoChildren.splice(0, socEcoChildren.length);
                        }

                        categories.forEach(category => {
                            if (category.code.startsWith('01.') && category.widgets.isNotEmpty) {
                                const item: TMenuItem = {
                                    type: 'item',
                                    title: {
                                        text: category.name_ru
                                    },
                                    nav: {
                                        type: 'route',
                                        path: '/soc-eco',
                                        query: {
                                            id: category.id
                                        }
                                    }
                                };
                                socEcoChildren.push(item);
                            }
                        });
                    })();

                    // Категории для "Социально-экономическая модель" начинаются с "02.", их в "socMod"
                    (() => {
                        const socModChildren = anchors.socMod.children;
                        if (socModChildren.length > 0) {
                            socModChildren.splice(0, socModChildren.length);
                        }

                        const rootSocModCategory = categoryMapByCode.get('02');
                        if (rootSocModCategory !== undefined) {
                            // Жесть - "поднятие" виджетов из дочерних категорий в родительские
                            // скопировано из "src/components/c-board/c-board.ts", "getCategory()"
                            rootSocModCategory.children.forEach(child1 => {
                                if (child1.children.length > 0) {
                                    child1.children.forEach(child2 => {
                                        if (child2.widgets.length > 0) {
                                            child2.widgets.forEach(widget => {
                                                child1.widgets.push(widget);
                                            });
                                        }
                                        if (child2.children.length > 0) {
                                            child2.children.forEach(child3 => {
                                                if (child3.widgets.length > 0) {
                                                    child3.widgets.forEach((widget: any) => {
                                                        child2.widgets.push(widget);
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }
                                if (child1.widgets.length > 0) {
                                    const item: IMenuItem = {
                                        type: 'item',
                                        title: {
                                            text: child1.name_ru
                                        },
                                        nav: {
                                            type: 'route',
                                            path: '/soc-mod',
                                            query: {
                                                id: child1.id
                                            }
                                        }
                                    };
                                    socModChildren.push(item);
                                }
                            });
                        }
                    })();

                    callback();
                });
        });
};

/**
 * Загрузка программ развития, изменяется меню "Программы развития"
 * @param callback вызывается при завершении, после загрузки данных
 */
const loadDevelopmentPrograms = (callback: () => void) => {
    interface IDevProgram extends Record<string, any> {
        id: number;
        // eslint-disable-next-line @typescript-eslint/camelcase
        name_en: string;
        // eslint-disable-next-line @typescript-eslint/camelcase
        name_kz: string;
        // eslint-disable-next-line @typescript-eslint/camelcase
        name_ru: string;
    }

    fetch('/api/dict/program')
        .then(response => response.json())
        .then((programs: IDevProgram[]) => {
            const items = anchors.developmentPrograms.children;
            if (items.isNotEmpty) {
                items.splice(0, items.length);
            }

            items.push({
                type: 'item',
                title: {
                    text: 'Общие показатели'
                },
                nav: {
                    type: 'route',
                    path: '/program-monitor2',
                    query: {
                        id: -1
                    }
                }
            });

            programs.forEach(program => {
                let title: string;
                if (i18nService.locale === 'kk') {
                    title = program.name_kz;
                } else {
                    title = program[`name_${i18nService.locale}`];
                }

                items.push({
                    type: 'item',
                    title: {
                        text: title
                    },
                    nav: {
                        type: 'route',
                        path: '/program-monitor2',
                        query: {
                            id: program.id
                        }
                    }
                });
            });

            callback();
        });
};

/**
 * Применение загруженных данных для меню "Бюджет", изменяется это меню
 * @param dataItems загруженные данные
 */
export const applyDataForBudgetMenu = (dataItems: IBudgetMenuData[]) => {
    const ignoredQueryKeys: string[] = ['i18n', 'href'];
    ignoredQueryKeys.push(...forbiddenQueryKeys);

    const dataToItem = (data: IBudgetMenuData): IMenuItem => {
        const nav: IMenuNavRoute = {
            type: 'route',
            path: data.href,
            isActive: router => (router.currentRoute.path === data.href)
        };

        const result: IMenuItem = {
            type: 'item',
            title: {
                text: `Budget item #${data.id}`,
                i18n: data.i18n
            },
            nav
        };

        const query: Record<string, any> = [];
        let queryIsEmpty = true;
        Object.getOwnPropertyNames(data).forEach(key => {
            if (ignoredQueryKeys.notIncludes(key)) {
                query[key] = data[key];
                queryIsEmpty = false;
            }
        });

        if ((!queryIsEmpty)) {
            nav.query = query;
        }

        return result;
    };

    const budgetMenuChildren = anchors.budget.children;
    if (budgetMenuChildren.isNotEmpty) {
        budgetMenuChildren.splice(0, budgetMenuChildren.length);
    }

    if (Array.isArray(dataItems)) {
        dataItems.forEach(data => {
            const item = dataToItem(data);
            budgetMenuChildren.push(item);
        });
    }
};

/**
 * Инициализация в несколько шагов - загрузка категорий и программ развития
 *
 * Шаги завершаются асинхронно (из-за загрузки данных)
 *
 * @param finalCallback вызывается после завершения всех шагов
 * @param stepCallback вызывается после завершения каждого из шагов
 */
export const initialize = (finalCallback: (() => void) | null | undefined, stepCallback?: (step: TInitializationStep) => void) => {
    const stepsBeforeFinal: TInitializationStep[] = ['categories', 'development-programs'];

    const applyStep = (step: TInitializationStep) => {
        if (stepCallback) {
            try {
                stepCallback(step);
            } catch (e) {
                console.error(e);
            }
        }

        const index = stepsBeforeFinal.indexOf(step);
        if (index >= 0) {
            stepsBeforeFinal.splice(index, 1);
        }

        if (stepsBeforeFinal.isEmpty && (typeof finalCallback === 'function')) {
            try {
                finalCallback();
            } catch (e) {
                console.error(e);
            }
        }
    };

    loadCategories(() => applyStep('categories'));
    loadDevelopmentPrograms(() => applyStep('development-programs'));
};
// #endregion