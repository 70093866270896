<template>
  <div
    v-if="videoPlayerState.isOpen"
    :class="`youtube-player ${isFullscreen && 'fullscreen'}`"
  >
    <div class="player-container">
      <div class="player-wrapper">
        <iframe
          :src="videoPlayerState.embedSrc"
          frameborder="0"
          allowfullscreen
          class="player"
        ></iframe>
      </div>
      <div class="body">
        <!-- <div class="name">{{ videoPlayerState.name }}</div>
        <div class="subtitle">{{ videoPlayerState.name }}</div> -->
        <div class="buttons">
          <div>
            <img
              @click="toggleFullscreen"
              src="./icons/fullscreen.svg"
              alt=""
            />
            <img @click="openLink" src="./icons/open-link.svg" alt="" />
          </div>
          <div>
            <b-button @click="handleCloseModal">Закрыть</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "VideoPlayer",
  data() {
    return {
      isFullscreen: false,
    };
  },
  computed: {
    ...mapGetters({
      videoPlayerState: "videoPlayerState",
    }),
  },
  methods: {
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
    },
    openLink() {
      window.open(this.videoPlayerState.src, "_blank");
    },
    ...mapMutations({
      setVideoPlayerState: "setVideoPlayerState",
    }),
    handleCloseModal() {
      this.setVideoPlayerState({
        isOpen: false,
        src: null,
        embedSrc: null,
        name: null,
      });
      this.isFullscreen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.youtube-player {
  position: fixed;
  bottom: 4px;
  right: 60px;
  width: 420px;
  height: 344px;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 0px 15px 0px #00000033;
  overflow: hidden;
  background-color: #fff;
  z-index: 10000000000000;
}

.player-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  border-radius: 10px;
  justify-content: space-between;
  height: 100%;
}

.player-wrapper {
  border-radius: 10px;
}

.player {
  width: 400px;
  height: 225px;
  border-radius: 10px;
}

.body {
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .name {
    font-size: 14px;
    font-weight: 500;
  }

  .subtitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 16.39px;
    color: #6087a0;
  }

  .buttons {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      gap: 10px;
    }

    img {
      cursor: pointer;
    }
  }
}

.youtube-player.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  z-index: 10000;
  background: rgba($color: #000000, $alpha: 0.4);
  display: flex;
  justify-content: center;
  align-items: center;

  .player {
    width: 800px;
    height: 550px;
  }

  .body {
    background: #fff;
    width: 800px;
  }
}
</style>
