
























import extend from 'extend';
import {Component, Vue} from 'vue-property-decorator';
import {VNode, VNodeData} from 'vue';

import {CText} from '@/components';
import auth from '@/services/auth';
import store from '@/services/store';
import {I18nTemplate} from './services/i18n';
import {BUILD_HASH_TIME} from '@/utils/version'
import VideoPlayer from './modules/guide/video-player.vue';

const localStorageKeys = {
    plainMenu: 'app.plainMenu'
};

@Component({
    components: {
        'video-player': VideoPlayer
  }
})
export default class App extends Vue {
    private isActive = false;
    public get active(): boolean {
        return this.isActive;
    }

    private plainMenu = true;

    public get isPlainMenu(): boolean {
        return this.plainMenu;
    }

    private isHover = false;
    private noFilters = false;
    private isHome = false;
    private noTabs = false;
    private filterOverTabs = false;
    private loader = true;
    private bipModel = false;
    private filterContainerHeight: number|undefined;
    private mainContainerMargin: number|undefined;
    private mainContainerStyles: any = '';

    private get keycloakInitialized(): boolean {
        return store.state.keyclockInitialized;
    }

    private get userModulesLoaded(): boolean {
        return store.state.user.modulesLoaded;
    }

    private renderI18nTemplate(template: I18nTemplate, data?: VNodeData): VNode {
        let actualData: VNodeData = {
            props: {
                data: template
            }
        };

        if (data !== undefined) {
            actualData = extend(true, {}, data, actualData);
        }

        return this.$createElement(CText, actualData);
    }

    private get mainCon(): HTMLDivElement {
        return (this.$refs.mainCon as any as HTMLDivElement);
    }

    private windowTop = 0;
    private windowCalc = false;
    private onScroll() {
        this.windowTop = this.mainCon.scrollTop;
        if (this.windowTop < 150){
            if (this.mainCon.scrollHeight > window.innerHeight + 100) {
                this.windowCalc = true;
            } else {
                this.windowCalc = false;
            }
        }
        this.mainContainerStyles = this.containerStyle();
    }

    private containerStyle() {
      if (this.mainContainerMargin !== undefined) {
        const mainContainerMargin = (this.windowTop > 100 && this.windowCalc)
            ? (this.mainContainerMargin - 130)
            : this.mainContainerMargin;
        return {
          marginTop: `${mainContainerMargin}px`,
          height: `calc(100vh - ${mainContainerMargin}px)`
        };
      }
      if (this.filterContainerHeight !== undefined && this.filterContainerHeight > 0) {
        const base = (this.windowTop > 100 && this.windowCalc) ? 40 : 170;
        return {
          marginTop: `${base + this.filterContainerHeight}px`,
          height: `calc(100vh - ${base + this.filterContainerHeight}px)`
        };
      }
      return '';
    }

    private mounted(){
        console.info("VERSION:", BUILD_HASH_TIME);
        this.$root.$on('removeClass', () => {
           this.noTabs = !this.noTabs;
        });

        this.$root.$on('bipModel', () => {
            this.bipModel = !this.bipModel;
            this.filterOverTabs = !this.filterOverTabs;
        });

        this.$root.$on('filterContainer', (value: number) => {
          this.filterContainerHeight = value;
          this.mainContainerStyles = this.containerStyle();
        });

        this.$root.$on('mainContainerMargin', (value: number) => {
          this.mainContainerMargin = value;
          this.mainContainerStyles = this.containerStyle();
        });

        auth.removeInitialDom();
    }

    private created() {
        this.$root.$on('openBoard', () => {
            this.isActive = !this.isActive;
        });

        this.$root.$watch('$route', () => {
            const path = this.$route.path;
            const name = this.$route.name;
            const meta : any = this.$route.meta;
            if (path === '/budget-execution' || path === '/budget-request' || name === 'additionally-menu') {
                this.noFilters = true;
            } else {
                this.noFilters = false;
            }

            if (path === '/' || path === '/examples') {
                this.isHome = true;
            } else {
                this.isHome = false;
            }

            if (path === '/forecast-form' || path === '/soc-eco' || path === '/soc-mod' || path === '/snp'
                || path === '/budget-sum-calc' || path === '/monitoring' || path === '/derived-forms' || path === '/forms' || path === '/budget-loader'
                || name === 'form01-123' || name === 'form02-123' || name === 'form01-136' || name === 'form01-141' || name === 'form01-142'
                || name === 'form01-143' || name === 'form01-144' || name === 'form01-149'
                || name === 'form01-151' || name === 'form01-152' || name === 'form01-153' || name === 'form01-159'
                || name === 'form01-161' || name === 'form01-162' || name === 'form01-169' || name === 'form01-413'
                || name === 'form01-414' || name === 'form01-416' || name === 'form02-111' || name === 'form02-141'
                || name === 'form02-142' || name === 'form02-144' || name === 'form02-149' || name === 'form02-151'
                || name === 'form02-159' || name === 'form02-414' || name === 'form03-141' || name === 'form03-142'
                || name === 'form03-149' || name === 'form03-151' || name === 'form03-159' || name === 'form04-141'
                || name === 'form04-151' || path === '/bip/bip-form' || path === '/forecast-target-form' || path === '/widget-yard'
                || path === '/staffing-table/config' || path === '/budget-execution-purchase-plan'
                || name === 'budget-form-concl-bp'
            ) {
                this.noTabs = true;
            } else {
                this.noTabs = false;
            }

            if (name === 'budget-program' || path === '/bip/bip-form') {
                this.filterOverTabs = true;
            } else {
                this.filterOverTabs = false;
            }

            if (meta.rootClasses) {
                meta.rootClasses.forEach((c: any) => {
                    this.noTabs = c === 'no-tabs';
                    this.noFilters = c === 'no-filters';
                });
            }

            this.filterContainerHeight = undefined;
            this.mainContainerMargin = undefined;
            this.mainContainerStyles = this.containerStyle();
        });

        store.watch(
            state => state.keyclockInitialized,
            () => {
                if (this.keycloakInitialized) {
                    let hash = location.hash;
                    if (hash.startsWith('#')) {
                        hash = hash.substring(1);
                    }

                    const fullPath = this.$route.fullPath;
                    if ((fullPath !== hash) && fullPath.startsWith(hash)) {
                        const resolved = this.$router.resolve({
                            path: hash
                        });

                        if (resolved.location.query && ('demo' in resolved.location.query)) {
                            if (resolved.location.query.demo === 'false') {
                                this.setPlainMenu();
                            } else {
                                this.setTreeMenu();
                            }
                            delete resolved.location.query.demo;
                        }

                        this.$router.push(resolved.location);

                        this.$root.$watch('$route', () => {
                            const route = this.$root.$route;
                            if (route.query && ('demo' in route.query)) {
                                if (route.query.demo === 'false') {
                                    this.setPlainMenu();
                                } else {
                                    this.setTreeMenu();
                                }

                                const query: Record<string, any> = {};
                                Object.getOwnPropertyNames(route.query).forEach(key => {
                                    if (key !== 'demo') {
                                        query[key] = route.query[key];
                                    }
                                });

                                this.$root.$router.push({
                                    name: route.name || undefined,
                                    path: route.path,
                                    hash: route.hash,
                                    query: query,
                                    params: route.params,
                                    replace: true
                                });
                            }
                        });
                    }
                }
            }
        );

        (window as any).app = this;

        const plainMenu = localStorage.getItem(localStorageKeys.plainMenu);
        if (plainMenu === 'false') {
            this.plainMenu = false;
        }

        if (!this.plainMenu) {
            const html = document.querySelector('html');
            if (html instanceof HTMLElement) {
                html.classList.add('tree-menu');
            }
        }

        if (location.href.includes('?demo=false') || location.href.includes('&demo=false')) {
            this.setPlainMenu();
        } else if (location.href.includes('?demo') || location.href.includes('&demo')) {
            this.setTreeMenu();
        }
    }

    private destroyed() {
        if ((window as any).app === this) {
            delete (window as any).app;
        }
    }

    private setPlainMenu() {
        this.plainMenu = true;
        localStorage.setItem(localStorageKeys.plainMenu, 'true');
        const html = document.querySelector('html');
        if (html instanceof HTMLElement) {
            html.classList.remove('tree-menu');
        }
    }

    private setTreeMenu() {
        this.plainMenu = false;
        localStorage.setItem(localStorageKeys.plainMenu, 'false');
        const html = document.querySelector('html');
        if (html instanceof HTMLElement) {
            html.classList.add('tree-menu');
        }
    }
}
