// настройка общего хранилища

import Vue from "vue";
import Vuex from "vuex";
import auth from "@/services/auth";
import notifications from "./notifications";


Vue.use(Vuex);


/**
 * Данные о текущем пользователе
 */
interface IUserInfo {
    /**
     * Логин
     */
    login: null | string;

    /**
     * Авторизован ли пользователь
     */
    authorized: boolean;

    /**
     * Роли пользователя
     */
    roles: string[];

    /**
     * Пути групп пользователей
     *
     * @example ['/budget/abp/110', '/budget/budget-clarify', ...]
     */
    groupPaths: string[];

    /**
     * id
     */
    sub?: string;

    preferred_username?: string;
    groups?: ILoadedUserGroupInfo[];
    userModules: IUserModules[];
    modulesLoaded: boolean;
}

/**
 * Данные о пользователе, получаемые с сервера
 */
interface ILoadedUserInfo {
    /**
     * Логин
     */
    login: string;

    /**
     * id
     */
    sub?: string;

    preferredUsername?: string;

    /**
     * Роли
     */
    roles: string[];
    groups: ILoadedUserGroupInfo[];
    userModules: IUserModules[];
    modulesLoaded: boolean;
}

interface ILoadedUserGroupInfo {
    id: string;
    name: string;
    path: string;
}

interface IUserModules {
    modules: string;
}

/**
 * Данные, хранящиеся в общем хранилище
 */
export interface IState {
    /**
     * Доступные локали
     *
     * Список дополняется через некоторое время после старта приложения (в файле "src/services/i18n/vue.ts")
     */
    locales: string[];

    /**
     * Данные о текущем пользователе
     */
    user: IUserInfo;

    /**
     * Kato code области акимата
     */
    menuBudget: object[];
    _instanceCode: string;

    keyclockInitialized: boolean;
    keycloakToken: string | null;
    kotlinAuthReady: boolean;

    /**
     *  кнопка Пакетная выгрузка Enabled/Disable
     */
    isDownloadButtonEnabled: boolean;


    /**
     * Состояние и контент плеера видео
     */
    videoPlayer: {
        /**
         * Показан ли плеер
         */
        isOpen: boolean;

        /**
         * Ссылка на видео
         */
        src: string | null;


        /**
         * Название видео
         */

        name: string | null;

        /**
         * Ссылка на embed видео
         */

        embedSrc: string | null;
    };
}


const store = new Vuex.Store<IState>({
    state: {
        locales: ["en"],
        user: {
            login: null,
            authorized: false,
            sub: "",
            preferred_username: "",
            roles: [],
            groupPaths: [],
            groups: undefined,
            userModules: [],
            modulesLoaded: false
        },
        menuBudget: [],
        _instanceCode: "",
        keyclockInitialized: false,
        isDownloadButtonEnabled: false,
        keycloakToken: null,
        kotlinAuthReady: false,
        videoPlayer: {
            isOpen: false,
            src: null,
            name: null,
            embedSrc: null,
        }
    },
    modules: {
        notifications,  // режим уведомления в личном кабинете
    },
    getters: {
        user_uuid(state) {
            return state.user.sub;
        },
        token(state) {
            return state.keycloakToken;
        },
        instanceCode(state) {
            return state._instanceCode;
        },
        userBudgetAbp(state) {
            if (state.user.groups) {
                return getUserGroupItemsByLevel(state, ["budget", "abp"]);
            }
            return [];
        },
        userBudgetGu(state) {
            if (state.user.groups) {
                return getUserGroupItemsByLevel(state, ["budget", "gu"]);
            }
            return [];
        },
        userBudgetLevel(state) {
            if (state.user.groups) {
                return getUserGroupItemsByLevel(state, ["budget", "level"]);
            }
            return [];
        },
        doGroupMap(state): any {
            const nodes = new Map();
            if (state.user.groups) {
                state.user.groups.forEach((group) => {
                    const depp = (group.path.match(new RegExp("/", "g")) || [])
                        .length;
                    const pathSplit = group.path.split("/");
                    let p = "";
                    for (let i = 0; i <= depp; i++) {
                        p = p + pathSplit[i] + "/";
                        const nod = {
                            level: i,
                            path: p,
                            name: pathSplit[i],
                            child: [],
                        };
                        if (!nodes.has(p)) {
                            nodes.set(p, nod);
                        }
                    }
                });
                for (const entry of nodes.entries()) {
                    for (const entry1 of nodes.entries()) {
                        // console.log((entry[0]!==entry1[0]), entry, entry1)
                        if (entry[0] !== entry1[0]) {
                            if (entry[1].level + 1 === entry1[1].level) {
                                entry[1].child.push(entry1[1]);
                                entry1[1].hasParent = true;
                            }
                        }
                    }
                }
                for (const entry of nodes.entries()) {
                    if (entry[1].hasParent) {
                        nodes.delete(entry[0]);
                    }
                }
            }
            return nodes;
        },
        user(state) {
            return state.user;
        },
        videoPlayerState(state) {
            return state.videoPlayer;
        }
    },
    mutations: {
        // Применяет новый список локалей
        setLocales: (state, locales: string[]) => {
            state.locales = locales;
        },

        // Применение новых данных о текущем пользователе
        applyNewUserInfo: (state, info?: ILoadedUserInfo) => {
            // очистка текущих ролей
            if (state.user.roles.isNotEmpty) {
                state.user.roles.splice(0, state.user.roles.length);
            }

            // Очистка или обновление:
            //   логина
            //   ролей
            //   признака "пользователь авторизован"
            if (info === undefined) {
                state.user.login = null;
                state.user.authorized = false;
                state.user.userModules = [];
                state.user.modulesLoaded = false;
            } else {
                state.user.login = info.login;
                state.user.sub = info.sub;
                state.user.preferred_username = info.preferredUsername;
                state.user.roles.push(...info.roles);
                state.user.authorized = true;
                state.user.groups = info.groups;
                state.user.userModules = info.userModules;
                state.user.modulesLoaded = info.modulesLoaded;
            }
        },
        setInstanceCode: (state, code: string) => {
            state._instanceCode = code;
        },
        // getMenuBudget(state) {
        //     // if (isNotEmpty(store.state.user.sub)) {
        //     //     if (state.menuBudget.length < 2) {
        //     //         fetch('/api-py/get-user-menu-budget/' + store.state.user.sub)
        //     //             .then(response => response.json())
        //     //             .then(data => {
        //     //                 const res: any[] = [];
        //     //                 for (const dat of data) {
        //     //                     if (!dat.href.includes('level')) {
        //     //                         res.push(dat);
        //     //                     }
        //     //                 }
        //     //                 (state.menuBudget = res);
        //     //             });
        //     //     }
        //     // }
        // },
        setKeyclockInitialized: (state, keyclockInitialized: boolean) => {
            state.keyclockInitialized = keyclockInitialized;
        },
        setKeycloakToken: (state, token: unknown) => {
            if (typeof token === "string") {
                state.keycloakToken = token;
            } else {
                state.keycloakToken = null;
            }
        },
        setKotlinAuthReady: (state, kotlinAuthReady: boolean) => {
            state.kotlinAuthReady = kotlinAuthReady;
        },
        setIsDownloadButtonEnabled: (state) => {
            state.isDownloadButtonEnabled = !state.isDownloadButtonEnabled;
        },
        setVideoPlayerState: (state, body: any) => {
            state.videoPlayer = body;
        },
    },
    actions: {
        // выход пользователя (запрос на сервер)
        logout() { auth.logout(); },
        updatePassword() { auth.updatePassword(); },
    },
});
export default store;
export const state: IState = store.state;

// region Registering watchers for "auth" service
store.watch(
    () => (auth.initialized),
    () => {
        store.commit('setKeyclockInitialized', auth.initialized);

        if (!auth.initialized) return;

        // auth.user => applyNewUserInfo (mutation)
        store.watch(
            () => (auth.user),
            (value) => {
                const copy: ILoadedUserInfo = {
                    login: value.login,
                    groups: [...value.groups],
                    modulesLoaded: value.modulesLoaded,
                    sub: value.sub,
                    roles: [...value.roles],
                    preferredUsername: value.preferredUsername,
                    userModules: [...value.modules],
                };
                store.commit('applyNewUserInfo', copy);
            },
            { immediate: true },
        );

        // auth.instanceCode => setInstanceCode (mutation)
        store.watch(
            () => (auth.instanceCode),
            (value) => { store.commit('setInstanceCode', value); },
            { immediate: true },
        );

        // auth.keycloakToken => setKeycloakToken (mutation)
        store.watch(
            () => (auth.keycloakToken),
            (value) => { store.commit('setKeycloakToken', value); },
            { immediate: true },
        );

        // auth.kotlinAuthReady => setKotlinAuthReady (mutation)
        store.watch(
            () => (auth.kotlinAuthReady),
            (value) => { store.commit('setKotlinAuthReady', value); },
            { immediate: true },
        );
    },
    { immediate: true },
);
// endregion

export const storeActions = {
    /**
     * Запуск процесса "выход пользователя"
     *
     * На сервер отправляется запрос, после завершения запроса данные текущего пользователя очищаются
     */
    logout() { store.dispatch("logout"); },

    /**
     * Запуск смены пароля текущего пользователя
     */
    updatePassword() { store.dispatch("updatePassword"); },
};

const getUserGroupItemsByLevel = (state: IState, levels: string[]) => {
    const res: any[] = [];
    let path = "/";
    levels.forEach(function (item) {
        path = path + item + "/";
    });
    if (state.user.groups) {
        state.user.groups.forEach(function (item) {
            if (item.path.startsWith(path)) {
                if (!isNaN(parseInt(item.name))) {
                    res.push(parseInt(item.name));
                } else {
                    res.push(item.name);
                }
            }
        });
        return res;
    }
    return [];
};
