import vue from 'vue';
import otherService from '@/services/i18n';
import Ax from '@/utils/ax';
import I18nDbCompiledTemplate from './I18nDbCompiledTemplate';


export namespace I18nDb {
    export namespace Validation {
        export interface Range {
            readonly start: number;
            readonly end: number;
        }

        export interface ErrorInfo {
            readonly en: string;
            readonly kk: string;
            readonly ru: string;
            readonly range: Range;
        }
    }

    export type CompiledArgType = 'ANY' | 'DATE' | 'NUMBER';

    export interface LoadedData {
        firstDayOfWeek: number;

        format24h: boolean;
        am: string;
        pm: string;

        monthNames: Array<string>;
        monthShortNames: Array<string>;
        monthDayTexts: Array<string>;
        monthDayShortTexts: Array<string>;

        weekDayNames: Array<string>;
        weekDayShortNames: Array<string>;

        dateTemplateLong: string;
        dateTemplateShort: string;
        dateTemplateNumeric: string;
        timeTemplateNoSeconds: string;
        timeTemplateWithSeconds: string;
        dateTimeTemplate: string;

        numberSeparatorFraction: string;
        numberSeparatorThousands: string;

        templateMap: Record<string, string | undefined>;
    }
}


const observableState = vue.observable<{
    loaded: boolean,
    loadedData: I18nDb.LoadedData,
}>({
    loaded: false,
    loadedData: {
        firstDayOfWeek: 0,

        format24h: true,
        am: 'AM',
        pm: 'PM',

        monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        monthShortNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        monthDayTexts: ['January {0}', 'February {0}', 'March {0}', 'April {0}', 'May {0}', 'June {0}', 'July {0}', 'August {0}', 'September {0}', 'October {0}', 'November {0}', 'December {0}'],
        monthDayShortTexts: ['{0} Jan', '{0} Feb', '{0} Mar', '{0} Apr', '{0} May', '{0} Jun', '{0} Jul', '{0} Aug', '{0} Sep', '{0} Oct', '{0} Nov', '{0} Dec'],

        weekDayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        weekDayShortNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],

        dateTemplateLong: '{0;date;month-day;long}, {0;date;year;numeric}',
        dateTemplateShort: '{0;date;month-day;short}, {0;date;year;numeric}',
        dateTemplateNumeric: '{0;date;month;2-digit}/{0;date;day;2-digit}/{0;date;year;numeric}',
        timeTemplateNoSeconds: '{0;time;ampm} {0;time;hour;numeric}:{0;time;minute;2-digit}',
        timeTemplateWithSeconds: '{0;time;ampm} {0;time;hour;numeric}:{0;time;minute;2-digit}:{0;time;second;2-digit}',
        dateTimeTemplate: '{0}, {1}',

        numberSeparatorFraction: '.',
        numberSeparatorThousands: ',',

        templateMap: {},
    },
});


interface StatePart {
    compiledTemplateMapByTemplate: Map<string, I18nDbCompiledTemplate>,
    compiledTemplateMapByKey: Map<string, I18nDbCompiledTemplate>,
}

interface State {
    useTmp: boolean;
    normal: StatePart;
    tmp: StatePart;
    tmpLoadedData: I18nDb.LoadedData | undefined;
}

const state: State = {
    useTmp: false,
    normal: {
        compiledTemplateMapByTemplate: new Map(),
        compiledTemplateMapByKey: new Map(),
    },
    tmp: {
        compiledTemplateMapByTemplate: new Map(),
        compiledTemplateMapByKey: new Map(),
    },
    tmpLoadedData: undefined,
};

const useStatePart = <T>(block: (part: StatePart) => T): T => {
    let part: StatePart;
    if (state.useTmp) {
        part = state.tmp;
    } else {
        part = state.normal;
    }

    return block(part);
};

const useLoadedData = <T>(block: (loadedData: I18nDb.LoadedData) => T): T => {
    let loadedData: I18nDb.LoadedData;
    if (state.useTmp) {
        loadedData = state.tmpLoadedData!!;
    } else {
        loadedData = observableState.loadedData;
    }
    return block(loadedData);
};

const getCompiledTemplate = (template: string): I18nDbCompiledTemplate => {
    const templateMap: Map<string, I18nDbCompiledTemplate> = useStatePart((part) => (part.compiledTemplateMapByTemplate));

    if (templateMap.has(template)) {
        return templateMap.get(template)!!;
    } else {
        const newCompiledTemplate = I18nDbCompiledTemplate.create(undefined, template, template);
        templateMap.set(template, newCompiledTemplate);
        return newCompiledTemplate;
    }
};


const constants = {
    KEY_PREFIX__NAME__MONTH_DAY: 'KEY_PREFIX__NAME__MONTH_DAY',
    KEY_PREFIX__NAME__MONTH_DAY_SHORT: '_.date-time.name.month-day-short.',

    KEY_PREFIX__DATE_TIME__TEMPLATE__DATE: '_.date-time.template.date.',
    KEY_PREFIX__DATE_TIME__TEMPLATE__TIME: '_.date-time.template.time.',
    KEY__DATE_TIME__TEMPLATE__DATE_TIME: '_.date-time.template.date-time',
};
Object.freeze(constants);

const service = {
    get locale(): string {
        return otherService.locale;
    },

    translateByTemplate(template: string, ...args: Array<unknown>): string {
        const compiledTemplate = getCompiledTemplate(template);
        return compiledTemplate.toString(...args);
    },

    getTemplateByKey(key: string): string {
        const template = useLoadedData((loadedData) => (loadedData.templateMap[key]));
        if (template === undefined) {
            console.warn(`I18n-DB: Cannot find template with key "${key}"`);
            return `[${key}]`;
        } else {
            return template;
        }
    },
    hasKey(key: string): boolean {
        if (observableState.loaded) {
            return useLoadedData((loadedData) => (loadedData.templateMap[key] !== undefined));
        } else {
            return false;
        }
    },
    translateByKey(key: string, ...args: Array<unknown>): string {
        const templateMap: Map<string, I18nDbCompiledTemplate> = useStatePart((part) => (part.compiledTemplateMapByKey));

        let compiledTemplate: I18nDbCompiledTemplate;
        if (templateMap.has(key)) {
            compiledTemplate = templateMap.get(key)!!;
        } else {
            const template = this.getTemplateByKey(key);
            const newCompiledTemplate = getCompiledTemplate(template);
            templateMap.set(template, newCompiledTemplate);
            compiledTemplate = newCompiledTemplate;
        }

        return compiledTemplate.toString(...args);
    },

    monthName(month: number): string {
        return useLoadedData((loadedData) => (loadedData.monthNames[month]));
    },
    monthShortName(month: number): string {
        return useLoadedData((loadedData) => (loadedData.monthShortNames[month]));
    },
    monthDayTemplate(month: number): string {
        return useLoadedData((loadedData) => (loadedData.monthDayTexts[month]));
    },
    monthDayShortTemplate(month: number): string {
        return useLoadedData((loadedData) => (loadedData.monthDayShortTexts[month]));
    },
    weekDay(weekDay: number): string {
        return useLoadedData((loadedData) => (loadedData.weekDayNames[weekDay]));
    },
    weekDayShort(weekDay: number): string {
        return useLoadedData((loadedData) => (loadedData.weekDayShortNames[weekDay]));
    },
    dateTemplateLong(): string {
        return useLoadedData((loadedData) => (loadedData.dateTemplateLong));
    },
    dateTemplateShort(): string {
        return useLoadedData((loadedData) => (loadedData.dateTemplateShort));
    },
    dateTemplateNumeric(): string {
        return useLoadedData((loadedData) => (loadedData.dateTemplateNumeric));
    },

    format24h(): boolean {
        return useLoadedData((loadedData) => (loadedData.format24h));
    },
    am(): string {
        return useLoadedData((loadedData) => (loadedData.am));
    },
    pm(): string {
        return useLoadedData((loadedData) => (loadedData.pm));
    },
    timeTemplateNoSeconds(): string {
        return useLoadedData((loadedData) => (loadedData.timeTemplateNoSeconds));
    },
    timeTemplateWithSeconds(): string {
        return useLoadedData((loadedData) => (loadedData.timeTemplateWithSeconds));
    },

    dateTimeTemplate(): string {
        return useLoadedData((loadedData) => (loadedData.dateTimeTemplate));
    },

    numberSeparatorFraction(): string {
        return useLoadedData((loadedData) => (loadedData.numberSeparatorFraction));
    },
    numberSeparatorThousands(): string {
        return useLoadedData((loadedData) => (loadedData.numberSeparatorThousands));
    },


    usingTemporary<T>(loadedData: I18nDb.LoadedData, block: () => T): T {
        try {
            state.tmpLoadedData = loadedData;
            state.useTmp = true;
            return block();
        } finally {
            state.useTmp = false;
            state.tmpLoadedData = loadedData;
        }
    },
};


let loadingId = Symbol('loading');

const load = (locale: string) => {
    const currentLoadingId = Symbol('loading');
    loadingId = currentLoadingId;

    Ax<I18nDb.LoadedData>(
        { url: `/api/i18n-db/data?locale=${locale}`, },
        (data) => {
            if (loadingId === currentLoadingId) {
                observableState.loadedData = data;
                observableState.loaded = true;

                [state.normal, state.tmp].forEach((part) => {
                    part.compiledTemplateMapByTemplate.clear();
                    part.compiledTemplateMapByKey.clear();
                });
            }
        },
        (error, reason) => {
            console.error(`I18n-DB: cannot load translates - ${error}`);
        },
    );
};

setTimeout(() => {
    otherService.addLocaleChangeListener((newLocale, oldLocale) => {
        load(newLocale);
    });

    load(otherService.locale);
});


export default service;
export { I18nDbCompiledTemplate, constants };