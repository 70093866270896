// настройки навигации

import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// end - мусорка для параметров киклок; directLink - прямая ссылка (чтобы отключить defaultProps)
const formsParams = '/:curYear?/:year?/:dataType?/:gr?/:abp?/:prg?/:ppr?/:gu?/:variant_uuid?/:spf?/:gkkp?/:mode?/:directLink?/:end?';

// @ts-ignore
export default new Router({
    mode: 'hash',
    routes: [
        /*
            Дочерние компоненты, описанные ниже, будут отображаться
            только внутри тэга <router-view/>,
            добавленного в родительском компоненте
            (в данном случае этот тег должен быть в '@/modules/plashki/Index.vue')
            */
        // {
        //    path: '/', // путь, который будет отображаться в браузере
        //    name: 'main-test', // хз зачем надо
        //    component: () => import(/* webpackChunkName: "about" */ '@/modules/mainpage/Index.vue'),
        //    meta: {
        //        breadcrumb: 'Main Page',
        //        translate: 'app.links.main'
        //    }
        // },
        // {
        //     path: '/auth',
        //     name: 'auth',
        //     component: () => import(/* webpackChunkName: "auth" */ '@/modules/auth/auth.vue'),
        //     meta: {
        //         breadcrumb: 'Authentication'
        //     }
        // },
        {
            path: '/',
            name: 'home',
            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/mainpage/home@.vue'),
            meta: {
                breadcrumb: 'home'
            }
        },
        {
            path: '/main',
            name: 'main',
            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/mainpage/main.vue'),
            meta: {
                breadcrumb: 'main'
            }
        },
        {
            path: '/soc-eco',
            name: 'soc-eco',
            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/soc-eco/soc-eco@.vue'),
            meta: {
                breadcrumb: 'soc-eco'
            }
        },
        {
            path: '/soc-mod',
            name: 'soc-mod',
            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/soc-mod/soc-mod@.vue'),
            meta: {
                breadcrumb: 'soc-mod'
            }
        },
        {
            path: '/socio',
            name: 'socio',
            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/socio/socio@.vue'),
            meta: {
                breadcrumb: 'socio',
                translate: 'app.links.main'
            }
        },
        {
            path: '/mainpage',
            name: 'mainpage',
            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/mainpage/Index.vue'),
            meta: {
                breadcrumb: 'mainpage'
            }
        },
        {
            path: '/plashki',
            name: 'plashki',
            component: () => import(/* webpackChunkName: "plashki" */ '@/modules/plashki/Index.vue'),
            meta: {
                breadcrumb: 'Plashki',
                translate: 'app.links.plashki'
            }
        },
        {
            path: '/widget-yard',
            name: 'widget-yard',
            component: () => import(/* webpackChunkName: "auth" */ '@/modules/widget-yard/Index.vue'),
            meta: {
                breadcrumb: 'Widget yard'
            }
        },
        {
            path: '/astanamap',
            name: 'astanamap',
            component: () => import(/* webpackChunkName: "astanamap" */ '@/modules/ymap/astanamap.vue'),
            meta: {
                breadcrumb: 'AstanaMap',
                translate: 'app.links.astanamap'
            }
        },
        {
            path: '/media',
            name: 'media',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "media" */ '@/modules/media/Index.vue'),
            meta: {
                breadcrumb: 'Media',
                translate: 'app.links.media'
            }
        },
        {
            path: '/socialnet',
            name: 'socialnet',
            component: () => import(/* webpackChunkName: "socialnet" */ '@/modules/socialnet/Index.vue'),
            meta: {
                breadcrumb: 'Socialnet',
                translate: 'app.links.socialnet'
            }
        },
        {
            path: '/budget',
            name: 'budget',
            component: () => import(/* webpackChunkName: "budget" */ '@/modules/budget/index.ts'),
            meta: {
                breadcrumb: 'Budget',
                translate: 'app.links.budget'
            }
        },
        {
            path: '/budget-new',
            name: 'budget-new',
            component: () => import(/* webpackChunkName: "budget" */ '@/components/Budjet.vue'),
            meta: {
                breadcrumb: 'Budget-new'
            }
        },
        {
            path: '/news-monitor',
            name: 'news-monitor',
            component: () => import(/* webpackChunkName: "news-monitor" */ '@/modules/news-monitor/index.ts'),
            meta: {
                breadcrumb: 'news-monitor'
            }
        },
        {
            path: '/ui-playground',
            name: 'ui-playground',
            component: () => import(/* webpackChunkName: "ui-playground" */ '@/modules/ui-playground/Index.vue'),
            meta: {
                breadcrumb: 'ui-playground'
            }
        },
        {
            path: '/ui-playground-temp',
            name: 'ui-playground-temp',
            component: () => import(/* webpackChunkName: "ui-playground-temp" */ '@/modules/ui-playground/Temp.vue'),
            meta: {
                breadcrumb: 'ui-playground-temp'
            }
        },
        {
            path: '/requests',
            name: 'requests',
            component: () => import(/* webpackChunkName: "requests" */ '@/modules/requests/Index.vue'),
            meta: {
                breadcrumb: 'requests'
            }
        },
        {
            path: '/test',
            name: 'test',
            component: () => import(/* webpackChunkName: "test" */ '@/modules/test/Index.vue'),
            meta: {
                breadcrumb: 'Test'
            }
        },
        {
            path: '/program-monitor2',
            name: 'program-monitor2',
            component: () => import(/* webpackChunkName: "news-monitor" */ '@/modules/program-monitor2/index.ts'),
            meta: {
                breadcrumb: 'program-monitor2'
            }
        },
        {
            path: '/program-link-list',
            name: 'program-link-list',
            component: () => import(/* webpackChunkName: "news-monitor" */ '@/modules/program-monitor2/ProgramLinkList.vue')
        },
        {
            path: '/design-estimate-documentation',
            name: 'design-estimate-documentation',
            component: () => import(/* webpackChunkName: "news-monitor" */ '@/modules/design-estimate-documentation/Index.vue')
        },
        {
            path: '/budget-analytics',
            name: '/budget-analytics',
            component: () => import(/* webpackChunkName: "news-monitor" */ '@/modules/budget-analytics/Index.vue')
        },
        {
            path: '/budget-execution-analytics',
            name: '/budget-execution-analytics',
            component: () => import(/* webpackChunkName: "news-monitor" */ '@/modules/budget-execution-analytics/Index.vue')
        },
        {
            path: '/program-card',
            name: 'program-card',
            component: () => import(/* webpackChunkName: "news-monitor" */ '@/modules/program-card/index.ts'),
            meta: {
                breadcrumb: 'program-card'
            }
        },
        {
            path: '/budget-loader',
            name: 'budget-loader',
            component: () => import(/* webpackChunkName: "news-monitor" */ '@/modules/budget/BudgetLoader.vue'),
            meta: {
                breadcrumb: 'budget-loader'
            }
        },
        {
            path: '/report-for-sep',
            name: 'report-for-sep',
            component: () => import('@/modules/report-for-sep/report-for-sep.vue')
        },
        {
            path: '/program-monitor-test',
            name: 'program-monitor-test',
            component: () => import(/* webpackChunkName: "news-monitor" */ '@/modules/program-monitor-test/index.ts'),
            meta: {
                breadcrumb: 'program-monitor-test'
            }
        },
        {
            path: '/budget-news',
            name: 'budget-news',
            component: () => import(/* webpackChunkName: "news-monitor" */ '@/modules/budget/BudgetView.vue'),
            meta: {
                breadcrumb: 'budget-news'
            }
        },
        {
            path: '/i18n-editor',
            name: 'i18n-editor',
            component: () => import(/* webpackChunkName: "news-monitor" */ '@/modules/i18n/I18nEditor.vue'),
            meta: {
                breadcrumb: 'i18n-editor'
            }
        },
        {
            path: '/budget-limit',
            name: 'budget-limit',
            component: () => import('@/modules/budget/BudgetLimit.vue'),
            meta: {
                breadcrumb: 'budget-limit'
            }
        },
        {
            path: '/budget-limit2',
            name: 'budget-limit2',
            component: () => import('@/modules/budget/BudgetLimit2.vue'),
            meta: {
                breadcrumb: 'budget-limit2'
            }
        },
        {
            path: '/budget-form',
            name: 'budget-form',
            component: () => import('@/modules/budget/BudgetForm.vue'),
            meta: {
                breadcrumb: 'budget-form'
            },
            children: [
                {
                    path: 'concl-bp/:region?/:planYear?/:abp?/:variant?/:prg?',
                    name: 'budget-form-concl-bp',
                    component: () => import('@/modules/budget-request/budget-program.vue'),
                    props: { conclMode: true }
                }
            ]
        },
        {
            path: '/budget-clarify-old',
            name: 'budget-clarify-old',
            component: () => import('@/modules/budget/BudgetClarify.vue'),
            meta: {
                breadcrumb: 'budget-clarify-old'
            }
        },
        {
            path: '/budget-clarify-old',
            name: 'budget-clarify-old',
            component: () => import('@/modules/budget-clarify/BudgetClarify.vue'),
            meta: {
                breadcrumb: 'budget-clarify-old'
            }
        },
        {
            path: '/budget-clarify',
            name: 'budget-clarify',
            component: () => import('@/modules/budget/budget-correct/BudgetClarifyMain.vue'),
            meta: {
                breadcrumb: 'budget-clarify'
            }
        },
        {
            path: '/budget-correct-old',
            name: 'budget-correct-old',
            component: () => import('@/modules/budget/BudgetCorrect.vue'),
            meta: {
                breadcrumb: 'budget-correct'
            }
        },
        {
            path: '/budget-correct',
            name: 'budget-correct',
            component: () => import('@/modules/budget/budget-correct/BudgetCorrectMain.vue'),
            meta: {
                breadcrumb: 'budget-correct'
            }
        },
        {
            path: '/budget-report',
            name: 'budget-report',
            component: () => import('@/modules/budget/BudgetReport.vue'),
            meta: {
                breadcrumb: 'budget-report'
            }
        },
        {
            path: '/cost-form',
            name: 'cost-form',
            component: () => import('@/modules/budget/CostForm.vue'),
            meta: {
                breadcrumb: 'cost-form'
            }
        },
        {
            path: '/cost-clarify',
            name: 'cost-clarify',
            component: () => import('@/modules/budget/CostClarify.vue'),
            meta: {
                breadcrumb: 'cost-clarify'
            }
        },
        {
            path: '/cost-correct',
            name: 'cost-correct',
            component: () => import('@/modules/budget/CostCorrect.vue'),
            meta: {
                breadcrumb: 'cost-correct'
            }
        },
        {
            path: '/income-form',
            name: 'income-form',
            component: () => import('@/modules/budget/IncomeForm.vue'),
            meta: {
                breadcrumb: 'income-form'
            }
        },
        {
            path: '/income-clarify',
            name: 'income-clarify',
            component: () => import('@/modules/budget/IncomeClarify.vue'),
            meta: {
                breadcrumb: 'income-clarify'
            }
        },
        {
            path: '/income-correct',
            name: 'income-correct',
            component: () => import('@/modules/budget/IncomeCorrect.vue'),
            meta: {
                breadcrumb: 'income-correct'
            }
        },

        {
            path: '/adjust-report',
            name: 'adjust-report',
            component: () => import('@/modules/budget/reports/AdjustReport.vue'),
            meta: {
                breadcrumb: 'adjust-report'
            }
        },
        {
            path: '/approv-report',
            name: 'approv-report',
            component: () => import('@/modules/budget/reports/ApprovReport.vue'),
            meta: {
                breadcrumb: 'approv-report'
            }
        },
        {
            path: '/clarify-report',
            name: 'clarify-report',
            component: () => import('@/modules/budget/reports/ClarifyReport.vue'),
            meta: {
                breadcrumb: 'clarify-report'
            }
        },
        {
            path: '/adjust-report',
            name: 'adjust-report',
            component: () => import('@/modules/budget/reports/AdjustReport.vue'),
            meta: {
                breadcrumb: 'adjust-report'
            }
        },
        {
            path: '/approv-report',
            name: 'approv-report',
            component: () => import('@/modules/budget/reports/ApprovReport.vue'),
            meta: {
                breadcrumb: 'approv-report'
            }
        },
        {
            path: '/clarify-report',
            name: 'clarify-report',
            component: () => import('@/modules/budget/reports/ClarifyReport.vue'),
            meta: {
                breadcrumb: 'clarify-report'
            }
        },
        {
            path: `/form01-111${formsParams}`,
            name: 'form01-111',
            component: () => import('@/modules/budget-request/Form01_111.vue'),
            meta: {
                breadcrumb: 'form01-111'
            }
        },
        {
            path: `/form02-111${formsParams}`,
            name: 'form02-111',
            component: () => import('@/modules/budget-request/Form02_111.vue'),
            meta: {
                breadcrumb: 'form02-111'
            }
        },
        {
            path: `/form01-123${formsParams}`,
            name: 'form01-123',
            component: () => import('@/modules/budget-request/Form01_123.vue'),
            meta: {
                breadcrumb: 'form01-123'
            }
        },
        {
            path: `/form01-339${formsParams}`,
            name: 'form01-339',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form01_339.vue'),
            meta: {
                breadcrumb: 'form01-339'
            }
        },
        {
            path: `/form02-324${formsParams}`,
            name: 'form02-324',
            component: () => import('@/modules/budget-request/Form02_324.vue'),
            meta: {
                breadcrumb: 'form02-324'
            }
        },
        {
            path: `/form02-123${formsParams}`,
            name: 'form02-123',
            component: () => import('@/modules/budget-request/Form02_123.vue'),
            meta: {
                breadcrumb: 'form02-123'
            }
        },
        {
            path: `/form01-136${formsParams}`,
            name: 'form01-136',
            component: () => import('@/modules/budget-request/Form01_136.vue'),
            meta: {
                breadcrumb: 'form01-136'
            }
        },
        {
            path: `/form01-141${formsParams}`,
            name: 'form01-141',
            component: () => import('@/modules/budget-request/Form01_141.vue'),
            meta: {
                breadcrumb: 'form01-141'
            }
        },
        {
            path: `/form02-141${formsParams}`,
            name: 'form02-141',
            component: () => import('@/modules/budget-request/Form02_141.vue'),
            meta: {
                breadcrumb: 'form02-141'
            }
        },
        {
            path: `/form03-141${formsParams}`,
            name: 'form03-141',
            component: () => import('@/modules/budget-request/Form03_141.vue'),
            meta: {
                breadcrumb: 'form03-141'
            }
        },
        {
            path: `/form04-141${formsParams}`,
            name: 'form04-141',
            component: () => import('@/modules/budget-request/Form04_141.vue'),
            meta: {
                breadcrumb: 'form04-141'
            }
        },
        {
            path: `/form01-142${formsParams}`,
            name: 'form01-142',
            component: () => import('@/modules/budget-request/Form01_142.vue'),
            meta: {
                breadcrumb: 'form01-142'
            }
        },
        {
            path: `/form02-142${formsParams}`,
            name: 'form02-142',
            component: () => import('@/modules/budget-request/Form02_142.vue'),
            meta: {
                breadcrumb: 'form02-142'
            }
        },
        {
            path: `/form03-142${formsParams}`,
            name: 'form03-142',
            component: () => import('@/modules/budget-request/Form03_142.vue'),
            meta: {
                breadcrumb: 'form03-142'
            }
        },
        {
            path: `/form01-143${formsParams}`,
            name: 'form01-143',
            component: () => import('@/modules/budget-request/Form01_143.vue'),
            meta: {
                breadcrumb: 'form01-143'
            }
        },
        {
            path: `/form01-144${formsParams}`,
            name: 'form01-144',
            component: () => import('@/modules/budget-request/Form01_144.vue'),
            meta: {
                breadcrumb: 'form01-144'
            }
        },
        {
            path: `/form01-144-sm${formsParams}`,
            name: 'form01-144-sm',
            component: () => import('@/modules/budget-request/Form01_144_sm.vue'),
            meta: {
                breadcrumb: 'form01-144-sm'
            }
        },
        {
            path: `/form02-144${formsParams}`,
            name: 'form02-144',
            component: () => import('@/modules/budget-request/Form02_144.vue'),
            meta: {
                breadcrumb: 'form02-144'
            }
        },
        {
            path: `/form01-149${formsParams}`,
            name: 'form01-149',
            component: () => import('@/modules/budget-request/Forms.vue'),
            meta: {
                breadcrumb: 'form01-149'
            }
        },
        {
            path: `/form02-149${formsParams}`,
            name: 'form02-149',
            component: () => import('@/modules/budget-request/Forms.vue'),
            meta: {
                breadcrumb: 'form02-149'
            }
        },
        {
            path: `/form03-149${formsParams}`,
            name: 'form03-149',
            component: () => import('@/modules/budget-request/Forms.vue'),
            meta: {
                breadcrumb: 'form03-149'
            },
            children: [
                {
                    path: 'budg',
                    name: 'budg',
                },
                {
                    path: 'paid',
                    name: 'paid',
                },
                {
                    path: '',
                    redirect: 'budg',   
                },
            ],
        },
        {
            path: `/form01-151${formsParams}`,
            name: 'form01-151',
            component: () => import('@/modules/budget-request/Form01_151.vue'),
            meta: {
                breadcrumb: 'form01-151'
            },
        },
        {
            path: `/form01-139${formsParams}`,
            name: 'form01-139',
            component: () => import('@/modules/budget-request/Form01_139.vue'),
            meta: {
                breadcrumb: 'form01-139'
            }
        },
        {
            path: `/form01-134${formsParams}`,
            name: 'form01-134',
            component: () => import('@/modules/budget-request/Form01_134.vue'),
            meta: {
                breadcrumb: 'form01-134'
            }
        },
        {
            path: `/form02-151${formsParams}`,
            name: 'form02-151',
            component: () => import('@/modules/budget-request/Form02_151.vue'),
            meta: {
                breadcrumb: 'form02-151'
            }
        },
        {
            path: `/form03-151${formsParams}`,
            name: 'form03-151',
            component: () => import('@/modules/budget-request/Form03_151.vue'),
            meta: {
                breadcrumb: 'form03-151'
            }
        },
        {
            path: `/form04-151${formsParams}`,
            name: 'form04-151',
            component: () => import('@/modules/budget-request/Form04_151.vue'),
            meta: {
                breadcrumb: 'form04-151'
            },
            children: [
                {
                    path: 'budg',
                    name: 'budg',
                },
                {
                    path: 'paid',
                    name: 'paid',
                },
                {
                    path: '',
                    redirect: 'budg',   
                },
            ],
        },
        {
            path: `/form01-152${formsParams}`,
            name: 'form01-152',
            component: () => import('@/modules/budget-request/Form01_152.vue'),
            meta: {
                breadcrumb: 'form01-152'
            }
        },
        {
            path: `/form01-153${formsParams}`,
            name: 'form01-153',
            component: () => import('@/modules/budget-request/Form01_153.vue'),
            meta: {
                breadcrumb: 'form01-153'
            }
        },
        {
            path: `/form01-159${formsParams}`,
            name: 'form01-159',
            component: () => import('@/modules/budget-request/Form01_159.vue'),
            meta: {
                breadcrumb: 'form01-159'
            }
        },
        {
            path: `/form02-159${formsParams}`,
            name: 'form02-159',
            component: () => import('@/modules/budget-request/Form02_159.vue'),
            meta: {
                breadcrumb: 'form02-159'
            },
            children: [
                {
                    path: 'budg',
                    name: 'budg',
                },
                {
                    path: 'paid',
                    name: 'paid',
                },
                {
                    path: '',
                    redirect: 'budg',   
                },
            ],
        },
        {
            path: `/form158${formsParams}`,
            name: 'form158',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form158.vue'),
            meta: {
                breadcrumb: 'form158'
            }
        },
        {
            path: `/form02-159-1${formsParams}`,
            name: 'form02-159-1',
            component: () => import('@/modules/budget-request/Form02_159_1.vue'),
            meta: {
                breadcrumb: 'form02-159-1'
            }
        },
        {
            path: `/form02-159-2${formsParams}`,
            name: 'form02-159-2',
            component: () => import('@/modules/budget-request/Form02_159_2.vue'),
            meta: {
                breadcrumb: 'form02-159-2'
            }
        },
        {
            path: `/form03-159${formsParams}`,
            name: 'form03-159',
            component: () => import('@/modules/budget-request/Form03_159.vue'),
            meta: {
                breadcrumb: 'form03-159'
            }
        },
        {
            path: `/form01-161${formsParams}`,
            name: 'form01-161',
            component: () => import('@/modules/budget-request/Form01_161.vue'),
            meta: {
                breadcrumb: 'form01-161'
            }
        },
        {
            path: `/form01-169${formsParams}`,
            name: 'form01-169',
            component: () => import('@/modules/budget-request/Form01_169.vue'),
            meta: {
                breadcrumb: 'form01-169'
            },
            children: [
                {
                    path: 'budg',
                    name: 'budg',
                },
                {
                    path: 'paid',
                    name: 'paid',
                },
                {
                    path: '',
                    redirect: 'budg',   
                },
            ],
        },
        {
            path: `/form02-169${formsParams}`,
            name: 'form02-169',
            component: () => import('@/modules/budget-request/Form02_169.vue'),
            meta: {
                breadcrumb: 'form02-169'
            }
        },
        {
            path: `/form01-324${formsParams}`,
            name: 'form01-324',
            component: () => import('@/modules/budget-request/Form01_324.vue'),
            meta: {
                breadcrumb: 'form01-324'
            }
        },
        {
            path: `/form321${formsParams}`,
            name: 'form321',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form321.vue'),
            meta: {
                breadcrumb: 'form321'
            }
        },
        {
            path: `/form133${formsParams}`,
            name: 'form133',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form133.vue'),
            meta: {
                breadcrumb: 'form133'
            }
        },
        {
            path: `/form154${formsParams}`,
            name: 'form154',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form154.vue'),
            meta: {
                breadcrumb: 'form154'
            }
        },
        {
            path: `/form155${formsParams}`,
            name: 'form155',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form155.vue'),
            meta: {
                breadcrumb: 'form155'
            }
        },
        {
            path: `/form156${formsParams}`,
            name: 'form156',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form156.vue'),
            meta: {
                breadcrumb: 'form156'
            }
        },
        {
            path: `/form157${formsParams}`,
            name: 'form157',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form157.vue'),
            meta: {
                breadcrumb: 'form157'
            }
        },
        {
            path: `/form163${formsParams}`,
            name: 'form163',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form163.vue'),
            meta: {
                breadcrumb: 'form163'
            }
        },
        {
            path: `/form165${formsParams}`,
            name: 'form165',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form165.vue'),
            meta: {
                breadcrumb: 'form165'
            }
        },
        {
            path: `/form212${formsParams}`,
            name: 'form212',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form212.vue'),
            meta: {
                breadcrumb: 'form212'
            }
        },
        {
            path: `/form213${formsParams}`,
            name: 'form213',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form213.vue'),
            meta: {
                breadcrumb: 'form213'
            }
        },
        {
            path: `/form311${formsParams}`,
            name: 'form311',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form311.vue'),
            meta: {
                breadcrumb: 'form311'
            }
        },
        {
            path: `/form322${formsParams}`,
            name: 'form322',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form322.vue'),
            meta: {
                breadcrumb: 'form322'
            }
        },
        {
            path: `/form01-322${formsParams}`,
            name: 'form01-322',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form01_322.vue'),
            meta: {
                breadcrumb: 'form01-322'
            }
        },
        {
            path: `/form02-322${formsParams}`,
            name: 'form02-322',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form02_322.vue'),
            meta: {
                breadcrumb: 'form02-322'
            }
        },
        {
            path: `/form331${formsParams}`,
            name: 'form331',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form331.vue'),
            meta: {
                breadcrumb: 'form331'
            }
        },
        {
            path: `/form336${formsParams}`,
            name: 'form336',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form336.vue'),
            meta: {
                breadcrumb: 'form336'
            }
        },
        {
            path: `/form338${formsParams}`,
            name: 'form338',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form338.vue'),
            meta: {
                breadcrumb: 'form338'
            }
        },
        {
            path: `/form02-339${formsParams}`,
            name: 'form02-339',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form02_339.vue'),
            meta: {
                breadcrumb: 'form02-339'
            }
        },
        {
            path: `/form352${formsParams}`,
            name: 'form352',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form352.vue'),
            meta: {
                breadcrumb: 'form352'
            }
        },
        {
            path: `/form412${formsParams}`,
            name: 'form412',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form412.vue'),
            meta: {
                breadcrumb: 'form412'
            }
        },
        {
            path: `/form417${formsParams}`,
            name: 'form417',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form417.vue'),
            meta: {
                breadcrumb: 'form417'
            }
        },
        {
            path: `/form418${formsParams}`,
            name: 'form418',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form418.vue'),
            meta: {
                breadcrumb: 'form418'
            },
            children: [
                {
                    path: 'budg',
                    name: 'budg',
                },
                {
                    path: 'paid',
                    name: 'paid',
                },
                {
                    path: '',
                    redirect: 'budg',   
                },
            ],
        },
        {
            path: `/form419${formsParams}`,
            name: 'form419',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form419.vue'),
            meta: {
                breadcrumb: 'form419'
            }
        },
        {
            path: `/form421${formsParams}`,
            name: 'form421',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form421.vue'),
            meta: {
                breadcrumb: 'form421'
            }
        },
        {
            path: `/form422${formsParams}`,
            name: 'form422',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form422.vue'),
            meta: {
                breadcrumb: 'form422'
            }
        },
        {
            path: `/form423${formsParams}`,
            name: 'form423',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form423.vue'),
            meta: {
                breadcrumb: 'form423'
            }
        },
        {
            path: `/form511${formsParams}`,
            name: 'form511',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form511.vue'),
            meta: {
                breadcrumb: 'form511'
            }
        },
        {
            path: `/form513${formsParams}`,
            name: 'form513',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form513.vue'),
            meta: {
                breadcrumb: 'form513'
            }
        },
        {
            path: `/form514${formsParams}`,
            name: 'form514',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form514.vue'),
            meta: {
                breadcrumb: 'form514'
            }
        },
        {
            path: `/form519${formsParams}`,
            name: 'form519',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form519.vue'),
            meta: {
                breadcrumb: 'form519'
            }
        },
        {
            path: `/form411${formsParams}`,
            name: 'form411',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form411.vue'),
            meta: {
                breadcrumb: 'form411'
            }
        },
        {
            path: `/form612${formsParams}`,
            name: 'form612',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form612.vue'),
            meta: {
                breadcrumb: 'form612'
            }
        },
        {
            path: `/form711${formsParams}`,
            name: 'form711',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form711.vue'),
            meta: {
                breadcrumb: 'form711'
            }
        },
        {
            path: `/form712${formsParams}`,
            name: 'form712',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form712.vue'),
            meta: {
                breadcrumb: 'form712'
            }
        },
        {
            path: `/form714${formsParams}`,
            name: 'form714',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form714.vue'),
            meta: {
                breadcrumb: 'form714'
            }
        },
        {
            path: `/form812${formsParams}`,
            name: 'form812',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form812.vue'),
            meta: {
                breadcrumb: 'form812'
            }
        },
        {
            path: `/form813${formsParams}`,
            name: 'form813',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form813.vue'),
            meta: {
                breadcrumb: 'form813'
            }
        },
        {
            path: `/form814${formsParams}`,
            name: 'form814',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form814.vue'),
            meta: {
                breadcrumb: 'form814'
            }
        },
        {
            path: `/form815${formsParams}`,
            name: 'form815',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form815.vue'),
            meta: {
                breadcrumb: 'form815'
            }
        },
        {
            path: `/form332${formsParams}`,
            name: 'form332',
            component: () => import('@/modules/budget-request/arbitrary-forms/Form332.vue'),
            meta: {
                breadcrumb: 'form332'
            }
        },
        {
            path: `/form01-413${formsParams}`,
            name: 'form01-413',
            component: () => import('@/modules/budget-request/Form01_413.vue'),
            meta: {
                breadcrumb: 'form01-413'
            }
        },
        {
            path: `/form01-414${formsParams}`,
            name: 'form01-414',
            component: () => import('@/modules/budget-request/Form01_414.vue'),
            meta: {
                breadcrumb: 'form01-414'
            }
        },
        {
            path: `/form01-416${formsParams}`,
            name: 'form01-416',
            component: () => import('@/modules/budget-request/Form01_416.vue'),
            meta: {
                breadcrumb: 'form01-416'
            }
        },
        {
            path: `/form02-414${formsParams}`,
            name: 'form02-414',
            component: () => import('@/modules/budget-request/Form02_414.vue'),
            meta: {
                breadcrumb: 'form02-414'
            }
        },
        {
            path: `/form01-162${formsParams}`,
            name: 'form01-162',
            component: () => import('@/modules/budget-request/Form01_162.vue'),
            meta: {
                breadcrumb: 'form01-162'
            }
        },
        {
            path: '/budget-dicts',
            name: 'budget-dicts',
            component: () => import('@/modules/budget/EbkDicts.vue'),
            meta: {
                breadcrumb: 'budget-dicts'
            }
        },
        {
            path: '/budget-versions',
            name: 'budget-versions',
            component: () => import('@/modules/budget/BudgetVariants.vue'),
            meta: {
                breadcrumb: 'budget-variants'
            }
        },
        {
            path: '/budget-limits',
            name: 'budget-limits',
            component: () => import('@/modules/budget-limitCosts/BudgetLimits.vue'),
            meta: {
                breadcrumb: 'budget-limits'
            }
        },
        {
            path: '/budget-limit-expenses',
            name: 'budget-limit-expenses',
            component: () => import('@/modules/budget-limitCosts/limitExpenses/BudgetLimitsExpenses.vue'),
            meta: {
                breadcrumb: 'budget-limit-expenses'
            }
        },
        {
            path: '/forecast-edit-test',
            name: 'forecast-edit-test',
            component: () => import('@/modules/forecast/forecastEditTest.vue')
        },
        {
            path: '/form-program-modules',
            name: 'form-program-modules',
            component: () => import('@/modules/development-program/formOfProgramModules.vue')
        },
        {
            path: '/passport-program',
            name: 'passport-program',
            component: () => import('@/modules/development-program/PassportOfProgram.vue'),
            meta: {
                breadcrumb: 'passport-program'
            }
        },
        {
            path: '/budget-limits-second',
            name: 'budget-limits-second',
            component: () => import('@/modules/budget-limitCosts/BudgetLimitSecond.vue'),
            meta: {
                breadcrumb: 'budget-limits-second'
            }
        },
        {
            path: '/forecast-form',
            name: 'forecast-form',
            component: () => import('@/modules/forecast/ForecastForm.vue'),
            meta: {
                breadcrumb: 'forecast-form'
            }
        },
        {
            path: '/forecast-target-form',
            name: 'forecast-target-form',
            component: () => import('@/modules/forecast/ForecastFormNew.vue'),
            meta: {
                breadcrumb: 'forecast-target-form'
            }
        },
        {
            path: '/target-indicators',
            name: 'target-indicators',
            component: () => import('@/modules/forecast/targetIndicators.vue'),
            meta: {
                breadcrumb: 'target-indicators'
            }
        },
        {
            path: '/target-indicator',
            name: 'target-indicator',
            component: () => import('@/modules/forecast/TargetIndicator.vue'),
            meta: {
                breadcrumb: 'target-indicator'
            }
        },
        {
            path: '/intercon-target-indicator',
            name: 'intercon-target-indicator',
            component: () => import('@/modules/forecast/interconTargetIndicator.vue'),
            meta: {
                breadcrumb: 'intercon-target-indicator'
            }
        },
        {
            path: '/intercon-forecast',
            name: 'intercon-forecast',
            component: () => import('@/modules/forecast/interconnection.vue'),
            meta: {
                breadcrumb: 'intercon-forecast'
            }
        },
        {
            path: '/initial-form',
            name: 'initial-form',
            component: () => import('@/modules/development-program/initialForm.vue'),
            meta: {
                breadcrumb: 'initial-form'
            }
        },
        {
            path: '/exp-note',
            name: 'exp-note',
            component: () => import('@/modules/explanatory-note/initialFormOfNote.vue'),
            meta: {
                breadcrumb: 'exp-note',
                rootClasses: ['no-tabs']
            }
        },
        {
            path: '/filter-forms',
            name: 'filter-forms',
            component: () => import('@/modules/development-program/filterForm.vue'),
            meta: {
                breadcrumb: 'filter-forms'
            }
        },
        {
            path: '/monitoring-snp',
            name: 'monitoring-snp',
            component: () => import('@/modules/monitoring-snp/monitoring-snp.vue')
        },
        {
            path: '/examples',
            name: 'examples',
            component: () => import('@/modules/examples/examples.vue')
        },
        {
            path: '/budget-sum-calc',
            name: 'budget-sum-calc',
            component: () => import('@/modules/budget-request/budget-sum-calc.vue')
        },
        {
            path: '/budget-sum-calc-prog',
            name: 'budget-sum-calc-prog',
            component: () => import('@/modules/budget-request/budget-sum-calc-prog.vue')
        },
        {
            path: '/budget-gkkp',
            name: 'budget-gkkp',
            component: () => import('@/modules/budget-request/budget-gkkp/budget-gkkp.vue')
        },
        {
            path: '/mm-data',
            name: 'mm-data',
            component: () => import(/* webpackChunkName: "my-page" */ '@/components/MmData.vue'),
            meta: {
                breadcrumb: 'mm-data'
            }
        },
        {
            path: '/forms',
            name: 'forms',
            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/forms/index.ts'),
            meta: {
                breadcrumb: 'forms'
            }
        },

        {
            path: '/snp',
            name: 'snp',
            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/sgp/snp.vue'),
            meta: {
                breadcrumb: 'snp'
            }
        },

        {
            path: '/info-servers',
            name: 'info-servers',
            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/info/Index.vue'),
            meta: {
                breadcrumb: 'info'
            }
        },
        // Штатное расписание
        {
            path: '/staffing-table',
            component: () => import(/* webpackChunkName: "staffing-table" */ '@/modules/budget/staffing-table/Page.vue'),
            children: [
                // Настройки
                {
                    path: 'config',
                    component: () => import(/* webpackChunkName: "staffing-table--config" */ '@/modules/budget/staffing-table/config/Page.vue')
                },

                // Данные штатного расписания
                {
                    path: 'data',
                    component: () => import(/* webpackChunkName: "staffing-table--data" */ '@/modules/budget/staffing-table/data/Page.vue')
                },

                // Отделы ГУ
                {
                    path: 'departments',
                    component: () => import(/* webpackChunkName: "staffing-table--departments" */ '@/modules/budget/staffing-table/departments/Page.vue')
                },

                // Должности в отделах ГУ
                {
                    path: 'department-positions',
                    component: () => import(/* webpackChunkName: "staffing-table--department-positions" */ '@/modules/budget/staffing-table/department-positions/Page.vue')
                },

                // Старые отчеты версии 1 (список)
                {
                    path: 'reports',
                    component: () => import(/* webpackChunkName: "staffing-table--old-v1-reports" */ '@/modules/budget/staffing-table/old-v1-reports/Page.vue')
                },

                // Старый отчет 1 версии
                {
                    path: 'report/:id',
                    component: () => import(/* webpackChunkName: "staffing-table--old-v1-reports--report" */ '@/modules/budget/staffing-table/old-v1-reports/Report.vue')
                },

                // Старый отчет 1 версии (минорная версия 2)
                {
                    path: 'report-2/:id',
                    component: () => import(/* webpackChunkName: "staffing-table--old-v1-reports--report-2" */ '@/modules/budget/staffing-table/old-v1-reports/Report2.vue')
                },

                //  // Отчеты новый генератор
                {
                    path: 'reports-v2',
                    component: () => import(/* webpackChunkName: "staffing-table--reports" */ '@/modules/budget/staffing-table/reports/Page.vue')
                },

                {
                    path: 'report-v2/:id',
                    component: () => import(/* webpackChunkName: "staffing-table--reports--report" */ '@/modules/budget/staffing-table/reports/ReportPage.vue')
                },

                //  // Отчеты новый генератор
                {
                    path: 'report-v2/:id/:year',
                    component: () => import(/* webpackChunkName: "staffing-table--reports--report" */ '@/modules/budget/staffing-table/reports/ReportPage.vue')
                },

                // Хранилище файлов
                {
                    path: 'files-storage',
                    component: () => import(/* webpackChunkName: "staffing-table--files-storage" */ '@/modules/budget/staffing-table/files-storage/Page.vue')
                },

                // Версии
                {
                    path: 'versions',
                    component: () => import(/* webpackChunkName: "staffing-table--versions" */ '@/modules/budget/staffing-table/versions/Page.vue'),
                },
            ]
        },

        // Исполнение бюджета (для демо)
        {
            path: '/demo-budget-execution',
            component: () => import('@/modules/budget/demo-budget-execution/Index.vue'),
            children: [
                // Главная
                {
                    path: '',
                    component: () => import('@/modules/budget/demo-budget-execution/Home.vue')
                },
                {
                    path: 'modeling',
                    component: () => import('@/modules/budget/demo-budget-execution/Modelling.vue')
                },
                {
                    path: 'target-dict',
                    component: () => import('@/modules/budget/demo-budget-execution/target-indicators/TargetDict.vue')
                },

                // Целевые индикаторы
                {
                    path: 'target-indicators',
                    component: () => import('@/modules/budget/demo-budget-execution/target-indicators/Index.vue'),
                    children: [
                        // Декомпозиция
                        {
                            path: 'decomposition',
                            component: () => import('@/modules/budget/demo-budget-execution/target-indicators/Decomposition.vue')
                        },
                        // Прогноз
                        {
                            path: 'prognosis',
                            component: () => import('@/modules/budget/demo-budget-execution/target-indicators/Prognosis.vue')
                        }
                    ]
                },

                {
                    path: 'roads',
                    // component: () => import('@/modules/budget/demo-budget-execution/target-indicators/Index.vue'),
                    component: () => import('@/modules/budget/demo-budget-execution/Roads/Index.vue'),
                    children: [
                        {
                            path: 'roads-map',
                            name: 'roads-map',
                            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/budget/demo-budget-execution/Roads/RoadsMap.vue'),
                            meta: {
                                breadcrumb: 'roads-map'
                            }
                        },
                        {
                            path: 'roads',
                            name: 'roads',
                            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/budget/demo-budget-execution/Roads/Roads.vue'),
                            meta: {
                                breadcrumb: 'roads'
                            }
                        },
                        {
                            path: 'bip-roads',
                            name: 'bip-roads',
                            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/budget/demo-budget-execution/Roads/Bip.vue'),
                            meta: {
                                breadcrumb: 'bip-roads'
                            }
                        },
                        {
                            path: 'bip-roads1',
                            name: 'bip-roads1',
                            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/budget/demo-budget-execution/Roads/BipRoads1.vue'),
                            meta: {
                                breadcrumb: 'bip-roads1'
                            }
                        },
                        {
                            path: 'bip-roads-modeling',
                            name: 'bip-roads-modeling',
                            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/budget/demo-budget-execution/Roads/BipRoadsModeling.vue'),
                            meta: {
                                breadcrumb: 'bip-roads-modeling'
                            }
                        }
                    ]
                },
                {
                    path: 'schools',
                    component: () => import('@/modules/budget/demo-budget-execution/Schools/Index.vue'),
                    children: [
                        {
                            path: 'schools-map',
                            name: 'schools-map',
                            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/budget/demo-budget-execution/Schools/SchoolsMap.vue'),
                            meta: {
                                breadcrumb: 'schools-map'
                            }
                        },
                        {
                            path: 'schools',
                            name: 'schools',
                            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/budget/demo-budget-execution/Schools/Schools.vue'),
                            meta: {
                                breadcrumb: 'schools'
                            }
                        },
                        {
                            path: 'bip-schools',
                            name: 'bip-schools',
                            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/budget/demo-budget-execution/Schools/Bip.vue'),
                            meta: {
                                breadcrumb: 'bip-schools'
                            }
                        },
                        {
                            path: 'bip-schools-modeling',
                            name: 'bip-schools-modeling',
                            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/budget/demo-budget-execution/Schools/BipSchoolsModeling.vue'),
                            meta: {
                                breadcrumb: 'bip-schools-modeling'
                            }
                        }
                    ]
                },
                {
                    path: 'request',
                    component: () => import('@/modules/budget/demo-budget-execution/BudgetRequest/Index.vue'),
                    children: [
                        {
                            path: 'request',
                            name: 'request',
                            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/budget/demo-budget-execution/BudgetRequest/Request.vue'),
                            meta: {
                                breadcrumb: 'request'
                            }
                        },
                        {
                            path: 'budget-commission',
                            name: 'budget-commission',
                            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/budget/demo-budget-execution/BudgetRequest/BudgetCommission1.vue'),
                            meta: {
                                breadcrumb: 'budget-commission'
                            }
                        },
                        {
                            path: 'budget-plan',
                            name: 'budget-plan',
                            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/budget/demo-budget-execution/BudgetRequest/BudgetPlan.vue'),
                            meta: {
                                breadcrumb: 'budget-commission'
                            }
                        }
                    ]
                },
                {
                    path: 'execution',
                    component: () => import('@/modules/budget/demo-budget-execution/BudgetExecution/Index.vue'),
                    children: [
                        {
                            path: 'budget-forms',
                            name: 'budget-forms',
                            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/budget/demo-budget-execution/BudgetExecution/BudgetForms.vue'),
                            meta: {
                                breadcrumb: 'budget-forms'
                            }
                        },
                        {
                            path: 'budget-gantt',
                            name: 'budget-gantt',
                            component: () => import(/* webpackChunkName: "weinre" */ '@/modules/budget/demo-budget-execution/BudgetExecution/BudgetGantt.vue'),
                            meta: {
                                breadcrumb: 'budget-gantt'
                            }
                        }
                    ]
                },

                // СГП
                {
                    path: 'sgp',
                    component: () => import('@/modules/budget/demo-budget-execution/Index.vue'),
                    children: [
                        // Справочники
                        {
                            path: 'dict',
                            component: () => import('@/modules/budget/demo-budget-execution/Fallback.vue')
                        },

                        // ЦИ
                        {
                            path: 'target-indicators',
                            component: () => import('@/modules/budget/demo-budget-execution/target-indicators/Index.vue'),
                            children: [
                                // Декомпозиция
                                {
                                    path: 'decomposition',
                                    component: () => import('@/modules/budget/demo-budget-execution/target-indicators/Decomposition.vue')
                                },

                                // Прогноз
                                {
                                    path: 'prognosis',
                                    component: () => import('@/modules/budget/demo-budget-execution/target-indicators/Prognosis.vue')
                                }
                            ]
                        },

                        // БИП
                        {
                            path: 'bip',
                            component: () => import('@/modules/budget/demo-budget-execution/Index.vue'),
                            children: [
                                // На рассмотрении
                                {
                                    path: 'on-review',
                                    component: () => import('@/modules/budget/demo-budget-execution/Fallback.vue')
                                },

                                // Не поддержанные
                                {
                                    path: 'rejected',
                                    component: () => import('@/modules/budget/demo-budget-execution/Fallback.vue')
                                }
                            ]
                        }
                    ]
                },

                // ПСЭР
                {
                    path: 'pser',
                    component: () => import('@/modules/budget/demo-budget-execution/Index.vue'),
                    children: [
                        // Соц.-эконом. показатели
                        {
                            path: 'soc-eco-indicators',
                            component: () => import('@/modules/budget/demo-budget-execution/Fallback.vue')
                        },

                        // Прогноз бюджета (лимиты)
                        {
                            path: 'budget-prognosis',
                            component: () => import('@/modules/budget/demo-budget-execution/Fallback.vue')
                        }
                    ]
                },

                // Бюджетное планирование
                {
                    path: 'budget-plan',
                    component: () => import('@/modules/budget/demo-budget-execution/Index.vue'),
                    children: [
                        // Классификаторы
                        {
                            path: 'classifiers',
                            component: () => import('@/modules/budget/demo-budget-execution/Fallback.vue')
                        },

                        // Расчет лимитов
                        {
                            path: 'limit-calc',
                            component: () => import('@/modules/budget/demo-budget-execution/Fallback.vue')
                        },

                        // Формирование
                        {
                            path: 'creation',
                            component: () => import('@/modules/budget/demo-budget-execution/Index.vue'),
                            children: [
                                // Переходящий бюджет
                                {
                                    path: 'flowing-budget',
                                    component: () => import('@/modules/budget/demo-budget-execution/Fallback.vue')
                                },

                                // Дополнительная потребность
                                {
                                    path: 'add-needs',
                                    component: () => import('@/modules/budget/demo-budget-execution/Fallback.vue')
                                }
                            ]
                        }
                    ]
                },
                {
                    path: '/budget-header-test',
                    name: 'budget-header-test',
                    component: () => import('@/modules/budget-request/budget-header-test.vue')

                }


            ]
        },
        {
            path: '/budget-request-link-list',
            name: 'budget-request-link-list',
            component: () => import('@/modules/budget-request/BudgetRequestLinkList.vue')
        },
        {
            path: '/gov-catalog',
            name: 'gov-catalog',
            component: () => import('@/modules/gov-catalog/index.vue')
        },
        // Таблица БИП
        {
            path: '/bip-table',
            name: 'bip-table',
            component: () => import('@/modules/budget/bip/BipTable.vue')

        },
        // Исполнение бюджета
        {
            path: '/monitoring',
            name: 'monitoring',
            component: () => import('@/modules/budget/monitoring/index'),
            meta: {
                breadcrumb: 'monitoring'
            }
        },
        //Внесение изменений
        {
            path: '/alteration',
            name: 'alteration',
            component: () => import('@/modules/budget/alteration/AlterationForm.vue'),
            meta: {
                breadcrumb: 'alteration'
            }
        },
        // Бюджетная заявка
        {
            path: '/budget-request',
            name: 'budget-request',
            component: () => import('@/modules/budget/budget-execution/Index.vue')
        },
        // Регистрация первичного договора бюджетного кредита (прототип)
        {
            path: '/budget-loan-form/:mode/:id?',
            name: 'budget-loan-form',
            component: () => import('@/modules/budget/execution/loan/AgreementRegistrationForm.vue')
        },
        { // Реестр бюджетных кредитов
            path: '/budget-loan-table',
            name: 'budget-loan-table',
            component: () => import('@/modules/budget/execution/loan/BudgetLoanTable.vue'),
            // meta: {
            //     breadcrumb: 'budget-form'
            // }
        },
        {
            path: '/bip',
            component: () => import('@/modules/budget/bip/Index.vue'),
            children: [
                // Главная
                {
                    path: 'bip-form',
                    component: () => import('@/modules/budget/bip/bip-form.vue')
                },
                {
                    path: 'bip-list',
                    component: () => import('@/modules/budget/bip/bip-list.vue')
                },
                {
                    path: 'bip-grade',
                    component: () => import('@/modules/budget/bip/bip-grade.vue')
                },
                {
                    path: 'bip-config',
                    component: () => import('@/modules/budget/bip/bip-config.vue')
                },
                {
                    path: 'bip-card',
                    component: () => import('@/modules/budget/bip/bip-free.vue')
                }
            ]
        },
        {
            path: '/budget-program/:region?/:planYear?/:abp?/:variant?/:prg?',
            name: 'budget-program',
            component: () => import('@/modules/budget-request/budget-program.vue')
        },
        // Справочник
        {
            path: '/book-dicts',
            name: 'book-dicts',
            component: () => import(/* webpackChunkName: "my-page" */ '@/modules/book-dicts/book-dicts@.vue'),
            meta: {
                breadcrumb: 'book-dicts'
            }
        },
        {
            path: '/form-balance',
            name: 'form-balance',
            component: () => import('@/modules/budget/BalanceForm.vue')
        },
        {
            path: '/additionally-menu/:typeNpa',
            name: 'additionally-menu',
            component: () => import('@/modules/additional-menu/Index.vue')
        },
        {
            path: '/agreement',
            name: 'agreement',
            component: () => import('@/modules/settings/agreement.vue')
        },
        {
            path: '/budget-report',
            name: 'budget-report',
            component: () => import('@/modules/budget/BudgetReport.vue'),
            meta: {
                breadcrumb: 'budget-report'
            }
        },
        {
            path: '/user-management',
            name: 'user-management',
            component: () => import('@/modules/user-management/Index.vue')
        },
        {
            path: '/budget-execution-form',
            name: 'budget-execution-form',
            component: () => import('@/modules/budget/execution/form/BudgetExecutionForm.vue')
        },
        {
            path: '/guide',
            name: 'instructions',
            component: () => import('@/modules/guide/instructions.vue')
        },
        {
            path: '/purchase-plan',
            name: 'purchase-plan',
            component: () => import('@/modules/purchase-plan/Index.vue')
        },
        {
            path: '/power-bi-tabs',
            name: 'power-bi-tabs',
            component: () => import('@/modules/budget/PowerBiTabs.vue')
        },
        {
            path: '/cabinet/signatories',
            name: 'signatories',
            component: () => import('@/modules/cabinet/Signatories.vue'),
            meta: {
                rootClasses: ['no-tabs']
            }
        },
        {
            path: '/cabinet/notifications',
            name: 'notifications',
            component: () => import('@/modules/cabinet/Notifications.vue'),
            meta: {
                rootClasses: ['no-filters', 'no-tabs']
            }
        },
        {
            path: '/cabinet/user-details',
            name: 'user-details',
            component: () => import('@/modules/cabinet/UserDetails.vue'),
            meta: {
                rootClasses: ['no-filters']
            }
        },
        {
            path: '/explanatory-note',
            name: 'explanatory-note',
            component: () => import('@/modules/budget/explanatory-note/Index.vue'),
            meta: {
                rootClasses: ['no-tabs']
            }
        },
        {
            path: '/i18n-db',
            component: () => import('@/modules/i18n/Page.vue'),
            meta: {
                rootClasses: ['no-tabs'],
            },
        },
        {
            path: '/ncalayer',
            name: 'ncalayer',
            component: () => import('@/modules/examples/ncalayer.vue')
        },

    ],

    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            };
        }
        return { x: 0, y: 0 };
    },

});