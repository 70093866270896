import extend from 'extend';

import store from '@/services/store';
import Ax from '@/utils/ax';

import I18nDateTemplate from './date-template';
import fallback, { ITranslates } from './fallback';
import I18nTemplate from './template';
import vue from './vue';
import VueI18n from 'vue-i18n';

export {
    I18nTemplate,
    I18nDateTemplate,
    vue
};


export type LocaleChangeListener = (newLocale: string, oldLocale: string) => unknown;


const loaded: string[] = [];
const available = ['en'];
let availableLoaded = false;
let requested = '';

const localStorageKey = 'csi--locale';

const listeners: Array<LocaleChangeListener> = [];

const service = {
    get locale(): string {
        return <string>vue.locale;
    },

    set locale(value: string) {
        requested = value;
        if (availableLoaded) {
            let locale = value;
            if (!available.includes(locale)) {
                if (locale.includes('-')) {
                    locale = locale.substr(0, locale.indexOf('-'));
                } else if (locale.includes('_')) {
                    locale = locale.substr(0, locale.indexOf('_'));
                }
                if (!available.includes(locale)) {
                    locale = 'en';
                }
            }

            if (!loaded.includes(locale)) {
                vue.mergeDateTimeFormat(locale, vue.getDateTimeFormat(<VueI18n.Locale>vue.fallbackLocale));
                vue.mergeNumberFormat(locale, vue.getNumberFormat(<string>vue.fallbackLocale));
                vue.mergeLocaleMessage(locale, vue.getLocaleMessage(<string>vue.fallbackLocale));

                Ax<ITranslates>(
                    { url: 'i18n/messages/' + locale + '.json' },
                    (data) => {
                        const newData = extend(true, {}, fallback, data);
                        vue.mergeNumberFormat(locale, newData.numberFormats);
                        vue.mergeDateTimeFormat(locale, newData.dateTimeFormats);
                        vue.mergeLocaleMessage(locale, newData.messages);

                        if (!loaded.includes(locale)) {
                            loaded.push(locale);
                        }

                        if (vue.locale === locale) {
                            vue.locale = vue.fallbackLocale as string;
                            vue.locale = locale;
                        }
                    }
                );
            }
            const oldLocale = vue.locale as string;
            vue.locale = locale;

            if (localStorage) {
                localStorage.setItem(localStorageKey, locale);
            }

            listeners.forEach((listener) => {
                try {
                    listener(locale, oldLocale);
                } catch (e) {
                    console.error(e);
                }
            });
        }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getTranslate(key: string, args: any[] | readonly any[], locale?: string): string {
        let result;
        if (typeof locale === 'string') {
            result = vue.t(key, locale, args);
        } else {
            result = vue.t(key, args);
        }

        if (typeof result === 'string') {
            return result;
        }

        return '[ Invalid translate key "' + key + '" ]';
    },

    getDateTranslate(value: Date, key: string): string {
        return vue.d(value, key);
    },

    addLocaleChangeListener(listener: LocaleChangeListener): void {
        listeners.push(listener);
    },
    removeLocaleChangeListener(listener: LocaleChangeListener): void {
        const indices: Array<number> = [];

        listeners.forEach((localListener, index) => {
            if (localListener === listener) {
                indices.unshift(index);
            }
        });

        indices.forEach((index) => {
            listeners.splice(index, 1);
        });
    },

    /**
     * Выбирает один из параметров в зависимости от текущей локали.
     *
     * Возвращает `kk`, если текущая локаль равна "kk", и `kk` не равен `undefined`.
     * Возвращает `en`, если текущая локаль равна "en", и `en` не равен `undefined`.
     * Во всех остальных случаях возвращает `ru`.
     *
     * @param ru Значение для локали "ru"
     * @param kk Значение для локали "kk"
     * @param en Значение для локали "en"
     *
     * @return Один из `ru`, `kk`, `en`
     */
    choose<T = string>(ru: T, kk?: T, en?: T): T {
        switch (this.locale) {
            case 'kk':
                if (kk === undefined) {
                    return ru;
                } else {
                    return kk;
                }
            case 'en':
                if (en === undefined) {
                    return ru;
                } else {
                    return en;
                }
            default:
                return ru;
        }
    },
};
export default service;

setTimeout(() => {
    let set = false;
    let initialLocale = 'en';
    if (localStorage) {
        const storedLocale = localStorage.getItem(localStorageKey);
        if (typeof storedLocale === 'string') {
            initialLocale = storedLocale;
            set = true;
        }
    }

    if (!set) {
        if ((navigator instanceof Object) && (Navigator !== undefined) && (navigator instanceof Navigator)) {
            const prefferedLocale = navigator.language;
            if (typeof prefferedLocale === 'string') {
                initialLocale = prefferedLocale;
            }
        }
    }

    service.locale = initialLocale;

    Ax<{locales: string[]}>(
        { url: '/i18n/info.json' },
        (data) => {
            data.locales.forEach((locale) => {
                if (!available.includes(locale)) {
                    available.push(locale);
                }
            });
        },
        undefined,
        () => {
            availableLoaded = true;
            store.commit('setLocales', available);
            if (requested !== '') {
                service.locale = requested;
            }
        }
    );
});